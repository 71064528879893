<template>
  <MainLayout>
    <div class="relative">
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Data Biaya Tagihan PMB</h2>
        <div
          class="w-full sm:w-auto md:flex justify-center items-center align-center mt-4 sm:mt-0"
        ></div>
      </div>

      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 lg:col-span-12">
          <div v-if="loading" class="block-loading">
            <LoadingIcon icon="three-dots" class="w-8 h-8" />
            <div class="text-center text-xs mt-2">
              Loading data... Please Wait...
            </div>
          </div>

          <div class="intro-y box">
            <div class="grid grid-cols-12 mb-3">
              <div class="col-span-12 xl:col-span-6 p-3">
                <div class="grid grid-cols-2 gap-3">
                  <div class="col-span-1">
                    <label class="form-label">Tahun Akademik</label>
                    <select
                      v-model="smtSelected"
                      class="form-select"
                      placeholder="Pilih Tahun Akademik"
                    >
                      <option>Pilih Tahun Akademik</option>
                      <option
                        v-for="item in listOptionSmtGanjil"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.thn_ajaran?.nm_thn_ajaran }}
                      </option>
                    </select>
                  </div>
                  <div class="col-span-1">
                    <label class="form-label">Program Studi</label>
                    <select v-model="smsSelected" class="form-select">
                      <option
                        v-for="item in listOptionSms"
                        :key="item.id"
                        :value="item.value"
                      >
                        {{ item.text }}
                      </option>
                    </select>
                  </div>
                  <!--                  <div class="col-span-1">
                    <label class="form-label">Jenis Pembiayaan</label>
                    <TailSelect
                      v-model="jenis_biaya"
                      :options="{
                        search: true,
                        classNames: 'w-full',
                        descriptions: true,
                        deselect: true,
                      }"
                      @change="setJenisBiayaSelected(v)"
                    >
                      <option
                        v-for="list in jenisBiayaList"
                        :key="list.id"
                        :value="list.id"
                      >
                        {{ list.nama }}
                      </option>
                    </TailSelect>
                  </div>-->
                </div>
              </div>
              <div class="col-span-12 xl:col-span-2 p-3">
                <button
                  class="btn btn-success w-50 mr-5 mt-7"
                  @click="cariData"
                >
                  Cek Tagihan
                </button>
              </div>
              <div
                class="col-span-12 xl:col-span-4 bg-gray-300 dark:bg-gray-700"
              >
                <div class="grid grid-cols-2 gap-3">
                  <div class="col-span-1">
                    <div class="p-3">
                      <h3 class="font-medium mb-2">Seluruh Tagihan</h3>
                      Jumlah Tagihan : {{ statistik?.all?.jumlah }}<br />
                      Total Nominal Tagihan :
                      {{ $h.IDR(statistik?.all?.total_tagihan) }}<br />
                      Jumlah Transaksi : {{ statistik?.lunas?.jumlah }}<br />
                      Total Nominal Transaksi :
                      {{ $h.IDR(statistik?.lunas?.total_tagihan) }}<br />
                    </div>
                  </div>
                  <div v-if="statistik" class="col-span-1">
                    <div v-if="sms" class="p-3">
                      <h3 class="font-medium mb-2">
                        Prodi {{ sms?.nama }} ({{ sms?.jenjang }})
                      </h3>
                      Jumlah Tagihan : {{ statistik?.filter_all?.jumlah }}<br />
                      Total Nominal Tagihan :
                      {{ $h.IDR(statistik?.filter_all?.total_tagihan) }}<br />
                      Jumlah Transaksi : {{ statistik?.filter_lunas?.jumlah
                      }}<br />
                      Total Nominal Transaksi :
                      {{ $h.IDR(statistik?.filter_lunas?.total_tagihan) }}<br />
                    </div>
                  </div>
                </div>

                <!--                <div class="p-3">
                  <button class="btn btn-primary w-50" @click="kirimNotifikasi">
                    <send-icon class="mr-1 w-5"></send-icon>
                    Kirim Pengingat Tagihan
                  </button>
                </div>-->
                <div class="p-3">
                  <Alert
                    v-if="statusValidasi.status != null"
                    :type="
                      statusValidasi.status ? 'alert-success' : 'alert-danger'
                    "
                    :dismissable="true"
                    >{{ statusValidasi.msg }}</Alert
                  >
                  <Alert
                    v-if="statusNotifikasi.status != null"
                    :type="
                      statusNotifikasi.status ? 'alert-success' : 'alert-danger'
                    "
                    :dismissable="true"
                    >{{ statusNotifikasi.msg }}</Alert
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="intro-y box p-5 mt-5">
            <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
              <form
                id="tabulator-html-filter-form"
                class="xl:flex sm:mr-auto"
                @submit.prevent="onFilter"
              >
                <div class="sm:flex items-center sm:mr-4">
                  <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                    >Field</label
                  >
                  <select
                    id="tabulator-html-filter-field"
                    v-model="filter.field"
                    class="form-select w-full sm:w-32 xxl:w-full mt-2 sm:mt-0 sm:w-auto"
                  >
                    <option value="no_ujian">No. Ujian</option>
                    <option value="nama">Nama</option>
                  </select>
                </div>

                <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                  <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                    >Value</label
                  >
                  <input
                    id="tabulator-html-filter-value"
                    v-model="filter.value"
                    type="text"
                    class="form-control sm:w-40 xxl:w-full mt-2 sm:mt-0"
                    placeholder="Search..."
                  />
                </div>
                <div class="mt-2 xl:mt-0">
                  <button
                    id="tabulator-html-filter-go"
                    type="button"
                    class="btn btn-primary w-full sm:w-16"
                    @click="onFilter"
                  >
                    Go
                  </button>
                  <button
                    id="tabulator-html-filter-reset"
                    type="button"
                    class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
                    @click="resetFilter"
                  >
                    Reset
                  </button>
                </div>
              </form>
              <div class="flex mt-5 sm:mt-0">
                <button
                  id="tabulator-print"
                  class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"
                  @click="generateExcel"
                >
                  <DownloadIcon class="w-4 h-4 mr-2" /> Download Excel
                </button>
              </div>
            </div>
            <div class="overflow-x-auto overflow-y-auto mt-2">
              <table class="table border">
                <thead>
                  <tr class="border">
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th class="border text-center" colspan="2">
                      Pembayaran Normal
                    </th>
                    <th class="border"></th>
                    <th class="border"></th>
                    <th class="border text-center" colspan="2">
                      Pembayaran Cicilan
                    </th>
                    <th class="border"></th>
                    <th class="border text-center" colspan="2">
                      Pembayaran Tagihan
                    </th>
                    <th class="border"></th>
                    <th class="border"></th>
                  </tr>
                  <tr class="border text-center">
                    <th class="border">No</th>
                    <th class="border">No. Ujian</th>
                    <th class="border">Nama</th>
                    <th class="border">Prodi Pilihan</th>
                    <th class="border">Pembayaran</th>
                    <th class="border">Nominal</th>
                    <th class="border">Tgl Tagih</th>
                    <th class="border">Tgl Jatuh Tempo</th>
                    <th class="border">Pembayaran</th>
                    <th class="border">Nominal</th>
                    <th class="border">Tanggal</th>
                    <th class="border">Pembayaran</th>
                    <th class="border">Nominal</th>
                    <th class="border">Tanggal Bayar</th>
                    <th class="border">Total Biaya</th>
                    <th class="border">Jumlah Tagihan</th>
                    <th class="border">Tagihan Dibayarkan</th>
                    <th class="border">Keterangan</th>
                  </tr>
                </thead>
                <tbody v-for="(item, key) in databiayatagihanpmb" :key="key">
                  <template v-if="item?.pmb_finance?.cicilan === 'T'">
                    <tr
                      v-for="(f, k) in item?.pmb_finance?.pembayaran"
                      :key="k"
                      class="align-top"
                    >
                      <td
                        v-if="k === 0"
                        class="border"
                        :rowspan="item?.pmb_finance?.pembayaran.length"
                      >
                        {{ ++key }}
                      </td>
                      <td
                        v-if="k === 0"
                        class="border"
                        :rowspan="item?.pmb_finance?.pembayaran.length"
                      >
                        {{ item.no_ujian }}
                      </td>
                      <td
                        v-if="k === 0"
                        class="border"
                        :rowspan="item?.pmb_finance?.pembayaran.length"
                      >
                        {{ item.nm_pd }}
                      </td>
                      <td
                        v-if="k === 0"
                        class="border"
                        :rowspan="item?.pmb_finance?.pembayaran.length"
                      >
                        {{ item.prodi?.nama }} ({{ item.prodi?.jenjang }})
                      </td>
                      <td class="border">
                        {{ f.jenis_biaya }}
                      </td>
                      <td class="border">{{ f.nominal }}</td>
                      <td class="border">{{ f.tgl_tagihan }}</td>
                      <td class="border">{{ f.tgl_jatuh_tempo }}</td>
                      <td class="border"></td>
                      <td class="border"></td>
                      <td class="border"></td>
                      <td class="border">
                        {{ item?.biaya_tagihan[k]?.nama_produk }}
                      </td>
                      <td class="border">
                        {{ $h.IDR(item?.biaya_tagihan[k]?.jumlah_upload) }}
                      </td>
                      <td class="border">
                        {{
                          item?.biaya_tagihan[k]?.tagihan_jupiter
                            ?.Ada_Transaksi == "Y"
                            ? item?.biaya_tagihan[k]?.tagihan_jupiter
                                .TGLTRANSAKSI == null
                              ? item?.biaya_tagihan[k]?.tagihan_jupiter.Add_Info
                              : item?.biaya_tagihan[k]?.tagihan_jupiter
                                  .TGLTRANSAKSI
                            : item?.biaya_tagihan[k]?.jumlah_upload > 0
                            ? "Belum Bayar"
                            : item?.biaya_tagihan[k]?.tagihan_jupiter.Add_Info
                        }}
                      </td>
                      <td
                        v-if="k === 0"
                        class="border"
                        :rowspan="item?.pmb_finance?.pembayaran.length"
                      >
                        {{
                          $h.IDR(hitungJumlah(item?.pmb_finance?.pembayaran))
                        }}
                      </td>
                      <td
                        v-if="k === 0"
                        class="border"
                        :rowspan="item?.pmb_finance?.pembayaran.length"
                      >
                        {{ $h.IDR(hitungJumlahDitagih(item?.biaya_tagihan)) }}
                      </td>
                      <td
                        v-if="k === 0"
                        class="border"
                        :rowspan="item?.pmb_finance?.pembayaran.length"
                      >
                        {{ $h.IDR(hitungJumlahDibayar(item?.biaya_tagihan)) }}
                      </td>
                      <td
                        v-if="k === 0"
                        class="border"
                        :rowspan="item?.pmb_finance?.pembayaran.length"
                      >
                        {{ item?.pmb_finance?.catatan_bak }}
                      </td>
                    </tr>
                  </template>

                  <template v-if="item?.pmb_finance?.cicilan === 'Y'">
                    <tr
                      v-for="(f, k) in item?.pmb_finance?.pembayaran"
                      :key="k"
                      class="align-top"
                    >
                      <td
                        v-if="k === 0"
                        class="border"
                        :rowspan="item?.pmb_finance?.pembayaran.length"
                      >
                        {{ ++key }}
                      </td>
                      <td
                        v-if="k === 0"
                        class="border"
                        :rowspan="item?.pmb_finance?.pembayaran.length"
                      >
                        {{ item.no_ujian }}
                      </td>
                      <td
                        v-if="k === 0"
                        class="border"
                        :rowspan="item?.pmb_finance?.pembayaran.length"
                      >
                        {{ item.nm_pd }}
                      </td>
                      <td
                        v-if="k === 0"
                        class="border"
                        :rowspan="item?.pmb_finance?.pembayaran.length"
                      >
                        {{ item.prodi?.nama }} ({{ item.prodi?.jenjang }})
                      </td>

                      <td class="border"></td>
                      <td class="border"></td>
                      <td class="border"></td>
                      <td class="border"></td>
                      <!-- Mulai Data Cicil -->
                      <td class="border">
                        {{ f.jenis_biaya }}
                      </td>
                      <td class="border">{{ $h.IDR(f.nominal) }}</td>
                      <td class="border">{{ f.tgl_tagihan }}</td>
                      <!--                      <td class="border">{{ f.tgl_jatuh_tempo }}</td>-->
                      <td class="border">
                        {{ item?.biaya_tagihan[k]?.nama_produk }}
                      </td>
                      <td class="border">
                        {{ $h.IDR(item?.biaya_tagihan[k]?.jumlah_upload) }}
                      </td>
                      <td class="border">
                        {{
                          item?.biaya_tagihan[k]?.tagihan_jupiter
                            ?.Ada_Transaksi == "Y"
                            ? item?.biaya_tagihan[k]?.tagihan_jupiter
                                .TGLTRANSAKSI == null
                              ? item?.biaya_tagihan[k]?.tagihan_jupiter.Add_Info
                              : item?.biaya_tagihan[k]?.tagihan_jupiter
                                  .TGLTRANSAKSI
                            : item?.biaya_tagihan[k]?.jumlah_upload > 0
                            ? "Belum Bayar"
                            : item?.biaya_tagihan[k]?.tagihan_jupiter.Add_Info
                        }}
                      </td>
                      <td
                        v-if="k === 0"
                        class="border"
                        :rowspan="item?.pmb_finance?.pembayaran.length"
                      >
                        {{
                          $h.IDR(hitungJumlah(item?.pmb_finance?.pembayaran))
                        }}
                      </td>
                      <td
                        v-if="k === 0"
                        class="border"
                        :rowspan="item?.pmb_finance?.pembayaran.length"
                      >
                        {{ $h.IDR(hitungJumlahDitagih(item?.biaya_tagihan)) }}
                      </td>
                      <td
                        v-if="k === 0"
                        class="border"
                        :rowspan="item?.pmb_finance?.pembayaran.length"
                      >
                        {{ $h.IDR(hitungJumlahDibayar(item?.biaya_tagihan)) }}
                      </td>
                      <td
                        v-if="k === 0"
                        class="border"
                        :rowspan="item?.pmb_finance?.pembayaran.length"
                      >
                        {{ item?.pmb_finance?.catatan_bak }}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <ModalDialogPositive
        :id="'validasi-modal'"
        :question="
          'Anda yakin ingin memvalidasi pembayaran tagihan ' +
          detail.tagihan?.NamaProduk +
          ' ' +
          detail.tagihan?.Nama +
          '?'
        "
      >
        <template #delete-button>
          <button
            type="button"
            class="btn btn-success w-24"
            @click="konfirmValidasi"
          >
            Ya
          </button>
        </template>
      </ModalDialogPositive>
    </div>

    <SlideOver :open="panel.panelOpen" size="max-w-5xl" @close="closePanel">
      <template #title>Detail Tagihan dan Transaksi Pembayaran</template>
      <div class="h-full" aria-hidden="true">
        <Alert
          v-if="statusValidasi.status != null"
          :type="statusValidasi.status ? 'alert-success' : 'alert-danger'"
          :dismissable="true"
          >{{ statusValidasi.msg }}</Alert
        >
        <div
          v-if="confirmValidasi"
          class="p-3 text-center border border-green-100 mb-3"
        >
          Anda yakin ingin memvalidasi pembayaran tagihan
          <b>{{ detail.tagihan?.NamaProduk }} {{ detail.tagihan?.Nama }}</b
          >?

          <button
            type="button"
            class="btn btn-success w-24"
            @click="konfirmValidasi"
          >
            Ya
          </button>

          <button
            type="button"
            class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 ml-1"
            @click="confirmValidasi = false"
          >
            Tidak
          </button>
        </div>
        <DetailTransaksi
          :detail="detail"
          :process="allowValidasi"
          @validasiTransaksi="validasiTransaksi"
        ></DetailTransaksi>
      </div>
    </SlideOver>
  </MainLayout>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import xlsx from "xlsx";
import { useStore } from "vuex";
import feather from "feather-icons";
import { HTTPKEU } from "@/services/http";
import TailSelectSelector from "@/components/form/TailSelectSelector";
import Alert from "@/components/alerts/Alert";
import { option } from "@/utils/listoptions";
import MainLayout from "@/layouts/main/MainLayout";
import SlideOver from "@/layouts/main/SlideOver";
import { tblTabulator } from "@/utils/tabulator";
import store from "@/store";
import ModalDialogPositive from "@/components/utility/ModalDialogPositive";
import DetailTransaksi from "./validasi/DetailTransaksi";
import { helper } from "@/utils/helper";
import writeXlsxFile from "write-excel-file";

export default {
  name: "Main",
  components: {
    DetailTransaksi,
    ModalDialogPositive,
    SlideOver,
    MainLayout,
    Alert,
    TailSelectSelector,
  },

  setup() {
    const tableRef = ref();
    const tabulator = ref();
    const idData = ref();
    const store = useStore();
    const filter = reactive({
      field: "no_ujian",
      type: "like",
      value: "",
    });
    let panelOpen = ref(false);
    let panel = reactive({ panelOpen });
    let tagihan = null;
    let detail = reactive({ tagihan });
    let model = reactive({
      kode_bank: "BCA",
      kode_produk: null,
      kode_sub_comp: null,
      id_smt: null,
      npm: null,
    });
    let id_jenis_biaya = null;

    /*const showDetail = (e, cell) => {
      idData.value = cell.getData().id;
      detail.tagihan = cell.getData()?.tagihan;

      if (detail.tagihan?.ID) {
        panel.panelOpen = true;
        model.id_smt =
          detail.tagihan?.TahunAkademik.slice(0, 4) +
          detail.tagihan.KD_Semester;
        model.kode_produk = detail.tagihan.KodeProduk;
        model.kode_bank = detail.tagihan.KodeBank;
        model.kode_sub_comp = detail.tagihan.SubComp;
        model.npm = detail.tagihan.NPM;
        model.va = detail.tagihan.NO_VA;
        model.no_upload = detail.tagihan.NO_UPLOAD;
        console.log(model);
      }

      console.log(detail.tagihan);
    };*/
    /*const kofirmValidasi = (e, cell) => {
      let tagihan = cell.getData().tagihan;
      if (tagihan?.Ada_Transaksi === "Y" && tagihan?.Validasi === "T") {
        idData.value = cell.getData().id;
        detail.tagihan = cell.getData()?.tagihan;
        model.id_smt = cell.getData().id_smt;
        model.kode_produk = cell.getData().kode_produk;
        model.kode_bank = cell.getData().kode_bank;
        model.kode_sub_comp = cell.getData().kode_sub_comp;
        model.npm = cell.getData().npm;
        cash("#validasi-modal").modal("show");
      }
    };*/

    //panel = true;
    const initTabulator = () => {
      let columns = [
        {
          formatter: "responsiveCollapse",
          width: 40,
          minWidth: 30,
          hozAlign: "center",
          resizable: false,
          headerSort: false,
        },
        { formatter: "rownum", hozAlign: "center", width: 40 },
        // For HTML table
        {
          title: "Mahasiswa",
          field: "nipd",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
          headerSort: true,
          formatter(cell) {
            return (
              "<div><div class='whitespace-normal font-medium'>" +
              cell.getData().nipd +
              "</div> " +
              "<div class='whitespace-normal text-xs'>" +
              cell.getData().nm_pd +
              "</div>" +
              "<div class='whitespace-normal text-xs'>" +
              cell.getData().id_stat_mhs +
              "</div>" +
              "</div>"
            );
          },
        },
        {
          title: "VA",
          field: "no_va",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
          headerSort: true,
        },
        {
          title: "Kelas Kuliah",
          field: "ket_kelompok_kelas",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
          headerSort: true,
        },
      ];
      tabulator.value = tblTabulator.remoteDataTable(
        tableRef,
        "biaya",
        columns,
        true,
        null,
        { jenis_biaya: id_jenis_biaya }
      );
    };
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener("resize", () => {
        tabulator.value.redraw();
        feather.replace({
          "stroke-width": 1.5,
        });
      });
    };
    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value);
    };
    // On reset filter
    const onResetFilter = () => {
      filter.field = "npm";
      filter.type = "like";
      filter.value = "";
      onFilter();
    };

    // Filter function
    const onFilterCustom = (field, type, value) => {
      tabulator.value.setFilter(field, type, value);
    };
    // Export
    const onExportCsv = () => {
      tabulator.value.download("csv", "data.csv");
    };
    const onExportJson = () => {
      tabulator.value.download("json", "data.json");
    };
    const onExportXlsx = () => {
      const win = window;
      win.XLSX = xlsx;
      tabulator.value.download("xlsx", "data.xlsx", {
        sheetName: "Products",
      });
    };
    const onExportHtml = () => {
      tabulator.value.download("html", "data.html", {
        style: true,
      });
    };
    // Print
    const onPrint = () => {
      tabulator.value.print();
    };
    onMounted(() => {
      initTabulator();
      reInitOnResizeWindow();
    });
    return {
      store,
      tabulator,
      tableRef,
      filter,
      onFilter,
      onFilterCustom,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      idData,
      panel,
      detail,
      model,
      id_jenis_biaya,
    };
  },
  data() {
    return {
      smt: { a_periode_aktif: 0 },
      loading: false,
      angkatan: new Date().getFullYear(),
      sms: null,
      smsSelected: null,
      smtSelected: null,
      subcomps: null,
      banks: null,
      produks: null,

      statusSimpan: {
        msg: null,
        status: null,
      },
      statusGetTagihan: {
        msg: null,
        status: null,
      },
      statusValidasi: {
        msg: null,
        status: null,
      },
      statusNotifikasi: {
        msg: null,
        status: null,
      },
      panelOpen: false,
      needreset: false,
      transaksiBank: null,
      allowValidasi: false,
      confirmValidasi: false,
      jenisBiayaList: null,
      jenisBiayaSelected: null,
      jenis_biaya: 2,
      statistik: null,
      databiayatagihanpmb: null,
    };
  },

  computed: {
    semester() {
      let year = new Date().getFullYear();
      let years = [];
      years.push({ id: 0, value: "Semua" });
      for (let i = 2015; i <= year; i++) {
        years.push({ id: i, value: i });
      }
      return years;
    },
    listOptionsState() {
      return this.$store.state.main.listOptions;
    },
    tabulatorLoaded() {
      return this.$store.state.main.tabulatorDataLoaded;
    },

    listOptionSmtGanjil() {
      let lo = this.$store.state.main.listOptions;
      if (lo?.semester?.length > 0) {
        return lo?.semester.filter((f) => {
          return f.smt == 1;
        });
      } else {
        return [];
      }
    },
    listOptionSms() {
      let lo = this.$store.state.main.listOptions;
      let sms = [];
      if (lo?.prodi?.length > 0) {
        sms = lo?.prodi.filter((f) => {
          return f.id != 0;
        });
      }
      let defaultOpt = {
        id: 0,
        value: "0",
        text: "Semua Prodi",
        selected: false,
      };
      sms.unshift(defaultOpt);
      return sms;
    },
  },
  watch: {
    tabulatorLoaded: {
      handler: function (h) {
        if (h === true) {
          this.$nextTick(() => {
            store.commit("main/resetTabulatorDataLoaded");
          });

          if (this.needreset === true) {
            this.onResetFilter();
            this.needreset = false;
          }
        }
      },
      immediate: true,
    },
    jenis_biaya: {
      handler: function (h) {
        this.setJenisBiayaSelected();
      },
      immediate: true,
    },
  },
  beforeMount() {
    option.fetchSemester();
    option.fetchProdi();
    this.getBankList();
    this.getProdukList();
    this.getSubcomList();
    this.getJenisBiayaList();
  },

  methods: {
    getJenisBiayaList() {
      HTTPKEU.get("list/jenisbiaya")
        .then((res) => {
          this.jenisBiayaList = res.data;
        })
        .finally(() => {});
    },
    setJenisBiayaSelected() {
      let h = parseInt(this.jenis_biaya);
      this.id_jenis_biaya = h;
      if (this.jenisBiayaList?.length > 0) {
        this.jenisBiayaSelected = this.jenisBiayaList.find((f) => {
          return h === f.id;
        });
      }
    },
    validasiTransaksi() {
      this.confirmValidasi = true;
      this.allowValidasi = true;
    },
    selectProdi(curr, id) {
      this.smsSelected = id;
      this.sms = curr[0];
    },
    selectSemester(curr, id) {
      this.smtSelected = id;
      this.smt = curr[0];
    },
    getSubcomList() {
      HTTPKEU.get("list/subcomp").then((res) => {
        this.subcomps = res.data;
      });
    },
    getProdukList() {
      HTTPKEU.get("list/produk").then((res) => {
        this.produks = res.data;
      });
    },
    getBankList() {
      HTTPKEU.get("list/bank").then((res) => {
        this.banks = res.data;
      });
    },

    konfirmValidasi() {
      this.statusValidasi.status = null;
      this.statusValidasi.msg = null;

      HTTPKEU.post("tagihan/validasi", { ...this.model })
        .then((res) => {
          if (res.status === 200) {
            this.statusValidasi.status = true;
            this.statusValidasi.msg = res.data.message;
          } else {
            this.statusValidasi.status = false;
            this.statusValidasi.msg = res.data.message;
          }
        })
        .catch((e) => {
          this.statusValidasi.status = false;
          this.statusValidasi.msg = e.response?.data?.message;
        })
        .finally(() => {
          this.cariData();
          this.confirmValidasi = false;
        });

      cash("#validasi-modal").modal("hide");
      //this.biayatagihanid = null;
    },
    closePanel(close) {
      this.panelOpen = close;
      this.panel.panelOpen = close;

      //Validation
      this.transaksiBank = null;
      this.allowValidasi = false;
      this.confirmValidasi = false;
      this.statusValidasi.status = null;
      this.statusValidasi.msg = null;
    },
    cariData() {
      HTTPKEU.get("biayapmb", {
        params: {
          id_smt: this.smtSelected,
          id_sms: this.smsSelected,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.databiayatagihanpmb = res.data?.data;
          }
        })
        .catch((e) => {
          this.databiayatagihanpmb = null;
        });
      this.getStatistik();
    },

    async generateExcel() {
      const rows = [
        [
          {
            value: "No",
            fontWeight: "bold",
          },
          {
            value: "No.Ujian",
            fontWeight: "bold",
          },
          {
            value: "Nama",
            fontWeight: "bold",
          },
          {
            value: "Prodi Pilihan",
            fontWeight: "bold",
          },
          {
            value: "Pembayaran",
            fontWeight: "bold",
          },
          {
            value: "Nominal",
            fontWeight: "bold",
          },
          {
            value: "Tgl Tagih",
            fontWeight: "bold",
          },
          {
            value: "Tgl Jatuh Tempo",
            fontWeight: "bold",
          },
          {
            value: "Pembayaran Cicilan",
            fontWeight: "bold",
          },
          {
            value: "Nominal Cicilan",
            fontWeight: "bold",
          },
          {
            value: "Tanggal Tagih Cicilan",
            fontWeight: "bold",
          },
          {
            value: "Pembayaran Tagihan",
            fontWeight: "bold",
          },
          {
            value: "Nominal Tagihan",
            fontWeight: "bold",
          },
          {
            value: "Tanggal Bayar Tagihan",
            fontWeight: "bold",
          },
          {
            value: "Total Biaya",
            fontWeight: "bold",
          },
          {
            value: "Jumlah Tagihan",
            fontWeight: "bold",
          },
          {
            value: "Tagihan Dibayarkan",
            fontWeight: "bold",
          },
          {
            value: "Keterangan",
            fontWeight: "bold",
          },
        ],
      ];

      if (this.databiayatagihanpmb.length > 0) {
        let n = 1;
        this.databiayatagihanpmb.forEach((item) => {
          if (item?.pmb_finance?.cicilan === "T") {
            item?.pmb_finance?.pembayaran.forEach((iter, idx) => {
              if (idx == 0) {
                rows.push([
                  {
                    type: Number,
                    value: n,
                  },
                  {
                    type: String,
                    value: item.no_ujian,
                  },
                  {
                    type: String,
                    value: item.nm_pd,
                  },
                  {
                    type: String,
                    value: item?.prodi?.nama + " " + item?.prodi?.jenjang,
                  },
                  {
                    type: String,
                    value: iter.jenis_biaya,
                  },
                  {
                    type: String,
                    value: iter.nominal,
                  },
                  {
                    type: String,
                    value: iter.tgl_tagihan,
                  },
                  {
                    type: String,
                    value: iter.tgl_jatuh_tempo,
                  },
                  {
                    type: String,
                    value: "",
                  },
                  {
                    type: String,
                    value: "",
                  },
                  {
                    type: String,
                    value: "",
                  },
                  {
                    type: String,
                    value: item?.biaya_tagihan[idx]?.nama_produk,
                  },
                  {
                    type: String,
                    value: item?.biaya_tagihan[idx]?.jumlah_upload,
                  },
                  {
                    type: String,
                    value:
                      item?.biaya_tagihan[idx]?.tagihan_jupiter
                        ?.Ada_Transaksi == "Y"
                        ? item?.biaya_tagihan[idx]?.tagihan_jupiter
                            .TGLTRANSAKSI == null
                          ? item?.biaya_tagihan[idx]?.tagihan_jupiter.Add_Info
                          : item?.biaya_tagihan[idx]?.tagihan_jupiter
                              .TGLTRANSAKSI
                        : item?.biaya_tagihan[idx]?.jumlah_upload > 0
                        ? "Belum Bayar"
                        : item?.biaya_tagihan[idx]?.tagihan_jupiter.Add_Info,
                  },
                  {
                    type: String,
                    value: this.hitungJumlah(
                      item?.pmb_finance?.pembayaran
                    ).toString(),
                  },
                  {
                    type: String,
                    value: this.hitungJumlahDitagih(
                      item?.biaya_tagihan
                    ).toString(),
                  },
                  {
                    type: String,
                    value: this.hitungJumlahDibayar(
                      item?.biaya_tagihan
                    ).toString(),
                  },
                  {
                    type: String,
                    value: item?.pmb_finance?.catatan_bak,
                  },
                ]);
              } else {
                rows.push([
                  {
                    type: String,
                    value: "",
                  },
                  {
                    type: String,
                    value: "",
                  },
                  {
                    type: String,
                    value: "",
                  },
                  {
                    type: String,
                    value: "",
                  },
                  {
                    type: String,
                    value: iter.jenis_biaya,
                  },
                  {
                    type: String,
                    value: iter.nominal,
                  },
                  {
                    type: String,
                    value: iter.tgl_tagihan,
                  },
                  {
                    type: String,
                    value: iter.tgl_jatuh_tempo,
                  },
                  {
                    type: String,
                    value: "",
                  },
                  {
                    type: String,
                    value: "",
                  },
                  {
                    type: String,
                    value: "",
                  },
                  {
                    type: String,
                    value: item?.biaya_tagihan[idx]?.nama_produk,
                  },
                  {
                    type: String,
                    value: item?.biaya_tagihan[idx]?.jumlah_upload,
                  },
                  {
                    type: String,
                    value:
                      item?.biaya_tagihan[idx]?.tagihan_jupiter
                        ?.Ada_Transaksi == "Y"
                        ? item?.biaya_tagihan[idx]?.tagihan_jupiter
                            .TGLTRANSAKSI == null
                          ? item?.biaya_tagihan[idx]?.tagihan_jupiter.Add_Info
                          : item?.biaya_tagihan[idx]?.tagihan_jupiter
                              .TGLTRANSAKSI
                        : item?.biaya_tagihan[idx]?.jumlah_upload > 0
                        ? "Belum Bayar"
                        : item?.biaya_tagihan[idx]?.tagihan_jupiter.Add_Info,
                  },
                  {
                    type: String,
                    value: "",
                  },
                  {
                    type: String,
                    value: "",
                  },
                  {
                    type: String,
                    value: "",
                  },

                  {
                    type: String,
                    value: "",
                  },
                ]);
              }
            });
          } else if (item?.pmb_finance?.cicilan === "Y") {
            item?.pmb_finance?.pembayaran.forEach((iter, idx) => {
              if (idx == 0) {
                rows.push([
                  {
                    type: Number,
                    value: n,
                  },
                  {
                    type: String,
                    value: item.no_ujian,
                  },
                  {
                    type: String,
                    value: item.nm_pd,
                  },
                  {
                    type: String,
                    value: item?.prodi?.nama + " " + item?.prodi?.jenjang,
                  },
                  {
                    type: String,
                    value: "",
                  },
                  {
                    type: String,
                    value: "",
                  },
                  {
                    type: String,
                    value: "",
                  },
                  {
                    type: String,
                    value: "",
                  },
                  {
                    type: String,
                    value: iter.jenis_biaya,
                  },
                  {
                    type: String,
                    value: iter.nominal,
                  },
                  {
                    type: String,
                    value: iter.tgl_tagihan,
                  },
                  {
                    type: String,
                    value: item?.biaya_tagihan[idx]?.nama_produk,
                  },
                  {
                    type: String,
                    value: item?.biaya_tagihan[idx]?.jumlah_upload,
                  },
                  {
                    type: String,
                    value:
                      item?.biaya_tagihan[idx]?.tagihan_jupiter
                        ?.Ada_Transaksi == "Y"
                        ? item?.biaya_tagihan[idx]?.tagihan_jupiter
                            .TGLTRANSAKSI == null
                          ? item?.biaya_tagihan[idx]?.tagihan_jupiter.Add_Info
                          : item?.biaya_tagihan[idx]?.tagihan_jupiter
                              .TGLTRANSAKSI
                        : item?.biaya_tagihan[idx]?.jumlah_upload > 0
                        ? "Belum Bayar"
                        : item?.biaya_tagihan[idx]?.tagihan_jupiter.Add_Info,
                  },
                  {
                    type: String,
                    value: this.hitungJumlah(
                      item?.pmb_finance?.pembayaran
                    ).toString(),
                  },
                  {
                    type: String,
                    value: this.hitungJumlahDitagih(
                      item?.biaya_tagihan
                    ).toString(),
                  },
                  {
                    type: String,
                    value: this.hitungJumlahDibayar(
                      item?.biaya_tagihan
                    ).toString(),
                  },
                  {
                    type: String,
                    value: item?.pmb_finance?.catatan_bak,
                  },
                ]);
              } else {
                rows.push([
                  {
                    type: String,
                    value: "",
                  },
                  {
                    type: String,
                    value: "",
                  },
                  {
                    type: String,
                    value: "",
                  },
                  {
                    type: String,
                    value: "",
                  },
                  {
                    type: String,
                    value: "",
                  },
                  {
                    type: String,
                    value: "",
                  },
                  {
                    type: String,
                    value: "",
                  },
                  {
                    type: String,
                    value: "",
                  },
                  {
                    type: String,
                    value: iter.jenis_biaya,
                  },
                  {
                    type: String,
                    value: iter.nominal,
                  },
                  {
                    type: String,
                    value: iter.tgl_tagihan,
                  },
                  {
                    type: String,
                    value: item?.biaya_tagihan[idx]?.nama_produk,
                  },
                  {
                    type: String,
                    value: item?.biaya_tagihan[idx]?.jumlah_upload,
                  },
                  {
                    type: String,
                    value:
                      item?.biaya_tagihan[idx]?.tagihan_jupiter
                        ?.Ada_Transaksi == "Y"
                        ? item?.biaya_tagihan[idx]?.tagihan_jupiter
                            .TGLTRANSAKSI == null
                          ? item?.biaya_tagihan[idx]?.tagihan_jupiter.Add_Info
                          : item?.biaya_tagihan[idx]?.tagihan_jupiter
                              .TGLTRANSAKSI
                        : item?.biaya_tagihan[idx]?.jumlah_upload > 0
                        ? "Belum Bayar"
                        : item?.biaya_tagihan[idx]?.tagihan_jupiter.Add_Info,
                  },
                  {
                    type: String,
                    value: "",
                  },
                  {
                    type: String,
                    value: "",
                  },
                  {
                    type: String,
                    value: "",
                  },

                  {
                    type: String,
                    value: "",
                  },
                ]);
              }
            });
          }

          n++;
        });
        //this.getBulan();
        let lo = this.$store.state.main.listOptions;
        let ll = lo?.prodi.find((f) => {
          return f.id === this.smsSelected;
        });
        let blobDataJadwal = await writeXlsxFile(rows, {
          filePatch: `Data Biaya Tagihan PMB Tahun ${this.smtSelected} Prodi ${ll.text}.xlsx`,
        });
        const blobData = new Blob([blobDataJadwal], {
          type: "application/vnd.ms-excel;charset=utf-8",
        });

        saveAs(
          blobData,
          `Data Biaya Tagihan PMB Tahun ${this.smtSelected} Prodi ${ll.text}.xlsx`
        );
        this.exportExcelString = "Export Excel";
      }
    },
    resetFilter() {
      this.needreset = true;
      this.filter.value = "";
      this.tabulator.setData(
        process.env.VUE_APP_KEUANGAN + "biayapmb",
        {
          filter: {
            kode_bank: this.model.kode_bank,
            kode_produk: this.model.kode_produk,
            kode_sub_comp: this.model.kode_sub_comp,
            id_smt: this.smtSelected,
            id_sms: this.smsSelected,
            angkatan: this.angkatan,
          },
          jenis_biaya: this.id_jenis_biaya,
        },
        tblTabulator.ajaxConfig()
      );
    },
    kirimNotifikasi() {
      HTTPKEU.get("tagihan/kirimnotif", {
        params: {
          id_smt: this.smtSelected,
          id_sms: this.smsSelected,
          angkatan: this.angkatan,
          jenis_biaya: this.id_jenis_biaya,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.statusNotifikasi.status = true;
            this.statusNotifikasi.msg = res.data.message;
          } else if (res.status == 404) {
            this.statusNotifikasi.status = false;
            this.statusNotifikasi.msg = "Route Not FOund";
          } else {
            this.statusNotifikasi.status = false;
            this.statusNotifikasi.msg = res.data.message;
          }
        })
        .catch((e) => {
          this.statusNotifikasi.status = false;
          this.statusNotifikasi.msg = e.response?.data?.message;
        });
    },
    getStatistik() {
      HTTPKEU.get("statistik/biayapmb", {
        params: {
          id_smt: this.smtSelected,
          id_sms: this.smsSelected,
          angkatan: this.angkatan,
          jenis_biaya: this.id_jenis_biaya,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.statistik = res.data;
          }
        })
        .catch((e) => {
          this.statistik = null;
        });
    },
    hitungJumlah(l) {
      let jumlah = 0;
      console.log(l);
      for (let li of l) {
        console.log(li);
        jumlah += parseFloat(li?.nominal);
      }
      return jumlah;
    },

    hitungJumlahDitagih(l) {
      let jumlah = 0;
      for (let li of l) {
        jumlah += parseFloat(li?.jumlah_upload);
      }
      return jumlah;
    },
    hitungJumlahDibayar(l) {
      let jumlah = 0;
      let ll = l.filter((f) => {
        return f?.validasi === "Y";
      });
      for (let li of ll) {
        jumlah += parseFloat(li?.jumlah_upload);
      }
      return jumlah;
    },
  },
};
</script>

<style scoped>
.table th {
  padding: 0.5rem 1rem;
}
.table td {
  padding: 0.5rem 1rem;
}
.columns-1 .form-label {
  margin-bottom: 0.1rem;
}
</style>
