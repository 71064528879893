<template>
  <div>
    <div v-if="loading" class="block-loading">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
      <div class="text-center text-xs mt-2">Loading... Please Wait...</div>
    </div>
    <div class="grid grid-cols-12 gap-0 mb-3">
      <dl class="col-span-12 lg:col-span-5">
        <DataList :gray="false" :dt="'Jenis Permohonan'">
          <template #dd>
            {{ detail.tagihan?.jenis_pengajuan?.nm_jenis_pengajuan }}
          </template>
        </DataList>
        <DataList :gray="false" :dt="'Semester'">
          <template #dd>
            {{ detail.tagihan?.smt?.nm_smt }}
          </template>
        </DataList>
        <DataList :gray="true" :dt="'Surat Pernyataan'">
          <template #dd>
            <a
              v-if="detail.tagihan?.surat_pernyataan != null"
              :href="detail.tagihan?.surat_pernyataan"
              target="_blank"
            >
              <download-icon class="w-4 mr-2"></download-icon> Download
            </a>
            <span v-else>Tidak Ada</span>
          </template>
        </DataList>
        <DataList :gray="false" :dt="'Bukti Pembayaran'">
          <template #dd>
            <a
              v-if="detail.tagihan?.bukti_bayar != null"
              :href="detail.tagihan?.bukti_bayar"
              target="_blank"
            >
              <download-icon class="w-4 mr-2"></download-icon> Download
            </a>
            <span v-else>Tidak Ada</span>
          </template>
        </DataList>
      </dl>

      <dl class="col-span-12 lg:col-span-7">
        <DataList
          :gray="false"
          :dd="detail.tagihan?.nm_pd"
          :dt="'Nama'"
        ></DataList>
        <DataList
          :gray="false"
          :dd="detail.tagihan?.npm"
          :dt="'NPM'"
        ></DataList>

        <DataList :gray="false" :dt="'&nbsp;'"></DataList>

        <DataList
          :gray="false"
          :dd="detail.tagihan?.jenis_pembayaran?.jenis_pembayaran"
          :dt="'Metode Pembayaran'"
        ></DataList>

        <DataList
          v-if="detail.tagihan.status == '2'"
          :gray="false"
          :dt="'Keterangan'"
        >
          <template #dd>
            <input
              v-model="model.infoTambahan"
              type="text"
              placeholder="Seperti no. transaksi, nama penerima, dll"
              class="form-control"
            />
          </template>
        </DataList>
      </dl>
    </div>

    <div class="grid grid-cols-12 gap-0 mb-3">
      <dl
        v-if="detail.tagihan.jenis_pengajuan_id == '3'"
        class="col-span-12 lg:col-span-4"
      >
        <DataList :gray="true" :dt="'Acc Kaprodi'">
          <template #dd>
            <span
              v-if="detail.tagihan.tgl_acc_kaprodi == null"
              class="py-1 px-2 rounded-full text-xs bg-purple-400 text-white text-center cursor-pointer font-medium"
            >
              Belum Validasi
            </span>
            <span v-else> {{ detail.tagihan?.tgl_acc_kaprodi }}</span>
          </template>
        </DataList>
      </dl>
      <dl class="col-span-12 lg:col-span-4">
        <DataList :gray="true" :dt="'Acc BAA'">
          <template #dd>
            <span
              v-if="detail.tagihan.tgl_acc_baa == null"
              class="py-1 px-2 rounded-full text-xs bg-purple-400 text-white text-center cursor-pointer font-medium"
            >
              Belum Validasi
            </span>
            <span v-else> {{ detail.tagihan?.tgl_acc_baa }}</span>
          </template>
        </DataList>
      </dl>
      <dl class="col-span-12 lg:col-span-4">
        <DataList :gray="true" :dt="'Acc BAK'">
          <template #dd>
            <span
              v-if="detail.tagihan.tgl_acc_bak == null"
              class="py-1 px-2 rounded-full text-xs bg-purple-400 text-white text-center cursor-pointer font-medium"
            >
              Belum Validasi
            </span>
            <span v-else> {{ detail.tagihan?.tgl_acc_bak }}</span>
          </template>
        </DataList>
      </dl>
    </div>

    <div class="grid grid-cols-12 gap-1 mb-3">
      <div class="col-span-6">
        <button
          v-if="detail.tagihan.status == 2"
          class="btn btn-success btn-sm ml-2"
          @click="validasi"
        >
          <check-circle-icon class="mr-2"></check-circle-icon>
          Validasi
        </button>
      </div>
      <div class="col-span-4"></div>
      <div class="col-span-4"></div>
    </div>
  </div>
</template>

<script>
import { HTTPKEU } from "@/services/http";
import axios from "axios";
import Alert from "../../../components/alerts/Alert";
import DataList from "@/components/table-and-list/DataList";
export default {
  name: "DetailPermohonan",
  components: { DataList, Alert },
  props: {
    detail: {
      type: Object,
      default: null,
    },
    process: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      transaksiBank: null,
      allowValidasi: false,
      statusValidasi: {
        msg: null,
        status: null,
      },
      loading: false,
      model: { infoTambahan: "", tglBayar: "" },
    };
  },
  watch: {
    detail: {
      handler: function (h) {
        this.transaksiBank = null;
        this.allowValidasi = false;
      },
      immediate: true,
    },
    process: {
      handler: function (h) {
        if (h == false) {
          this.transaksiBank = null;
          this.allowValidasi = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    getSubcomList() {
      HTTPKEU.get("master/subcomp").then((res) => {
        this.subcomps = res.data;
      });
    },
    getProdukList() {
      HTTPKEU.get("master/produk").then((res) => {
        this.produks = res.data;
      });
    },
    clearV() {
      this.statusValidasi.status = null;
      this.statusValidasi.msg = null;
    },
    validasi() {
      //console.log(this.model);
      this.$emit("validasiBak", null, this.model);
    },
  },
};
</script>

<style scoped></style>
