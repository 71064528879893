<template>
  <div class="grid grid-cols-12 gap-2">
    <div class="col-span-4">
      <Alert
        v-if="statusSimpanSkema.status != null"
        :type="statusSimpanSkema.status ? 'alert-success' : 'alert-danger'"
        :dismissable="true"
        >{{ statusSimpanSkema.msg }}</Alert
      >
      <div class="intro-x box p-3">
        <div
          class="flex text-slate-500 border-b border-slate-200 dark:border-darkmode-300 border-dashed pb-3 mb-3"
        >
          <div>Buat Skema Cicilan</div>
          <div class="ml-auto">
            <button class="btn btn-sm btn-warning" @click="buatskema = false">
              Tutup/Batal
            </button>
          </div>
        </div>
      </div>
      <div v-if="buatskema || editskema" class="intro-x box p-3">
        <div class="grid grid-cols-12 gap-2 items-center mb-3">
          <div class="col-span-6">
            <label class="form-label">Tahun Akademik</label>
            <select v-model="modelskema.tahun_akademik" class="form-select">
              <option
                v-for="item in listOptionSmtGanjil"
                :key="item.id"
                :value="item.thn_ajaran?.nm_thn_ajaran"
              >
                {{ item.thn_ajaran?.nm_thn_ajaran }}
              </option>
            </select>
          </div>
          <div class="col-span-6">
            <label class="form-label">Program Studi</label>
            <select v-model="modelskema.id_sms" class="form-select">
              <option
                v-for="item in listOptionSms"
                :key="item.id"
                :value="item.value"
              >
                {{ item.text }}
              </option>
            </select>
          </div>
        </div>

        <div class="grid grid-cols-12 gap-2 items-center mb-3">
          <div class="col-span-4">
            <label class="form-label">Jml Cicilan</label>
            <div v-if="buatskema" class="mt-2 mb-2 pl-2">
              <MinusCircleIcon
                class="w-5 mr-2 cursor-pointer"
                @click="
                  () => {
                    if (jumcicil > 1) jumcicil--;
                  }
                "
              />
              {{ jumcicil }}
              <PlusCircleIcon
                class="w-5 ml-2 cursor-pointer"
                @click="
                  () => {
                    jumcicil++;
                  }
                "
              />
            </div>
          </div>
          <div class="col-span-8">
            <label class="form-label">Pembiayaan</label>
            <select v-model="modelskema.group_pembiayaan" class="form-select">
              <option value="Sumbangan">Sumbangan</option>
              <option value="UK1+UK2">UK1+UK2</option>
            </select>
          </div>
        </div>
        <div class="items-center mb-3">
          <label>Total Nominal</label>
          <div class="font-bold">
            {{ $h.IDR(totalNominalSkemaCicilan) }}
          </div>
        </div>
        <div
          v-for="(sk, k) in modelskema.cicilan"
          class="grid grid-cols-12 gap-2 items-center mb-3"
        >
          <div class="col-span-4">
            <label class="form-label">Cicilan Ke</label>
            <div>{{ sk.cicilan_ke }}</div>
          </div>
          <div class="col-span-8">
            <label class="form-label">Nominal</label>
            <input
              v-model="sk.nominal"
              type="number"
              class="form-control"
              placeholder="Nominal"
            />
          </div>
        </div>
        <div class="items-center mb-3">
          <button v-if="buatskema" class="btn btn-success" @click="simpanSkema">
            Simpan
          </button>
          <button v-if="editskema" class="btn btn-primary" @click="updateSkema">
            Update
          </button>
        </div>
      </div>
    </div>
    <div class="col-span-4">
      <div class="box p-3">
        <div class="">
          <label class="form-label">Tahun Akademik</label>
          <TailSelectSelector
            :model="smtSelected"
            :list-options="listOptionSmtGanjil"
            @cbox-select="selectSemester"
          ></TailSelectSelector>
        </div>
      </div>
      <div class="intro-y box p-3">
        <div
          class="flex text-slate-500 border-b border-slate-200 dark:border-darkmode-300 border-dashed pb-3 mb-3"
        >
          <div>Skema Cicilan Sumbangan</div>
          <div class="ml-auto">
            <button class="btn btn-sm" @click="setbuatskema('Sumbangan')">
              Buat Skema
            </button>
          </div>
        </div>
        <div
          v-for="(list, k) in masterSkema.sumbangan"
          :key="k"
          class="flex items-center mb-1 hover:bg-gray-300 cursor-pointer p-1"
          @click="editingSkema(list)"
        >
          <div class="items-center">
            <div class="">
              {{ list.group_pembiayaan }}
            </div>
            <div v-if="list.prodi" class="text-blue-600 text-xs">
              {{ list.prodi?.nama }} ({{ list.prodi?.jenjang }})
            </div>
            <div v-else class="text-blue-600 text-xs">Semua Prodi</div>
          </div>
          <div class="ml-auto">
            <div class="text-yellow-700 text-xs">{{ list.group_skema }} X</div>
            <div class="text-green-900 text-xs">
              {{ $h.IDR(list.group_totalnominal) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-4">
      <div class="box p-3">
        <div class="">
          <label class="form-label">Program Studi</label>
          <TailSelectSelector
            :model="smsSelected"
            :list-options="listOptionSms"
            @cbox-select="selectProdi"
          ></TailSelectSelector>
        </div>
      </div>
      <div class="intro-y box p-3">
        <div
          class="flex text-slate-500 border-b border-slate-200 dark:border-darkmode-300 border-dashed pb-3 mb-3"
        >
          <div>Skema Cicilan UK</div>
          <div class="ml-auto">
            <button class="btn btn-sm" @click="setbuatskema('UK1+UK2')">
              Buat Skema
            </button>
          </div>
        </div>
        <div
          v-for="(list, k) in masterSkema.uk"
          :key="k"
          class="flex items-center mb-1 hover:bg-gray-300 cursor-pointer p-1"
          @click="editingSkema(list)"
        >
          <div class="items-center">
            <div class="">
              {{ list.group_pembiayaan }}
            </div>

            <div v-if="list.prodi" class="text-blue-600 text-xs">
              {{ list.prodi?.nama }} ({{ list.prodi?.jenjang }})
            </div>
            <div v-else class="text-blue-600 text-xs">Semua Prodi</div>
          </div>
          <div class="ml-auto">
            <div class="text-yellow-700 text-xs">{{ list.group_skema }} X</div>
            <div class="text-green-900 text-xs">
              {{ $h.IDR(list.group_totalnominal) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTPKEU } from "../../../services/http";
import TailSelectSelector from "../../../components/form/TailSelectSelector";
import { option } from "@/utils/listoptions";
import Alert from "../../../components/alerts/Alert";
export default {
  name: "SkemaCicilan",
  components: { Alert, TailSelectSelector },
  data() {
    return {
      loading: false,
      smt: { a_periode_aktif: 0 },
      angkatan: new Date().getFullYear(),
      masterSkema: { sumbangan: [], uk: [] },
      smsSelected: null,
      smtSelected: null,
      modelskema: {
        group_pembiayaan: "Sumbangan",
        group_skema: null,
        group_totalnominal: null,
        tahun_akademik: null,
        id_sms: null,
        cicilan: [
          {
            cicilan_ke: 1,
            nominal: 0,
          },
        ],
        //id_fakultas: null,
      },
      modelcicilan: {
        nominal: null,
        cicilan_ke: null,
        group_pembiayaan: null,
        group_skema: null,
        group_totalnominal: null,
        tahun_akademik: null,
        id_sms: null,
        id_fakultas: null,
      },
      jumcicil: 1,
      buatskema: false,
      editskema: false,
      statusSimpanSkema: {
        msg: null,
        status: null,
      },
    };
  },
  computed: {
    listOptionsState() {
      return this.$store.state.main.listOptions;
    },
    tabulatorLoaded() {
      return this.$store.state.main.tabulatorDataLoaded;
    },
    listOptionSmtGanjil() {
      let lo = this.$store.state.main.listOptions;
      if (lo?.semester?.length > 0) {
        return lo?.semester.filter((f) => {
          return f.smt == 1;
        });
      } else {
        return [];
      }
    },
    listOptionSms() {
      let lo = this.$store.state.main.listOptions;
      let sms = [];
      if (lo?.prodi?.length > 0) {
        sms = lo?.prodi.filter((f) => {
          return f.id != 0;
        });
      }
      let defaultOpt = {
        id: 0,
        value: "0",
        text: "Semua Prodi",
        selected: false,
      };
      sms.unshift(defaultOpt);
      return sms;
    },
    totalNominalSkemaCicilan() {
      let total = 0;
      if (this.modelskema?.cicilan?.length > 0) {
        for (let t of this.modelskema.cicilan) {
          total += parseFloat(t.nominal);
        }
      }

      return total;
    },
  },
  watch: {
    jumcicil: {
      handler: function (next, prev) {
        console.log(prev, next);
        if (prev < next) {
          this.modelskema.group_skema = next;
          this.modelskema.cicilan.push({
            cicilan_ke:
              this.modelskema.cicilan.length > 0
                ? this.modelskema.cicilan.length + 1
                : 1,
            nominal: 0,
          });
        } else {
          this.modelskema.cicilan.splice(this.modelskema.cicilan.length - 1, 1);
        }
        if (this.jumcicil != this.modelskema.cicilan.length) {
          this.jumcicil = this.modelskema.cicilan.length;
        }
      },
      deep: true,
    },
  },
  beforeMount() {
    option.fetchSemester();
    option.fetchProdi();
  },
  mounted() {},
  methods: {
    ambilSkemaCicilanMaba() {
      let that = this;
      Promise.all([
        this.getCicilanNominalGroup("Sumbangan"),
        this.getCicilanNominalGroup("UK1+UK2"),
      ]).then(function (result) {
        that.masterSkema.sumbangan = result[0].data;
        that.masterSkema.uk = result[1].data;
      });
    },
    getCicilanNominalGroup(group) {
      return HTTPKEU.get("list/cicilanmabanominalgroup", {
        params: {
          id_sms: this.smsSelected,
          id_smt: this.smtSelected,
          group: group,
        },
      });
    },
    selectProdi(curr, id) {
      this.smsSelected = id;
      this.modelskema.id_sms = id;
      if (this.smsSelected?.length > 0) this.ambilSkemaCicilanMaba();
    },
    selectSemester(curr, id) {
      this.smtSelected = id;
      if (this.smtSelected?.length > 0)
        this.angkatan = this.smtSelected.substr(0, 4);
      this.smt = curr[0];
      this.modelskema.tahun_akademik = curr[0]?.thn_ajaran?.nm_thn_ajaran;
      if (this.smtSelected?.length > 0) this.ambilSkemaCicilanMaba();
    },
    setProdi(curr, id) {
      this.modelskema.id_sms = id;
    },
    setSemester(curr, id) {
      this.modelskema.tahun_akademik = curr[0]?.thn_ajaran?.nm_thn_ajaran;
    },
    simpanSkema() {
      this.statusSimpanSkema.msg = null;
      this.statusSimpanSkema.status = null;
      this.modelskema.group_totalnominal = this.totalNominalSkemaCicilan;
      HTTPKEU.post("master/cicilanmabanominal", this.modelskema)
        .then((res) => {
          if (res.status === 200) {
            this.statusSimpanSkema.msg = res.data?.message;
            this.statusSimpanSkema.status = true;
            this.jumcicil = 1;
            this.modelskema = {
              group_pembiayaan: "Sumbangan",
              group_skema: null,
              group_totalnominal: null,
              tahun_akademik: this.modelskema.tahun_akademik,
              id_sms: this.modelskema.id_sms,
              cicilan: [
                {
                  cicilan_ke: 1,
                  nominal: 0,
                },
              ],
            };
          }
        })
        .catch((err) => {
          this.statusSimpanSkema.msg = err.message;
          this.statusSimpanSkema.status = false;
        })
        .finally(() => {
          if (this.smtSelected?.length > 0) this.ambilSkemaCicilanMaba();
        });
    },
    updateSkema() {
      this.statusSimpanSkema.msg = null;
      this.statusSimpanSkema.status = null;
      this.modelskema.group_totalnominal = this.totalNominalSkemaCicilan;
      HTTPKEU.patch(
        "master/cicilanmabanominal/" + 0,
        this.modelskema
      )
        .then((res) => {
          if (res.status === 200) {
            this.statusSimpanSkema.msg = res.data?.message;
            this.statusSimpanSkema.status = true;
            /*this.jumcicil = 1;
            this.modelskema = {
              group_pembiayaan: "Sumbangan",
              group_skema: null,
              group_totalnominal: null,
              tahun_akademik: this.modelskema.tahun_akademik,
              id_sms: this.modelskema.id_sms,
              cicilan: [
                {
                  cicilan_ke: 1,
                  nominal: 0,
                },
              ],
            };*/
          }
        })
        .catch((err) => {
          this.statusSimpanSkema.msg = err.message;
          this.statusSimpanSkema.status = false;
        })
        .finally(() => {
          if (this.smtSelected?.length > 0) this.ambilSkemaCicilanMaba();
        });
    },
    setbuatskema(skema) {
      this.buatskema = true;
      this.editskema = false;
      this.modelskema = {
        group_pembiayaan: "Sumbangan",
        group_skema: null,
        group_totalnominal: null,
        tahun_akademik: this.modelskema.tahun_akademik,
        id_sms: this.modelskema.id_sms,
        cicilan: [
          {
            cicilan_ke: 1,
            nominal: 0,
          },
        ],
      };
      this.modelskema.group_pembiayaan = skema;
    },
    editingSkema(cicilamskema) {
      this.modelskema = null;

      this.modelskema = cicilamskema;
      this.editskema = true;
      this.buatskema = false;

      //this.jumcicil = cicilamskema.group_skema;
      this.modelskema.id_sms =
        cicilamskema.id_sms?.length > 0 ? cicilamskema.id_sms : 0;
      this.modelskema.cicilan = cicilamskema.skema;
      console.log(cicilamskema.skema);

      console.log(cicilamskema);
    },
  },
};
</script>

<style scoped></style>
