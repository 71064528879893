<template>
  <div class="grid grid-cols-12 gap-1">
    <div class="col-span-2">
      <div class="columns-1 mb-2">
        <label class="form-label">No. Test</label>
        <div class="font-bold">
          {{ mhs?.no_ujian }}
        </div>
      </div>
      <div class="columns-1 mb-2">
        <label class="form-label">Nama</label>
        <div class="font-bold">
          {{ mhs?.nm_pd }}
        </div>
      </div>
      <div class="columns-1 mb-2">
        <label class="form-label">NO. VA</label>
        <div class="font-bold">
          <span class="text-yellow-700 pr-1">75105</span>{{ mhs?.no_va }}
        </div>
      </div>

      <div class="columns-1 mb-2">
        <label class="form-label">Prodi</label>
        <div class="font-bold">
          {{ mhs?.prodiFix?.nama }} ({{ mhs?.prodiFix?.jenjang }})
        </div>
      </div>

      <div class="columns-1 mb-2">
        <label class="form-label">Waktu Kuliah</label>
        <div class="font-bold">
          {{ mhs?.waktu === "P" ? "Pagi" : "Malam" }}
        </div>
      </div>

      <div class="columns-1 mb-2">
        <label class="form-label">Status Ujian</label>
        <div v-if="mhs?.hasil_ujian == 1" class="font-bold text-theme-1">
          LULUS
        </div>
        <div v-else-if="mhs?.hasil_ujian == 0" class="font-bold text-theme-6">
          TIDAK LULUS
        </div>
        <div v-else class="font-bold text-gray-600">BELUM DITENTUKAN</div>
      </div>
      <div class="columns-1 mb-2">
        <label class="form-label">Grade</label>
        <div v-if="mhs?.grade" class="font-bold">
          {{ mhs?.grade }}
        </div>
        <div v-else class="font-bold text-gray-600">BELUM DITENTUKAN</div>
      </div>
    </div>
    <div class="col-span-2">
      <div class="columns-1 mb-2">
        <label class="form-label">Cicilan</label>
      </div>
      <div class="grid grid-cols-2 mt-2 border-b mb-2">
        <div class="col-span-1">
          <label class="font-bold" for="modeCicilan">{{
            modeCicilan ? "Ya" : "Tidak"
          }}</label>
        </div>
        <div class="col-span-1">
          <input
            id="modeCicilan"
            v-model="modeCicilan"
            type="checkbox"
            class="form-check-switch ml-3"
          />

          <!--          <button
            class="btn btn-sm mb-1 -mt-1"
            :class="modeCicilan ? 'btn-primary' : 'btn-default'"
            @click="modeCicilan = !modeCicilan"
          >
            {{ modeCicilan ? "Tagihan Normal" : "Cicilan" }}
          </button>-->
        </div>
      </div>

      <div class="columns-1 mb-2">
        <label class="form-label">Biaya Sumbangan</label>
        <div class="font-bold">
          {{ $h.IDR(masterBiaya.sumbangan?.biaya) }}
        </div>
      </div>

      <div class="columns-1 mb-2">
        <label class="form-label">Biaya UK 1</label>
        <div class="font-bold">
          {{ $h.IDR(masterBiaya.uk1?.biaya) }}
        </div>
      </div>

      <div class="columns-1 mb-2">
        <label class="form-label">Biaya UK 2</label>
        <div class="font-bold">
          {{ $h.IDR(masterBiaya.uk2?.biaya) }}
        </div>
      </div>
      <div class="columns-1 mb-2">
        <label class="form-label">Total </label>
        <div class="font-bold">{{ $h.IDR(totalBiaya) }}</div>
      </div>

      <div class="columns-1 mb-2">
        <label class="form-label">Potongan Sumbangan</label>
        <input
          v-model="potongan"
          type="number"
          class="form-control"
          placeholder="Nominal Potongan"
          required
        />
      </div>
      <div class="columns-1 mb-2">
        <label class="form-label">Potongan UK</label>
        <input
          v-model="potongan_uk"
          type="number"
          class="form-control"
          placeholder="Nominal Potongan UK"
          required
        />
      </div>
      <div class="columns-1 mb-2">
        <label class="form-label" title="Total Biaya Kuliah dikurangai Potongan"
          >Total Biaya Kuliah</label
        >
        <div>{{ $h.IDR(totalBiayaKuliah) }}</div>
      </div>
    </div>
    <div class="col-span-8">
      <div v-if="!modeCicilan" class="bg-blue-50">
        <button class="btn btn-sm btn-default ml-2 mt-2" @click="ambilBiaya">
          <RefreshCcwIcon class="w-3 mr-1" /> Refresh
        </button>
        <table class="table">
          <tr>
            <th class="border-b">Rencana Pembayaran</th>
            <th class="border-b">Jatuh Tempo</th>
            <th class="border-b">Telah Bayar</th>
            <th class="border-b">Tanggal Tagihan</th>
            <th class="border-b">Upload</th>
          </tr>
          <tr>
            <th>
              <div class="flex flex-row">
                <div class="flex-1 pt-2">Biaya Sumbangan</div>
                <div class="flex-initial">
                  <button
                    class="btn btn-sm text-center btn-warning py-1 px-2"
                    @click="addTagihSumbangan"
                  >
                    <PlusIcon class="w-4" />
                  </button>
                </div>
              </div>
            </th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          <tr v-for="(sumb, i) in tagihSumbangan" :key="i">
            <td>
              <div class="input-group">
                <div
                  v-if="Boolean(sumb.upload_tagih) === false"
                  :id="'btn-hapus-s-' + i"
                  class="input-group-text"
                  @click="hapusBiaya(sumb, 'Sumbangan', i)"
                >
                  <MinusSquareIcon class="cursor-pointer w-4 h-4" />
                </div>
                <input
                  v-model="sumb.nominal"
                  type="number"
                  class="form-control"
                  placeholder="Nominal Tagihan"
                  required
                  :aria-describedby="'btn-hapus-s-' + i"
                />
              </div>
            </td>
            <td>
              <input
                v-model="sumb.tgl_jatuh_tempo"
                type="date"
                class="form-control"
              />
            </td>
            <td>{{ $h.IDR(sumb.nominal_bayar) }}</td>
            <td>
              <input
                v-model="sumb.tgl_tagihan"
                type="date"
                class="form-control"
              />
            </td>
            <td class="text-center">
              <input
                :disabled="sumb.tgl_bayar?.length > 0"
                type="checkbox"
                value="1"
                :checked="parseInt(sumb.upload_tagih) === 1"
                class="form-check-switch"
                @change="setDataTagihan(sumb)"
              />
            </td>
          </tr>

          <tr>
            <th>
              <div class="flex flex-row">
                <div class="flex-1 pt-2">Biaya UK 1</div>
                <div class="flex-initial">
                  <button
                    class="btn btn-sm text-center btn-warning py-1 px-2"
                    @click="addTagihUk1"
                  >
                    <PlusIcon class="w-4" />
                  </button>
                </div>
              </div>
            </th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          <tr v-for="(uk1, i) in tagihUk1" :key="i">
            <td>
              <div class="input-group">
                <div
                  v-if="Boolean(uk1.upload_tagih) === false"
                  :id="'btn-hapus-uk1-' + i"
                  class="input-group-text"
                  @click="hapusBiaya(uk1, 'UK I', i)"
                >
                  <MinusSquareIcon class="cursor-pointer w-4 h-4" />
                </div>
                <input
                  v-model="uk1.nominal"
                  type="number"
                  class="form-control"
                  placeholder="Nominal Tagihan"
                  required
                  :aria-describedby="'btn-hapus-uk1-' + i"
                />
              </div>
            </td>
            <td>
              <input
                v-model="uk1.tgl_jatuh_tempo"
                type="date"
                class="form-control"
              />
            </td>
            <td>{{ $h.IDR(uk1.nominal_bayar) }}</td>
            <td>
              <input
                v-model="uk1.tgl_tagihan"
                type="date"
                class="form-control"
              />
            </td>
            <td class="text-center">
              <input
                :disabled="uk1.tgl_bayar?.length > 0"
                type="checkbox"
                class="form-check-switch"
                value="1"
                :checked="parseInt(uk1.upload_tagih) === 1"
                @change="setDataTagihan(uk1)"
              />
            </td>
          </tr>

          <tr>
            <th>
              <div class="flex flex-row">
                <div class="flex-1 pt-2">Biaya UK 2</div>
                <div class="flex-initial">
                  <button
                    class="btn btn-sm text-center btn-warning py-1 px-2"
                    @click="addTagihUk2"
                  >
                    <PlusIcon class="w-4" />
                  </button>
                </div>
              </div>
            </th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          <tr v-for="(uk2, i) in tagihUk2" :key="i">
            <td>
              <div class="input-group">
                <div
                  v-if="Boolean(uk2.upload_tagih) === false"
                  :id="'btn-hapus-uk2-' + i"
                  class="input-group-text"
                  @click="hapusBiaya(uk2, 'UK II', i)"
                >
                  <MinusSquareIcon class="cursor-pointer w-4 h-4" />
                </div>
                <input
                  v-model="uk2.nominal"
                  type="number"
                  class="form-control"
                  placeholder="Nominal Tagihan"
                  required
                  :aria-describedby="'btn-hapus-uk2-' + i"
                />
              </div>
            </td>
            <td>
              <input
                v-model="uk2.tgl_jatuh_tempo"
                type="date"
                class="form-control"
              />
            </td>
            <td>{{ $h.IDR(uk2.nominal_bayar) }}</td>
            <td>
              <input
                v-model="uk2.tgl_tagihan"
                type="date"
                class="form-control"
              />
            </td>
            <td class="text-center">
              <input
                :disabled="uk2.tgl_bayar?.length > 0"
                type="checkbox"
                class="form-check-switch"
                value="1"
                :checked="parseInt(uk2.upload_tagih) === 1"
                @change="setDataTagihan(uk2)"
              />
            </td>
          </tr>
          <tr class="border-t border-green-700 align-top">
            <td>
              <label
                class="form-label"
                title="Total Biaya Kuliah yang akan ditagihkan ke calon Mahasiswa"
                >Total Rencana Pembayaran</label
              >
              <div>{{ $h.IDR(totalHarusDibayar) }}</div>
            </td>
            <td>
              <label class="form-label">Catatan BKP</label>
              <div>
                {{ pmbFinance?.catatan_bkp ? pmbFinance?.catatan_bkp : "-" }}
              </div>
            </td>
            <td>
              <label class="form-label">Catatan BAK</label>
              <input
                v-model="catatan_bak"
                type="text"
                class="form-control"
                placeholder="Catatan BAK"
                required
              />
            </td>
            <td>
              <button class="btn btn-primary" @click="simpanPembiayaan">
                <SaveIcon class="w-4 mr-2"></SaveIcon> Simpan Pembiayaan
              </button>
            </td>
          </tr>
          <tr class="align-top">
            <td>
              <label class="form-label" title="Selisih Biaya Kuliah"
                >Selisih</label
              >
              <div>{{ $h.IDR(totalBiayaKuliah - totalHarusDibayar) }}</div>
            </td>
            <td>
              <label
                class="form-label"
                title="Total telah diupload baik yg telah dibayar atau belum"
                >Total Tagih</label
              >
              <div>{{ $h.IDR(totalUpload) }}</div>
            </td>
            <td>
              <label class="form-label" title="Total Biaya Kuliah Dibayar"
                >Total Telah Dibayar</label
              >
              <div>{{ $h.IDR(totalTelahDibayar) }}</div>
            </td>
            <td>
              <label
                class="form-label"
                title="Total akan diupload saat penagihan ini"
                >Total Akan Upload</label
              >
              <div>{{ $h.IDR(totalHarusDiupload) }}</div>
            </td>
          </tr>
        </table>
      </div>

      <CreateTagihanCicilan
        v-else
        :mhs="mhs"
        :potongan-pmb="potongan"
        @loadingBlock="setLoadingBlock"
        @dataTagihan="setDataTagihanCicilan"
      />

      <Alert
        v-if="statusHapus.status != null"
        :type="statusHapus.status ? 'alert-success' : 'alert-danger'"
        :dismissable="true"
        >{{ statusHapus.msg }}</Alert
      >

      <Alert
        v-if="statusGetTagihan.status != null"
        :type="statusGetTagihan.status ? 'alert-success' : 'alert-danger'"
        :dismissable="true"
        >{{ statusGetTagihan.msg }}</Alert
      >

      <Alert
        v-if="statusSimpanPembiayaan.status != null"
        :type="statusSimpanPembiayaan.status ? 'alert-success' : 'alert-danger'"
        :dismissable="true"
        >{{ statusSimpanPembiayaan.msg }}</Alert
      >
    </div>

    <ModalDialog
      :question="
        'Anda yakin ingin menghapus Pembiayaan Biaya ' +
        pmbpembiayaanhapus +
        '?'
      "
    >
      <template #delete-button>
        <button
          type="button"
          class="btn btn-danger w-24"
          @click="konfirmHapusBiaya"
        >
          Ya
        </button>
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import { HTTPKEU } from "../../../services/http";
import ModalDialog from "../../../components/utility/ModalDialog";
import Alert from "../../../components/alerts/Alert";
import moment from "moment";
import CreateTagihanCicilan from "./CreateTagihanCicilan";
export default {
  name: "FormCreateTagihan",
  components: {
    CreateTagihanCicilan,
    ModalDialog,
    Alert,
  },
  props: {
    model: {
      type: Object,
      default: null,
    },
    mhs: {
      type: Object,
      default: null,
    },
    jenisBiayaList: {
      type: Array,
      default: null,
    },
    idUjian: {
      type: String,
      default: null,
    },
    angkatan: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      modeCicilan: false,
      biayaKuliah: null,
      id_biaya_sumbangan: 0,
      id_jenis_biaya: ["4", "2", "3"],
      tagihSumbangan: [],
      tagihUk1: [],
      tagihUk2: [],
      tagihUk: [],
      masterBiaya: { sumbangan: null, uk1: null, uk2: null },
      statusGetTagihan: {
        msg: null,
        status: null,
      },
      statusSimpanPembiayaan: {
        msg: null,
        status: null,
      },
      statusHapus: {
        msg: null,
        status: null,
      },
      pmbFinance: null,
      loading: false,
      potongan: 0,
      potongan_uk: 0,
      catatan_bak: null,
      pmbpembiayaanid: null,
      pmbpembiayaanidx: null,
      pmbpembiayaanhapus: null,
    };
  },
  computed: {
    totalBiaya() {
      return (
        parseFloat(this.masterBiaya.sumbangan?.biaya) +
        parseFloat(this.masterBiaya.uk1?.biaya) +
        parseFloat(this.masterBiaya.uk2?.biaya)
      );
    },
    totalBiayaKuliah() {
      let total =
        parseFloat(this.masterBiaya.sumbangan?.biaya) +
        parseFloat(this.masterBiaya.uk1?.biaya) +
        parseFloat(this.masterBiaya.uk2?.biaya);
      if (this.potongan > 0) {
        total -= parseFloat(this.potongan);
      }
      if (this.potongan_uk > 0) {
        total -= parseFloat(this.potongan_uk);
      }

      console.log("counting harus bayar", total);
      return total;
    },
    totalHarusDibayar() {
      let total = 0;
      if (this.tagihSumbangan) {
        for (let s of this.tagihSumbangan) {
          total += parseFloat(s.nominal);
        }
      }
      if (this.tagihUk1) {
        for (let s of this.tagihUk1) {
          total += parseFloat(s.nominal);
        }
      }
      if (this.tagihUk2) {
        for (let s of this.tagihUk2) {
          total += parseFloat(s.nominal);
        }
      }

      return total;
    },
    totalTelahDibayar() {
      let total = 0;
      if (this.tagihSumbangan) {
        for (let s of this.tagihSumbangan.filter((t) => {
          return t.tgl_bayar?.length > 0;
        })) {
          total += parseFloat(s.nominal);
        }
      }
      if (this.tagihUk1) {
        for (let s of this.tagihUk1.filter((t) => {
          return t.tgl_bayar?.length > 0;
        })) {
          total += parseFloat(s.nominal);
        }
      }
      if (this.tagihUk2) {
        for (let s of this.tagihUk2.filter((t) => {
          return t.tgl_bayar?.length > 0;
        })) {
          total += parseFloat(s.nominal);
        }
      }

      console.log("counting telah bayar", total);
      return total;
    },
    totalUpload() {
      let total = 0;
      if (this.tagihSumbangan) {
        for (let s of this.tagihSumbangan.filter((t) => {
          return t.upload_tagih === true || t.upload_tagih == 1;
        })) {
          total += parseFloat(s.nominal);
        }
      }
      if (this.tagihUk1) {
        for (let s of this.tagihUk1.filter((t) => {
          return t.upload_tagih === true || t.upload_tagih == 1;
        })) {
          total += parseFloat(s.nominal);
        }
      }
      if (this.tagihUk2) {
        for (let s of this.tagihUk2.filter((t) => {
          return t.upload_tagih === true || t.upload_tagih == 1;
        })) {
          total += parseFloat(s.nominal);
        }
      }

      return total;
    },
    totalHarusDiupload() {
      let total = 0;
      if (this.tagihSumbangan) {
        for (let s of this.tagihSumbangan.filter((t) => {
          return Boolean(t.upload_tagih) === true && t.nominal_bayar == null;
        })) {
          total += parseFloat(s.nominal);
        }
      }
      if (this.tagihUk1) {
        for (let s of this.tagihUk1.filter((t) => {
          return Boolean(t.upload_tagih) === true && t.nominal_bayar == null;
        })) {
          total += parseFloat(s.nominal);
        }
      }
      if (this.tagihUk2) {
        for (let s of this.tagihUk2.filter((t) => {
          return Boolean(t.upload_tagih) === true && t.nominal_bayar == null;
        })) {
          total += parseFloat(s.nominal);
        }
      }

      return total;
    },
  },
  watch: {
    idUjian: {
      handler: function (h) {
        this.ambilBiaya();
      },
      deep: true,
    },
    tagihSumbangan: {
      handler: function () {},
      deep: true,
    },
    tagihUk1: {
      handler: function () {},
      deep: true,
    },
    tagihUk2: {
      handler: function () {},
      deep: true,
    },
    potongan: {
      handler: function () {},
      deep: true,
    },
  },
  beforeMount() {
    this.addTagihSumbangan();
    this.addTagihUk1();
    this.addTagihUk2();
  },
  mounted() {
    this.ambilBiaya();
  },
  methods: {
    addTagihSumbangan() {
      this.tagihSumbangan.push({
        nominal: 0,
        tgl_jatuh_tempo: moment(new Date()).format("YYYY-MM-DD"),
        nominal_bayar: null,
        status_bayar: null,
        tgl_tagihan: moment(new Date()).format("YYYY-MM-DD"),
        upload_tagih: false,
        id_jenis_biaya: 4,
        cicilan_ke:
          this.tagihSumbangan?.length > 0 ? this.tagihSumbangan?.length + 1 : 1,
      });
    },
    addTagihUk1() {
      this.tagihUk1.push({
        nominal: 0,
        tgl_jatuh_tempo: moment(new Date()).format("YYYY-MM-DD"),
        nominal_bayar: null,
        status_bayar: null,
        tgl_tagihan: moment(new Date()).format("YYYY-MM-DD"),
        upload_tagih: false,
        id_jenis_biaya: 2,
        cicilan_ke: this.tagihUk1?.length > 0 ? this.tagihUk1?.length + 1 : 1,
      });
    },
    addTagihUk2() {
      this.tagihUk2.push({
        nominal: 0,
        tgl_jatuh_tempo: moment(new Date()).format("YYYY-MM-DD"),
        nominal_bayar: null,
        status_bayar: null,
        tgl_tagihan: moment(new Date()).format("YYYY-MM-DD"),
        upload_tagih: false,
        id_jenis_biaya: 3,
        cicilan_ke: this.tagihUk2?.length > 0 ? this.tagihUk2?.length + 1 : 1,
      });
    },
    ambilBiaya() {
      let that = this;
      //if (!that.modeCicilan) {
      this.tagihSumbangan = [];
      this.tagihUk1 = [];
      this.tagihUk2 = [];
      this.tagihUk = [];
      //}
      Promise.all([
        this.getBiayaDetail(4, this.mhs.gelombang),
        this.getBiayaDetail(2, this.mhs.waktu),
        this.getBiayaDetail(3, this.mhs.waktu),
      ])
        .then(function (result) {
          that.masterBiaya.sumbangan = result[0].data;
          that.masterBiaya.uk1 = result[1].data;
          that.masterBiaya.uk2 = result[2].data;
        })
        .finally(() => {
          that.cekTagihanMaba();
        });
    },
    getBiaya(id_jenis_biaya) {
      return HTTPKEU.post("master/getbiayakuliahlist", {
        tahun: this.angkatan,
        id_jenis_biaya: id_jenis_biaya,
        id_sms: this.mhs?.prodiFix?.id_sms,
      });
    },
    getBiayaDetail(id_jenis_biaya, sub_jenis) {
      return HTTPKEU.post("master/getbiayakuliahdetail", {
        tahun: this.angkatan,
        id_jenis_biaya: id_jenis_biaya,
        id_sms: this.mhs?.prodiFix?.id_sms,
        sub_jenis: sub_jenis,
      });
    },
    cekTagihanMaba() {
      this.loading = true;
      this.$emit("loadingBlock", this.loading);
      this.statusGetTagihan.msg = null;
      this.statusGetTagihan.status = null;
      HTTPKEU.post("tagihan/get_tagihan_pmb", {
        id_daftar: this.mhs.id_daftar,
      })
        .then((res) => {
          if (res.status === 200) {
            this.pmbFinance = res?.data;
            this.modeCicilan = this.pmbFinance.cicilan === "Y" ? true : false;
            this.catatan_bak = this.pmbFinance.catatan_bak;
            this.catatan_bkp = this.pmbFinance.catatan_bkp;
            this.potongan = this.pmbFinance.potongan;
            this.potongan_uk = this.pmbFinance.potongan_uk;
          } else {
            this.statusGetTagihan.msg = res.data?.message;
            this.statusGetTagihan.status = false;
            this.pmbFinance = null;
            this.modeCicilan = false;
          }
        })
        .catch((err) => {
          this.statusGetTagihan.msg = err.response?.data?.message;
          this.statusGetTagihan.status = false;
          this.pmbFinance = null;
          this.modeCicilan = false;
        })
        .finally(() => {
          this.loading = false;
          if (this.pmbFinance !== null) {
            this.tagihSumbangan = this.pmbFinance.pembayaran?.filter((f) => {
              return f.id_jenis_biaya === 4;
            });
            this.tagihUk1 = this.pmbFinance.pembayaran?.filter((f) => {
              return f.id_jenis_biaya === 2;
            });
            this.tagihUk2 = this.pmbFinance.pembayaran?.filter((f) => {
              return f.id_jenis_biaya === 3;
            });
            let data_pembiayaan = {
              tagihSumbangan: this.tagihSumbangan,
              tagihUk1: this.tagihUk1,
              tagihUk2: this.tagihUk2,
            };
            this.$emit("dataTagihan", data_pembiayaan, this.modeCicilan);
          }
          this.$emit("loadingBlock", this.loading);
        });
    },

    simpanPembiayaan() {
      this.loading = true;
      this.$emit("loadingBlock", this.loading);
      this.statusSimpanPembiayaan.msg = null;
      this.statusSimpanPembiayaan.status = null;
      HTTPKEU.post("biayapmb", {
        id_daftar: this.mhs.id_daftar,
        id_ujian: this.mhs.id_ujian,
        id_smt: this.mhs.smt,
        no_ujian: this.mhs.no_ujian,
        cicilan: this.modeCicilan,
        potongan: this.potongan,
        potongan_uk: this.potongan_uk,
        catatan_bak: this.catatan_bak,
        data_pembiayaan: [
          ...this.tagihSumbangan,
          ...this.tagihUk1,
          ...this.tagihUk2,
        ],
      })
        .then((res) => {
          if (res.status === 200) {
            this.pmbFinance = res?.data;
            this.statusSimpanPembiayaan.msg = res.data?.message;
            this.statusSimpanPembiayaan.status = true;
          } else {
            this.statusSimpanPembiayaan.msg = res.data?.message;
            this.statusSimpanPembiayaan.status = false;
          }
        })
        .catch((err) => {
          this.statusSimpanPembiayaan.msg = err.message;
          this.statusSimpanPembiayaan.status = false;
        })
        .finally(() => {
          this.loading = false;
          this.$emit("loadingBlock", this.loading);
        });
    },
    setDataTagihan(t) {
      let data_pembiayaan = {
        tagihSumbangan: this.tagihSumbangan,
        tagihUk1: this.tagihUk1,
        tagihUk2: this.tagihUk2,
      };
      t.upload_tagih = !t.upload_tagih;
      this.$emit("dataTagihan", data_pembiayaan, this.modeCicilan);
    },
    hapusBiaya(btag, b, i) {
      this.pmbpembiayaanid = btag?.id;
      this.pmbpembiayaanhapus = b;
      this.pmbpembiayaanidx = i;
      cash("#delete-modal").modal("show");
    },
    konfirmHapusBiaya() {
      this.statusHapus.status = null;
      this.statusHapus.msg = null;

      if (this.pmbpembiayaanid !== undefined) {
        HTTPKEU.post("hapus_pmb_pembayaran", {
          _method: "DELETE",
          id: this.pmbpembiayaanid,
        })
          .then((res) => {
            if (res.status === 200) {
              this.statusHapus.status = true;
              this.statusHapus.msg = res.data.message;
            } else {
              this.statusHapus.status = false;
              this.statusHapus.msg = res.data.message;
            }
          })
          .catch((e) => {
            this.statusHapus.status = false;
            this.statusHapus.msg = e.response?.data?.message;
          })
          .finally(() => {
            this.ambilBiaya();
          });
      } else {
        //this.ambilBiaya();
      }
      if (this.pmbpembiayaanhapus === "Sumbangan") {
        this.tagihSumbangan.splice(this.pmbpembiayaanidx - 1, 1);
      }

      if (this.pmbpembiayaanhapus === "UK I") {
        this.tagihUk1.splice(this.pmbpembiayaanidx - 1, 1);
      }
      if (this.pmbpembiayaanhapus === "UK II") {
        this.tagihUk2.splice(this.pmbpembiayaanidx - 1, 1);
      }

      cash("#delete-modal").modal("hide");
      this.pmbpembiayaanid = null;
      this.pmbpembiayaanidx = null;
      this.pmbpembiayaanhapus = null;
    },
    setLoadingBlock(loading) {
      this.$emit("loadingBlock", loading);
    },
    setDataTagihanCicilan(data_pembiayaan) {
      this.$emit("dataTagihan", data_pembiayaan, this.modeCicilan);
    },
  },
};
</script>

<style scoped>
.table th {
  padding: 0.5rem 1rem;
}
.table td {
  padding: 0.5rem 1rem;
}
.columns-1 .form-label {
  margin-bottom: 0.1rem;
}
</style>
