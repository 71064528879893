<template>
  <div class="bg-yellow-50">
    <button class="btn btn-sm btn-default ml-2 mt-2" @click="ambilBiaya">
      <RefreshCcwIcon class="w-3 mr-1" /> Refresh
    </button>
    <div class="grid grid-cols-12 gap-2 mb-2 p-2">
      <div class="col-span-6">
        <label class="form-label">Skema Cicilan Sumbangan</label>
        <TailSelect
          v-model="skemaCicilanSumbangan"
          :options="{
            search: true,
            classNames: 'w-full',
            deselect: true,
          }"
        >
          <option>Pilih Skema Cicilan</option>
          <option
            v-for="list in masterSkema.sumbangan"
            :key="list.group_skema"
            :value="list.group_skema"
          >
            {{ list.group_pembiayaan }} - ({{ list.group_skema }}X) -
            {{ list.prodi?.nama }} {{ $h.IDR(list.group_totalnominal) }}
          </option>
        </TailSelect>
      </div>
      <div class="col-span-6">
        <label class="form-label">Skema Cicilan UK</label>
        <TailSelect
          v-model="skemaCicilanUk"
          :options="{
            search: true,
            classNames: 'w-full',
            deselect: true,
          }"
        >
          <option>Pilih Skema UK</option>
          <option v-for="(list, k) in masterSkema.uk" :key="k" :value="k">
            {{ list.group_pembiayaan }} - ({{ list.group_skema }}X) -
            {{ list.prodi?.nama }} {{ $h.IDR(list.group_totalnominal) }}
          </option>
        </TailSelect>
      </div>
    </div>
    <table class="table">
      <tr>
        <th class="border-b">Rencana Pembayaran Cicilan</th>
        <th class="border-b">Cicilan Ke</th>
        <th class="border-b">Telah Bayar</th>
        <th class="border-b">Tanggal Tagihan</th>
        <th class="border-b">Upload</th>
      </tr>
      <tr>
        <th>
          <div class="flex flex-row">
            <div class="flex-1 pt-2">Cicilan Sumbangan</div>
            <div class="flex-initial">
              <button
                class="btn btn-sm text-center btn-warning py-1 px-2"
                @click="addTagihSumbangan"
              >
                <PlusIcon class="w-4" />
              </button>
            </div>
          </div>
        </th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
      <tr v-for="(sumb, i) in tagihSumbangan" :key="i">
        <td>
          <div class="input-group">
            <div
              v-if="Boolean(sumb.upload_tagih) === false"
              :id="'btn-hapus-s-' + i"
              class="input-group-text"
              @click="hapusBiaya(sumb, 'Sumbangan', i)"
            >
              <MinusSquareIcon class="cursor-pointer w-4 h-4" />
            </div>
            <input
              v-model="sumb.nominal"
              type="number"
              class="form-control"
              placeholder="Nominal Tagihan"
              required
              :aria-describedby="'btn-hapus-s-' + i"
            />
          </div>
        </td>
        <td>
          {{ ++i }}
          <!--          <input
            v-model="sumb.tgl_jatuh_tempo"
            type="date"
            class="form-control"
          />-->
        </td>
        <td>{{ $h.IDR(sumb.nominal_bayar) }}</td>
        <td>
          <input v-model="sumb.tgl_tagihan" type="date" class="form-control" />
        </td>
        <td class="text-center">
          <input
            :disabled="sumb.tgl_bayar?.length > 0"
            type="checkbox"
            value="1"
            :checked="parseInt(sumb.upload_tagih) === 1"
            class="form-check-switch"
            @change="setDataTagihan(sumb)"
          />
        </td>
      </tr>

      <tr>
        <th>
          <div class="flex flex-row">
            <div class="flex-1 pt-2">Cicilan UK 1 + UK 2</div>
            <div class="flex-initial">
              <button
                class="btn btn-sm text-center btn-warning py-1 px-2"
                @click="addTagihUk"
              >
                <PlusIcon class="w-4" />
              </button>
            </div>
          </div>
        </th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
      <tr v-for="(uk1, i) in tagihUk" :key="i">
        <td>
          <div class="input-group">
            <div
              v-if="Boolean(uk1.upload_tagih) === false"
              :id="'btn-hapus-uk1-' + i"
              class="input-group-text"
              @click="hapusBiaya(uk1, 'UK I + UK II', i)"
            >
              <MinusSquareIcon class="cursor-pointer w-4 h-4" />
            </div>
            <input
              v-model="uk1.nominal"
              type="number"
              class="form-control"
              placeholder="Nominal Tagihan"
              required
              :aria-describedby="'btn-hapus-uk1-' + i"
            />
          </div>
        </td>
        <td>
          {{ ++i }}
          <!--          <input
            v-model="uk1.tgl_jatuh_tempo"
            type="date"
            class="form-control"
          />-->
        </td>
        <td>{{ $h.IDR(uk1.nominal_bayar) }}</td>
        <td>
          <input v-model="uk1.tgl_tagihan" type="date" class="form-control" />
        </td>
        <td class="text-center">
          <input
            :disabled="uk1.tgl_bayar?.length > 0"
            type="checkbox"
            class="form-check-switch"
            value="1"
            :checked="parseInt(uk1.upload_tagih) === 1"
            @change="setDataTagihan(uk1)"
          />
        </td>
      </tr>

      <!--
      <tr>
        <th>
          <div class="flex flex-row">
            <div class="flex-1 pt-2">Biaya UK 2</div>
            <div class="flex-initial">
              <button
                class="btn btn-sm text-center btn-warning py-1 px-2"
                @click="addTagihUk2"
              >
                <PlusIcon class="w-4" />
              </button>
            </div>
          </div>
        </th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
      <tr v-for="(uk2, i) in tagihUk2" :key="i">
        <td>
          <div class="input-group">
            <div
              v-if="Boolean(uk2.upload_tagih) === false"
              :id="'btn-hapus-uk2-' + i"
              class="input-group-text"
              @click="hapusBiaya(uk2, 'UK II', i)"
            >
              <MinusSquareIcon class="cursor-pointer w-4 h-4" />
            </div>
            <input
              v-model="uk2.nominal"
              type="number"
              class="form-control"
              placeholder="Nominal Tagihan"
              required
              :aria-describedby="'btn-hapus-uk2-' + i"
            />
          </div>
        </td>
        <td>
          <input
            v-model="uk2.tgl_jatuh_tempo"
            type="date"
            class="form-control"
          />
        </td>
        <td>{{ $h.IDR(uk2.nominal_bayar) }}</td>
        <td>
          <input v-model="uk2.tgl_tagihan" type="date" class="form-control" />
        </td>
        <td class="text-center">
          <input
            :disabled="uk2.tgl_bayar?.length > 0"
            type="checkbox"
            class="form-check-switch"
            value="1"
            :checked="parseInt(uk2.upload_tagih) === 1"
            @change="setDataTagihan(uk2)"
          />
        </td>
      </tr>-->

      <tr class="border-t border-green-700 align-top">
        <td>
          <label
            class="form-label"
            title="Total Biaya Kuliah yang akan ditagihkan ke calon Mahasiswa"
            >Total Rencana Pembayaran</label
          >
          <div>{{ $h.IDR(totalHarusDibayar) }}</div>
        </td>
        <td>
          <label class="form-label">Catatan BKP</label>
          <div>
            {{ pmbFinance?.catatan_bkp ? pmbFinance?.catatan_bkp : "-" }}
          </div>
        </td>
        <td>
          <label class="form-label">Catatan BAK</label>
          <input
            v-model="catatan_bak"
            type="text"
            class="form-control"
            placeholder="Catatan BAK"
            required
          />
        </td>
        <td>
          <button class="btn btn-success" @click="simpanPembiayaan">
            <SaveIcon class="w-4 mr-2"></SaveIcon> Simpan Cicilan
          </button>
        </td>
      </tr>
      <tr class="align-top">
        <td>
          <label class="form-label" title="Selisih Biaya Kuliah">Selisih</label>
          <div>{{ $h.IDR(totalBiayaKuliah - totalHarusDibayar) }}</div>
        </td>
        <td>
          <label
            class="form-label"
            title="Total telah diupload baik yg telah dibayar atau belum"
            >Total Tagih</label
          >
          <div>{{ $h.IDR(totalUpload) }}</div>
        </td>
        <td>
          <label class="form-label" title="Total Biaya Kuliah Dibayar"
            >Total Telah Dibayar</label
          >
          <div>{{ $h.IDR(totalTelahDibayar) }}</div>
        </td>
        <td>
          <label
            class="form-label"
            title="Total akan diupload saat penagihan ini"
            >Total Akan Upload</label
          >
          <div>{{ $h.IDR(totalHarusDiupload) }}</div>
        </td>
      </tr>
    </table>

    <Alert
      v-if="statusHapus.status != null"
      :type="statusHapus.status ? 'alert-success' : 'alert-danger'"
      :dismissable="true"
      >{{ statusHapus.msg }}</Alert
    >

    <Alert
      v-if="statusGetTagihan.status != null"
      :type="statusGetTagihan.status ? 'alert-success' : 'alert-danger'"
      :dismissable="true"
      >{{ statusGetTagihan.msg }}</Alert
    >

    <Alert
      v-if="statusSimpanPembiayaan.status != null"
      :type="statusSimpanPembiayaan.status ? 'alert-success' : 'alert-danger'"
      :dismissable="true"
      >{{ statusSimpanPembiayaan.msg }}</Alert
    >

    <ModalDialog
      :id="'dialog-delete-tc'"
      :question="
        'Anda yakin ingin menghapus Pembiayaan Biaya ' +
        pmbpembiayaanhapus +
        '?'
      "
    >
      <template #delete-button>
        <button
          type="button"
          class="btn btn-danger w-24"
          @click="konfirmHapusBiayaCic"
        >
          Ya
        </button>
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import { HTTPKEU } from "../../../services/http";
import ModalDialog from "../../../components/utility/ModalDialog";
import moment from "moment";
import Alert from "../../../components/alerts/Alert";
export default {
  name: "CreateTagihanCicilan",
  components: {
    ModalDialog,
    Alert,
  },
  props: {
    model: {
      type: Object,
      default: null,
    },
    mhs: {
      type: Object,
      default: null,
    },
    jenisBiayaList: {
      type: Array,
      default: null,
    },
    idUjian: {
      type: String,
      default: null,
    },
    angkatan: {
      type: Number,
      default: null,
    },
    potonganPmb: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      modeCicilan: false,
      biayaKuliah: null,
      id_biaya_sumbangan: 0,
      id_jenis_biaya: ["4", "2", "3"],
      tagihSumbangan: [],
      tagihUk1: [],
      tagihUk2: [],
      tagihUk: [],
      masterBiaya: { sumbangan: null, uk1: null, uk2: null, uk: null },
      masterSkema: { sumbangan: [], uk: [] },
      statusGetTagihan: {
        msg: null,
        status: null,
      },
      statusSimpanPembiayaan: {
        msg: null,
        status: null,
      },
      statusHapus: {
        msg: null,
        status: null,
      },
      pmbFinance: null,
      loading: false,
      catatan_bak: null,
      pmbpembiayaanid: null,
      pmbpembiayaanidx: null,
      pmbpembiayaanhapus: null,
      skemaCicilanSumbangan: null,
      skemaCicilanUk: null,
      flagAdaSumbangan: false,
      flagAdaUk: false,
    };
  },
  computed: {
    totalBiaya() {
      return (
        parseFloat(this.masterBiaya.sumbangan?.biaya) +
        parseFloat(this.masterBiaya.uk?.biaya)
      );
    },
    totalBiayaKuliah() {
      let total =
        parseFloat(this.masterBiaya.sumbangan?.biaya) +
        parseFloat(this.masterBiaya.uk?.biaya);
      if (this.potongan > 0) {
        total -= parseFloat(this.potongan);
      }

      console.log("counting harus bayar", total);
      return total;
    },
    totalHarusDibayar() {
      let total = 0;
      if (this.tagihSumbangan) {
        for (let s of this.tagihSumbangan) {
          total += parseFloat(s.nominal);
        }
      }
      if (this.tagihUk) {
        for (let s of this.tagihUk) {
          total += parseFloat(s.nominal);
        }
      }
      /*if (this.tagihUk) {
        for (let s of this.tagihUk) {
          total += parseFloat(s.nominal);
        }
      }*/

      return total;
    },
    totalTelahDibayar() {
      let total = 0;
      if (this.tagihSumbangan) {
        for (let s of this.tagihSumbangan.filter((t) => {
          return t.tgl_bayar?.length > 0;
        })) {
          total += parseFloat(s.nominal);
        }
      }
      if (this.tagihUk) {
        for (let s of this.tagihUk.filter((t) => {
          return t.tgl_bayar?.length > 0;
        })) {
          total += parseFloat(s.nominal);
        }
      }
      /*if (this.tagihUk2) {
        for (let s of this.tagihUk2.filter((t) => {
          return t.tgl_bayar?.length > 0;
        })) {
          total += parseFloat(s.nominal);
        }
      }*/

      console.log("counting telah bayar", total);
      return total;
    },
    totalUpload() {
      let total = 0;
      if (this.tagihSumbangan) {
        for (let s of this.tagihSumbangan.filter((t) => {
          return t.upload_tagih === true || t.upload_tagih == 1;
        })) {
          total += parseFloat(s.nominal);
        }
      }
      if (this.tagihUk) {
        for (let s of this.tagihUk.filter((t) => {
          return t.upload_tagih === true || t.upload_tagih == 1;
        })) {
          total += parseFloat(s.nominal);
        }
      }
      /*if (this.tagihUk2) {
        for (let s of this.tagihUk2.filter((t) => {
          return t.upload_tagih === true || t.upload_tagih == 1;
        })) {
          total += parseFloat(s.nominal);
        }
      }*/

      return total;
    },
    totalHarusDiupload() {
      let total = 0;
      if (this.tagihSumbangan) {
        for (let s of this.tagihSumbangan.filter((t) => {
          return Boolean(t.upload_tagih) === true && t.nominal_bayar == null;
        })) {
          total += parseFloat(s.nominal);
        }
      }
      if (this.tagihUk) {
        for (let s of this.tagihUk.filter((t) => {
          return Boolean(t.upload_tagih) === true && t.nominal_bayar == null;
        })) {
          total += parseFloat(s.nominal);
        }
      }
      /*if (this.tagihUk2) {
        for (let s of this.tagihUk2.filter((t) => {
          return Boolean(t.upload_tagih) === true && t.nominal_bayar == null;
        })) {
          total += parseFloat(s.nominal);
        }
      }*/

      return total;
    },
  },
  watch: {
    idUjian: {
      handler: function (h) {
        this.ambilBiaya();
        this.ambilSkemaCicilanMaba();
      },
      deep: true,
    },
    tagihSumbangan: {
      handler: function () {},
      deep: true,
    },
    tagihUk1: {
      handler: function () {},
      deep: true,
    },
    tagihUk2: {
      handler: function () {},
      deep: true,
    },
    tagihUk: {
      handler: function () {},
      deep: true,
    },
    potongan: {
      handler: function () {},
      deep: true,
    },
    potonganPmb: {
      handler: function () {
        this.potongan = this.potonganPmb;
      },
      immediate: true,
    },
    skemaCicilanSumbangan: {
      handler: function (h) {
        if (
          h !== null &&
          Number(h) >= 0 &&
          Array.isArray(this.masterSkema.sumbangan)
        ) {
          let sumb = this.masterSkema?.sumbangan[h];
          if (this.flagAdaSumbangan === false) {
            this.tagihSumbangan = [];
            if (sumb?.skema.length > 0) {
              for (let i of sumb.skema) {
                this.tagihSumbangan.push({
                  nominal: parseFloat(i.nominal),
                  cicilan: "Y",
                  tgl_jatuh_tempo: moment(new Date()).format("YYYY-MM-DD"),
                  nominal_bayar: null,
                  status_bayar: null,
                  tgl_tagihan: moment(new Date())
                    .add(i.cicilan_ke, "month")
                    .format("YYYY-MM-DD"),
                  upload_tagih: false,
                  id_jenis_biaya: 4,
                  cicilan_ke: i.cicilan_ke,
                });
              }
            }
          }
        }
      },
      deep: true,
    },
    skemaCicilanUk: {
      handler: function (h) {
        if (
          h !== null &&
          Number(h) >= 0 &&
          Array.isArray(this.masterSkema.uk)
        ) {
          let uk = this.masterSkema?.uk[h];
          if (this.flagAdaUk === false) {
            this.tagihUk = [];
            if (uk?.skema.length > 0) {
              for (let i of uk.skema) {
                this.tagihUk.push({
                  nominal: parseFloat(i.nominal),
                  cicilan: "Y",
                  tgl_jatuh_tempo: moment(new Date()).format("YYYY-MM-DD"),
                  nominal_bayar: null,
                  status_bayar: null,
                  tgl_tagihan: moment(new Date())
                    .add(i.cicilan_ke, "month")
                    .format("YYYY-MM-DD"),
                  upload_tagih: false,
                  id_jenis_biaya: 5,
                  cicilan_ke: i.cicilan_ke,
                });
              }
            }
          }
        }
      },
      deep: true,
    },
  },
  beforeMount() {
    this.addTagihSumbangan();
    this.addTagihUk();
  },
  mounted() {
    this.ambilBiaya();
    this.ambilSkemaCicilanMaba();
  },
  methods: {
    addTagihSumbangan() {
      this.tagihSumbangan.push({
        nominal: 0,
        cicilan: "Y",
        tgl_jatuh_tempo: moment(new Date()).format("YYYY-MM-DD"),
        nominal_bayar: null,
        status_bayar: null,
        tgl_tagihan: moment(new Date())
          .add(this.tagihUk?.length + 1, "month")
          .format("YYYY-MM-DD"),
        upload_tagih: false,
        id_jenis_biaya: 4,
        cicilan_ke:
          this.tagihSumbangan?.length > 0 ? this.tagihSumbangan?.length + 1 : 1,
      });
    },
    //Tagihan UK 1 + UK 2
    addTagihUk() {
      this.tagihUk.push({
        nominal: 0,
        cicilan: "Y",
        tgl_jatuh_tempo: moment(new Date()).format("YYYY-MM-DD"),
        nominal_bayar: null,
        status_bayar: null,
        tgl_tagihan: moment(new Date())
          .add(this.tagihUk?.length + 1, "month")
          .format("YYYY-MM-DD"),
        upload_tagih: false,
        id_jenis_biaya: 5,
        cicilan_ke: this.tagihUk?.length > 0 ? this.tagihUk?.length + 1 : 1,
      });
    },
    addTagihUk1() {
      this.tagihUk1.push({
        nominal: 0,
        tgl_jatuh_tempo: moment(new Date()).format("YYYY-MM-DD"),
        nominal_bayar: null,
        status_bayar: null,
        tgl_tagihan: moment(new Date()).format("YYYY-MM-DD"),
        upload_tagih: false,
        id_jenis_biaya: 2,
        cicilan_ke: this.tagihUk1?.length > 0 ? this.tagihUk1?.length + 1 : 1,
      });
    },
    addTagihUk2() {
      this.tagihUk2.push({
        nominal: 0,
        tgl_jatuh_tempo: moment(new Date()).format("YYYY-MM-DD"),
        nominal_bayar: null,
        status_bayar: null,
        tgl_tagihan: moment(new Date()).format("YYYY-MM-DD"),
        upload_tagih: false,
        id_jenis_biaya: 3,
        cicilan_ke: this.tagihUk2?.length > 0 ? this.tagihUk2?.length + 1 : 1,
      });
    },
    ambilBiaya() {
      let that = this;

      //if (that.modeCicilan) {
      this.tagihSumbangan = [];
      this.tagihUk = [];
      //}
      Promise.all([
        this.getBiayaDetail(4, this.mhs.gelombang),
        this.getBiayaDetail(5, this.mhs.waktu),
      ])
        .then(function (result) {
          that.masterBiaya.sumbangan = result[0].data;
          that.masterBiaya.uk = result[1].data;
        })
        .finally(() => {
          that.cekTagihanMaba();
        });
    },

    ambilSkemaCicilanMaba() {
      let that = this;

      //if (that.modeCicilan) {
      this.skemaCicilanSumbangan = [];
      this.skemaCicilanUk = [];
      this.flagAdaSumbangan = false;
      this.flagAdaUk = false;
      //}
      Promise.all([
        this.getCicilanNominalGroup("Sumbangan"),
        this.getCicilanNominalGroup("UK1+UK2"),
      ]).then(function (result) {
        that.masterSkema.sumbangan = result[0].data;
        that.masterSkema.uk = result[1].data;
      });
    },
    getBiayaDetail(id_jenis_biaya, sub_jenis) {
      return HTTPKEU.post("master/getbiayakuliahdetail", {
        tahun: this.angkatan,
        id_jenis_biaya: id_jenis_biaya,
        id_sms: this.mhs?.prodiFix?.id_sms,
        sub_jenis: sub_jenis,
      });
    },

    getCicilanNominalGroup(group) {
      return HTTPKEU.get("list/cicilanmabagroup", {
        params: {
          id_sms: this.mhs?.prodiFix?.id_sms,
          id_smt: this.mhs.smt,
          group: group,
        },
      });
    },
    cekTagihanMaba() {
      this.loading = true;
      this.$emit("loadingBlock", this.loading);
      this.statusGetTagihan.msg = null;
      this.statusGetTagihan.status = null;
      HTTPKEU.post("tagihan/get_tagihan_pmb", {
        id_daftar: this.mhs.id_daftar,
      })
        .then((res) => {
          if (res.status === 200) {
            this.pmbFinance = res?.data;
            this.catatan_bak = this.pmbFinance.catatan_bak;
            this.catatan_bkp = this.pmbFinance.catatan_bkp;
            this.potongan = this.pmbFinance.potongan;
          } else {
            this.statusGetTagihan.msg = res.data?.message;
            this.statusGetTagihan.status = false;
            this.pmbFinance = null;
          }
        })
        .catch((err) => {
          this.statusGetTagihan.msg = err.response?.data?.message;
          this.statusGetTagihan.status = false;
          this.pmbFinance = null;
        })
        .finally(() => {
          this.loading = false;
          if (this.pmbFinance !== null) {
            this.tagihSumbangan = this.pmbFinance.pembayaran?.filter((f) => {
              return f.id_jenis_biaya === 4;
            });
            this.tagihUk = this.pmbFinance.pembayaran?.filter((f) => {
              return f.id_jenis_biaya === 5;
            });

            if (this.tagihSumbangan.length > 0) {
              this.flagAdaSumbangan = true;
            }

            if (this.tagihUk.length > 0) {
              this.flagAdaUk = true;
            }

            let data_pembiayaan = {
              tagihSumbangan: this.tagihSumbangan,
              tagihUk: this.tagihUk,
            };
            this.$emit("dataTagihan", data_pembiayaan, true);
          }
          this.$emit("loadingBlock", this.loading);
        });
    },

    simpanPembiayaan() {
      this.loading = true;
      this.$emit("loadingBlock", this.loading);
      this.statusSimpanPembiayaan.msg = null;
      this.statusSimpanPembiayaan.status = null;
      HTTPKEU.post("biayapmb", {
        id_daftar: this.mhs.id_daftar,
        id_ujian: this.mhs.id_ujian,
        id_smt: this.mhs.smt,
        no_ujian: this.mhs.no_ujian,
        cicilan: true,
        potongan: this.potongan,
        catatan_bak: this.catatan_bak,
        data_pembiayaan: [...this.tagihSumbangan, ...this.tagihUk],
      })
        .then((res) => {
          if (res.status === 200) {
            this.pmbFinance = res?.data;
            this.statusSimpanPembiayaan.msg = res.data?.message;
            this.statusSimpanPembiayaan.status = true;
          } else {
            this.statusSimpanPembiayaan.msg = res.data?.message;
            this.statusSimpanPembiayaan.status = false;
          }
        })
        .catch((err) => {
          this.statusSimpanPembiayaan.msg = err.message;
          this.statusSimpanPembiayaan.status = false;
        })
        .finally(() => {
          this.loading = false;
          this.$emit("loadingBlock", this.loading);
        });
    },
    setDataTagihan(t) {
      let data_pembiayaan = {
        tagihSumbangan: this.tagihSumbangan,
        tagihUk: this.tagihUk,
      };
      t.upload_tagih = !t.upload_tagih;
      this.$emit("dataTagihan", data_pembiayaan, true);
    },
    hapusBiaya(btag, b, i) {
      this.pmbpembiayaanid = btag?.id;
      this.pmbpembiayaanhapus = b;
      this.pmbpembiayaanidx = i;
      console.log(i);
      cash("#dialog-delete-tc").modal("show");
    },
    konfirmHapusBiayaCic() {
      this.statusHapus.status = null;
      this.statusHapus.msg = null;
      if (this.pmbpembiayaanid !== undefined) {
        HTTPKEU.post("hapus_pmb_pembayaran", {
          _method: "DELETE",
          id: this.pmbpembiayaanid,
        })
          .then((res) => {
            if (res.status === 200) {
              this.statusHapus.status = true;
              this.statusHapus.msg = res.data.message;
            } else {
              this.statusHapus.status = false;
              this.statusHapus.msg = res.data.message;
            }
          })
          .catch((e) => {
            this.statusHapus.status = false;
            this.statusHapus.msg = e.response?.data?.message;
          })
          .finally(() => {
            this.ambilBiaya();
          });
      } else {
        //this.ambilBiaya();
      }
      if (this.pmbpembiayaanhapus === "Sumbangan") {
        this.tagihSumbangan.splice(this.pmbpembiayaanidx - 1, 1);
      }

      if (this.pmbpembiayaanhapus === "UK I + UK II") {
        this.tagihUk.splice(this.pmbpembiayaanidx - 1, 1);
      }

      cash("#dialog-delete-tc").modal("hide");
      this.pmbpembiayaanid = null;
      this.pmbpembiayaanidx = null;
      this.pmbpembiayaanhapus = null;
    },
  },
};
</script>

<style scoped>
.table th {
  padding: 0.5rem 1rem;
}
.table td {
  padding: 0.5rem 1rem;
}
.columns-1 .form-label {
  margin-bottom: 0.1rem;
}
</style>
