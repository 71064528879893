<template>
  <div class="relative -mt-4">
    <div class="intro-y flex flex-col sm:flex-row items-center border-t">
      <h2 class="text-lg font-medium mr-auto pt-2">
        Tambah Mahasiswa yang Akan Mencicil
      </h2>
    </div>

    <div class="intro-y box mt-5">
      <div class="grid sm:grid-cols-4 grid-cols-1 gap-2">
        <div class="mb-2">
          <label class="form-label">NPM</label>
          <AutoComplete
            v-model="selectedMahasiswa"
            :suggestions="filteredMahasiswa"
            field="label"
            force-selection
            placeholder="Ketikkan Nama atau NPM Mahasiswa"
            class="w-full remake"
            @focus="loadDataMahasiswa"
            @complete="searchMahasiswa($event)"
          >
            <template #item="slotProps">
              <div class="item">
                <div>
                  <strong> {{ slotProps.item?.nama }}</strong>
                  {{ slotProps.item?.npm }}
                </div>
              </div>
            </template>
          </AutoComplete>
        </div>

        <div class="mb-2">
          <label class="form-label">Tahun Akademik</label>
          <select v-model="smt" class="form-select">
            <option
              v-for="smtr in semester"
              :key="smtr.id"
              :value="smtr.value"
              :selected="smtr.selected"
            >
              {{ smtr.text }}
            </option>
          </select>
          <span
            v-if="
              messageValidator?.thnakademik &&
              messageValidator?.thnakademik.length > 0
            "
            class="text-red-600"
            >{{ messageValidator?.thnakademik[0] }}</span
          >
        </div>

        <div class="mb-2">
          <label class="form-label">Jenis</label>
          <TailSelect
            v-model="jenis"
            :options="{
              search: true,
              classNames: 'w-full',
              deselect: false,
            }"
          >
            <option
              v-for="item in listJenis"
              :key="item.id"
              :value="item.value"
              :selected="item.selected"
            >
              {{ item.text }}
            </option>
          </TailSelect>
          <span
            v-if="messageValidator?.jenis && messageValidator?.jenis.length > 0"
            class="text-red-600"
            >{{ messageValidator?.jenis[0] }}</span
          >
        </div>

        <div class="mb-2">
          <label class="form-label">Skema Cicilan</label>
          <select v-model="jumlahcicilan" class="form-select">
            <option v-for="n in 6" :key="n" :value="n">{{ n }} x</option>
          </select>
        </div>
      </div>

      <div class="my-3">
        <button
          :disabled="
            selectedMahasiswa?.npm === '' ||
            selectedMahasiswa?.npm === undefined
          "
          class="btn btn-primary w-full"
          @click="simpanMhsCicilan"
        >
          SIMPAN
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { HTTPKEU } from "../../../services/http";
import { mahasiswa, master, keuangan } from "@/services/models";
import router from "@/router";
import { userdata } from "@/utils/userdata";

export default defineComponent({
  data() {
    return {
      listMahasiswa: null,
      filteredMahasiswa: null,
      selectedMahasiswa: [],
      semester: [],
      smt: "",
      listJenis: [],
      jenis: "",
      listAkun: null,
      filteredAkun: null,
      selectedAkun: [],
      keterangan: "",
      nominal: "",
      messageValidator: [],
      rupiahNominal: "",
      skemaCicilanUk: null,
      masterSkema: { sumbangan: [], uk: [] },
      jumlahcicilan: 6,
    };
  },
  watch: {
    selectedMahasiswa: {
      handler: function (val) {
        console.log(val);
        if (this.smt.length > 0) {
          this.ambilSkemaCicilanMhs();
        }
        //this.ambilSkemaCicilanMhs();
      },
      deep: true,
    },
    smt: {
      handler: function (val) {
        if (this.selectedMahasiswa) {
          this.ambilSkemaCicilanMhs();
        }
        //this.ambilSkemaCicilanMhs();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchSemester();
    this.jenislist();
  },
  methods: {
    formatRupiah(nominal) {
      if (nominal) {
        let rupiahLocal = Intl.NumberFormat("id-ID");
        this.rupiahNominal = "Rp." + rupiahLocal.format(nominal);
      } else {
        this.rupiahNominal = "";
      }
    },
    searchMahasiswa(event) {
      setTimeout(() => {
        if (!event.query.length) {
          this.filteredMahasiswa = [...this.listMahasiswa];
        } else {
          mahasiswa.RegistasiPD.searchData({
            params: {
              q: event.query,
            },
          }).then((result) => {
            this.listMahasiswa = result.data.data.map((mhs) => {
              return {
                id_reg_pd: mhs.id_reg_pd,
                id_pd: mhs.peserta_didik?.id_pd,
                nama: mhs.peserta_didik?.nm_pd,
                npm: mhs.nipd,
                mhs: mhs,
                label: mhs.peserta_didik?.nm_pd + " (" + mhs.nipd + ")",
              };
            });
            this.filteredMahasiswa = [...this.listMahasiswa];
          });
        }
      }, 100);
    },
    loadDataMahasiswa() {
      if (!userdata.isAuthenticated())
        mahasiswa.RegistasiPD.searchData({}).then((result) => {
          this.listMahasiswa = result?.data?.data?.map((mhs) => {
            return {
              id_reg_pd: mhs.id_reg_pd,
              id_pd: mhs.peserta_didik?.id_pd,
              nama: mhs.peserta_didik?.nm_pd,
              npm: mhs.nipd,
              mhs: mhs,
              label: mhs.peserta_didik?.nm_pd + " (" + mhs.nipd + ")",
            };
          });
        });
    },
    fetchSemester() {
      let that = this;
      this.semester.push({
        id: "",
        value: "",
        text: "Pilih Semester",
        selected: false,
      });
      master.Semester.getAllActive().then((result) => {
        if (result.status === 200) {
          result.data.data.forEach((item) => {
            let newoption = {
              id: item.id_smt,
              value: item.id_smt,
              text: item.nm_smt,
              id_smt: item.id_smt,
              nm_smt: item.nm_smt,
              smt: item.smt,
              id_thn_ajaran: item.id_thn_ajaran,
              thn_ajaran: item.thn_ajaran,
              selected: that.current?.id_smt === item.id_smt,
            };

            that.semester.push(newoption);
          });
        }
      });
    },
    jenislist() {
      HTTPKEU.get("list/jenisbiaya").then((res) => {
        this.listJenis = [];
        let opt = {
          id: 0,
          value: "",
          text: "Pilih Jenis Pembiyaan",
          selected: true,
        };
        this.listJenis.push(opt);

        res.data.forEach((item) => {
          const opt = {
            id: item.id,
            value: item.id,
            text: `${item.keterangan}`,
            selected: false,
          };
          this.listJenis.push(opt);
        });
      });
    },
    searchAkun(event) {
      setTimeout(() => {
        if (!event.query.length) {
          this.filteredAkun = [...this.listAkun];
        } else {
          keuangan.Akun.searchData({
            params: {
              q: event.query,
            },
          }).then((result) => {
            this.listAkun = result.data.data.map((act) => {
              return {
                kd_account: act.kd_account,
                account: act.account,
                label: act.account + " (" + act.kd_account + ")",
              };
            });
            this.filteredAkun = [...this.listAkun];
          });
        }
      }, 100);
    },
    loadDataAkun() {
      if (!userdata.isAuthenticated())
        keuangan.Akun.searchData({}).then((result) => {
          this.listAkun = result?.data?.data?.map((acc) => {
            return {
              kd_account: acc.kd_account,
              accound: acc.account,
              label: acc.account + " (" + acc.kd_account + ")",
            };
          });
        });
    },
    simpanMhsCicilan() {
      let smtr = this.semester.find((item) => item.id_smt === this.smt);
      let val = {
        id_reg_pd: this.selectedMahasiswa.id_reg_pd,
        npm: this.selectedMahasiswa.npm,
        nama: this.selectedMahasiswa.nama,
        TahunAkademik: smtr.thn_ajaran.nm_thn_ajaran,
        Kd_Semester: smtr.smt,
        id_smt: this.smt,
        id_jenis_biaya: this.jenis,
        jumlahcicilan: this.jumlahcicilan,
        id_sms: this.selectedMahasiswa.mhs?.id_sms,
      };

      console.log(val);
      keuangan.MahasiswaCicilan.insertData(val).then((res) => {
        if (res.data.code === 200) {
          this.smt = "";
          this.jenis = "";
          this.selectedMahasiswa = [];
          router.push({
            name: "mahasiswa-cicilan",
          });
        } else {
          this.messageValidator = res.data.message;
        }
      });
    },
    ambilSkemaCicilanMhs() {
      let that = this;
      this.skemaCicilanUk = [];
      Promise.all([this.getCicilanNominalGroup("UK1+UK2")]).then(function (
        result
      ) {
        that.masterSkema.uk = result[0].data;
      });
    },
    getCicilanNominalGroup(group) {
      return HTTPKEU.get("list/cicilanmabagroup", {
        params: {
          id_sms: this.selectedMahasiswa.mhs?.id_sms,
          id_smt: this.selectedMahasiswa.mhs?.mulai_smt,
          group: group,
        },
      });
    },
  },
});
</script>

<style scope>
.p-autocomplete > input {
  width: 100% !important;
}
.remake {
  padding: 0 !important;
}
</style>
