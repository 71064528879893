<template>
  <div>
    <table class="table">
      <thead>
        <tr class="text-center">
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-5"
          >
            No.
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-10"
          >
            VA
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            SUBCOMP
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            PRODUK
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            KODE AKUN
          </th>

          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            Status
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(data, i) in datafromexcelprops" :key="i">
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-normal text-xs"
          >
            {{ i + 1 }})
          </td>

          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-normal text-xs w-40"
          >
            {{ data?.no_va }}
            <span v-if="data?.nama">
              <b
                ><i>{{ data?.nama }}</i></b
              >
            </span>
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-normal text-xs"
          >
            {{ data?.sub_comp }}
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-normal text-xs"
          >
            {{ data?.produk }}
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-normal text-xs"
          >
            {{ data?.account_code }}
          </td>

          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-normal text-xs"
          >
            <div v-if="statusimportvalidasi[i]">
              Validasi: {{ statusimportvalidasi[i]?.message }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "TableImportData",
  props: {
    datafromexcelprops: {
      type: Array,
      default: null,
    },
    statusImport: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      datafromexcel: [],
      statusimportvalidasi: [],
    };
  },
  watch: {
    statusImport: {
      handler: function (h) {
        this.statusimportvalidasi = h;
      },
      immediate: true,
    },
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
  },
};
</script>

<style scoped></style>
