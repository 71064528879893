<template>
  <div class="sm:w-auto mb-3">
    <label v-if="showLabel" class="form-label">{{ showLabel }}</label>

    <TailSelect
      v-model="filter"
      :options="{
        search: true,
        classNames: 'w-full',
        deselect: true,
      }"
    >
      <option
        v-for="list in listOptions"
        :key="list.value"
        :value="list.value"
        :selected="list.selected"
      >
        {{ list.text }}
      </option>
    </TailSelect>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "TailSelectSelector",
  props: {
    listOptions: {
      type: Object,
      default: () => ({}),
    },
    showLabel: {
      type: String,
      default: null,
    },
    model: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      id: null,
      filters: [],
      filter: 0,
      current: null,
    };
  },
  watch: {
    filter: {
      handler: function (newData) {
        if (this.listOptions.length) {
          let curr = this.listOptions?.filter((item) => item.id === newData);
          this.$emit("cbox-select", curr, newData);
        }
      },
      immediate: true,
    },
    model: {
      handler: function (newData) {
        this.filter = newData;
      },
      immediate: true,
    },
    listOptions: {
      handler: function () {},
      immediate: true,
    },
  },
  mounted() {},
  methods: {},
});
</script>
<style scoped></style>
