<template>
  <MainLayout>
    <div class="relative">
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">
          Tagihan dan Transaksi Pembayaran PMB
        </h2>
        <div
          class="w-full sm:w-auto md:flex justify-center items-center align-center mt-4 sm:mt-0"
        ></div>
      </div>

      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 lg:col-span-12">
          <div v-if="loading" class="block-loading">
            <LoadingIcon icon="three-dots" class="w-8 h-8" />
            <div class="text-center text-xs mt-2">
              Loading data... Please Wait...
            </div>
          </div>

          <div class="intro-y box">
            <div class="grid grid-cols-12 mb-3">
              <div class="col-span-12 xl:col-span-8">
                <div class="p-3 flex">
                  <div class="flex-1">
                    <label class="form-label">Tahun Akademik</label>
                    <TailSelectSelector
                      :list-options="listOptionsState?.semester"
                      @cbox-select="selectSemester"
                    ></TailSelectSelector>

                    <div class="grid grid-cols-2 mt-3 gap-3 mb-2">
                      <div class="col-span-1">
                        <label class="form-label">Tahun Ajaran</label>
                        <input
                          class="form-control"
                          :value="smt?.thn_ajaran?.nm_thn_ajaran"
                          readonly
                          disabled
                        />
                      </div>
                      <div class="col-span-1">
                        <label class="form-label">Semester</label>
                        <input
                          class="form-control"
                          :value="smt?.nm_smt"
                          readonly
                          disabled
                        />
                      </div>
                    </div>
                    <Alert
                      v-if="statusValidasi.status != null"
                      :type="
                        statusValidasi.status ? 'alert-success' : 'alert-danger'
                      "
                      :dismissable="true"
                      >{{ statusValidasi.msg }}</Alert
                    >
                  </div>
                  <div class="flex-1 ml-5">
                    <label class="form-label">Program Studi</label>
                    <TailSelectSelector
                      class="col-span-5"
                      :list-options="listOptionsState?.prodi"
                      @cbox-select="selectProdi"
                    ></TailSelectSelector>

                    <div class="grid grid-cols-2 mt-3 gap-3 mb-2">
                      <div class="col-span-1">
                        <label class="form-label">Angkatan</label>
                        <input
                          v-model="angkatan"
                          type="text"
                          readonly
                          class="form-control"
                        />
                      </div>
                      <div class="col-span-1">
                        <label class="form-label"
                          >Jumlah Calon Mhs Tagihan</label
                        >
                        <input
                          type="text"
                          readonly
                          :value="listCama.length"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="p-3 flex">
                  <label class="form-label">Calon Mahasiswa</label>
                  <TailSelect
                    v-model="camaSelected"
                    :options="{
                      search: true,
                      classNames: 'w-full',
                      deselect: true,
                    }"
                  >
                    <option
                      v-for="list in listCama"
                      :key="list.id_ujian"
                      :value="list.id_ujian"
                    >
                      {{ list.nm_pd }} ({{ list.no_ujian }})
                    </option>
                  </TailSelect>
                </div>
              </div>
              <div
                class="col-span-12 xl:col-span-4 bg-gray-300 dark:bg-gray-700 pb-3"
              >
                <div class="p-3">
                  <div class="grid grid-cols-2 gap-2">
                    <div class="col-span-1">
                      <label class="form-label">Sub Comp</label>
                      <select v-model="model.kode_sub_comp" class="form-select">
                        <option value="0">
                          Semua
                        </option>
                        <option
                          v-for="list in subcomps"
                          :key="list.Kode"
                          :value="list.Kode"
                        >
                          {{ list.Keterangan }} ({{ list.Kode }})
                        </option>
                      </select>
                    </div>
                    <div class="col-span-1">
                      <label class="form-label">Produk</label>

                      <select v-model="model.kode_produk" class="form-select">
                        <option value="0">Semua</option>
                        <option
                          v-for="list in produks"
                          :key="list.kode"
                          :value="list.kode"
                        >
                          {{ list.Keterangan }} ({{ list.kode }})
                        </option>
                      </select>
                    </div>
                  </div>

                  <label class="form-label mt-3">Bank</label>
                  <select v-model="model.kode_bank" class="form-select">
                    <option
                      v-for="(item, index) in banks"
                      :key="index"
                      :value="item.kode"
                    >
                      {{ item.Keterangan }}
                    </option>
                  </select>
                </div>

                <button
                  class="btn btn-success w-50 ml-3 mr-5"
                  @click="cariData"
                >
                  Cek Tagihan
                </button>
              </div>
            </div>
          </div>

          <div class="intro-y box p-5 mt-5">
            <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
              <form
                id="tabulator-html-filter-form"
                class="xl:flex sm:mr-auto"
                @submit.prevent="onFilter"
              >
                <div class="sm:flex items-center sm:mr-4">
                  <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                    >Field</label
                  >
                  <select
                    id="tabulator-html-filter-field"
                    v-model="filter.field"
                    class="form-select w-full sm:w-32 xxl:w-full mt-2 sm:mt-0 sm:w-auto"
                  >
                    <option value="va">NO VA</option>
                    <option value="nama">Nama</option>
                  </select>
                </div>

                <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                  <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                    >Value</label
                  >
                  <input
                    id="tabulator-html-filter-value"
                    v-model="filter.value"
                    type="text"
                    class="form-control sm:w-40 xxl:w-full mt-2 sm:mt-0"
                    placeholder="Search..."
                  />
                </div>
                <div class="mt-2 xl:mt-0">
                  <button
                    id="tabulator-html-filter-go"
                    type="button"
                    class="btn btn-primary w-full sm:w-16"
                    @click="onFilter"
                  >
                    Go
                  </button>
                  <button
                    id="tabulator-html-filter-reset"
                    type="button"
                    class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
                    @click="resetFilter"
                  >
                    Reset
                  </button>
                </div>
              </form>
              <div class="flex mt-5 sm:mt-0">
                <button
                  id="tabulator-print"
                  class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"
                  @click="onPrint"
                >
                  <PrinterIcon class="w-4 h-4 mr-2" /> Print
                </button>
                <div class="dropdown w-1/2 sm:w-auto">
                  <button
                    class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
                    aria-expanded="false"
                  >
                    <FileTextIcon class="w-4 h-4 mr-2" /> Export
                    <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                  </button>
                  <div class="dropdown-menu w-40">
                    <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                      <a
                        id="tabulator-export-xlsx"
                        href="javascript:"
                        class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                        @click="onExportXlsx"
                      >
                        <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                      </a>
                      <a
                        id="tabulator-export-html"
                        href="javascript:"
                        class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                        @click="onExportHtml"
                      >
                        <FileTextIcon class="w-4 h-4 mr-2" /> Export HTML
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="overflow-x-auto scrollbar-hidden">
              <div
                id="tabulator"
                ref="tableRef"
                class="mt-5 table-report table-report--tabulator"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <ModalDialogPositive
        :id="'validasi-modal'"
        :question="
          'Anda yakin ingin memvalidasi pembayaran tagihan ' +
          detail.tagihan?.NamaProduk +
          ' ' +
          detail.tagihan?.Nama +
          '?'
        "
      >
        <template #delete-button>
          <button
            type="button"
            class="btn btn-success w-24"
            @click="konfirmValidasi"
          >
            Ya
          </button>
        </template>
      </ModalDialogPositive>
    </div>

    <SlideOver :open="panel.panelOpen" size="max-w-5xl" @close="closePanel">
      <template #title>Detail Tagihan dan Transaksi Pembayaran</template>
      <div class="h-full" aria-hidden="true">
        <Alert
          v-if="statusValidasi.status != null"
          :type="statusValidasi.status ? 'alert-success' : 'alert-danger'"
          :dismissable="true"
          >{{ statusValidasi.msg }}</Alert
        >
        <div
          v-if="confirmValidasi"
          class="p-3 text-center border border-yellow-500 mb-3 font-medium text-lg text-yellow-700"
        >
          Anda yakin ingin memvalidasi pembayaran tagihan
          <b>{{ detail.tagihan?.NamaProduk }}</b> mahasiswa
          <b>{{ detail.tagihan?.Nama }}</b
          >?

          <select
            v-model="model.account_code"
            class="form-select form-select-sm w-auto mr-2 border-yellow-700"
          >
            <option value="700.100">Penerimaan PMB</option>
            <option value="710.100">UK 1</option>
            <option value="710.200">UK 2</option>
            <option value="710.300">Biaya Semester Pendek</option>
            <option value="710.300">Wisuda</option>
          </select>
          <button
            type="button"
            class="btn btn-success w-24"
            @click="konfirmValidasi"
          >
            Ya
          </button>

          <button
            type="button"
            class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 ml-1"
            @click="confirmValidasi = false"
          >
            Tidak
          </button>
        </div>
        <DetailTransaksi
          :detail="detail"
          :process="allowValidasi"
          @validasiTransaksi="validasiTransaksi"
        ></DetailTransaksi>
      </div>
    </SlideOver>
  </MainLayout>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import xlsx from "xlsx";
import { useStore } from "vuex";
import feather from "feather-icons";
import { HTTPKEU } from "@/services/http";
import TailSelectSelector from "@/components/form/TailSelectSelector";
import Alert from "@/components/alerts/Alert";
import { option } from "@/utils/listoptions";
import MainLayout from "@/layouts/main/MainLayout";
import SlideOver from "@/layouts/main/SlideOver";
import { tblTabulator } from "@/utils/tabulator";
import { helper } from "@/utils/helper";
import store from "@/store";
import ModalDialogPositive from "@/components/utility/ModalDialogPositive";
import DetailTransaksi from "../validasi/DetailTransaksi";
import { useRouter } from "vue-router";

export default {
  name: "Main",
  components: {
    DetailTransaksi,
    ModalDialogPositive,
    SlideOver,
    MainLayout,
    Alert,
    TailSelectSelector,
  },

  setup() {
    const router = useRouter();
    const tableRef = ref();
    const tabulator = ref();
    const idData = ref();
    const store = useStore();
    const filter = reactive({
      field: "va",
      type: "like",
      value: "",
    });
    let panelOpen = ref(false);
    let panel = reactive({ panelOpen });
    let tagihan = null;
    let detail = reactive({ tagihan });
    let model = reactive({
      kode_bank: "BCA",
      kode_produk: 0,
      kode_sub_comp: 0,
      id_smt: null,
      npm: null,
      add_info: "",
      tgl_bayar: null,
      account_code: "710.100",
    });

    const showDetail = (e, cell) => {
      idData.value = cell.getData().id;
      detail.tagihan = cell.getData()?.tagihan;

      if (detail.tagihan?.ID) {
        panel.panelOpen = true;
        model.id_smt =
          detail.tagihan?.TahunAkademik.slice(0, 4) +
          detail.tagihan.KD_Semester;
        model.kode_produk = detail.tagihan.KodeProduk;
        model.kode_bank = detail.tagihan.KodeBank;
        model.kode_sub_comp = detail.tagihan.SubComp;
        model.npm = detail.tagihan.NPM;
        model.va = detail.tagihan.NO_VA;
        model.no_upload = detail.tagihan.NO_UPLOAD;
        model.add_info = "";
        model.tgl_bayar = null;
      }

      console.log(detail.tagihan);
    };

    const cetakKwitansi = (e, cell) => {
      let id = cell.getData().id;
      let tagihan = cell.getData()?.tagihan;
      let apiurl = process.env.VUE_APP_KEUANGAN;
      if (tagihan?.ID) {
        let staff = store.state.userdata.userData?.name;
        window.open(apiurl + "cetakkwitansi/" + id + "/" + staff, "_blank");
        //router.push({ to: "https://api.mdp.ac.id" });
      }
    };

    //panel = true;
    const initTabulator = () => {
      let columns = [
        {
          formatter: "responsiveCollapse",
          width: 40,
          minWidth: 30,
          hozAlign: "center",
          resizable: false,
          headerSort: false,
        },
        { formatter: "rownum", hozAlign: "center", width: 40 },
        // For HTML table
        {
          title: "CALON MAHASISWA",
          field: "nama",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
          headerSort: true,
          formatter(cell) {
            return (
              "<div><div class='whitespace-normal font-medium'>" +
              cell.getData().no_ujian +
              "</div>" +
              "<div class='whitespace-normal text-xs'>" +
              cell.getData().nama +
              "</div></div>"
            );
          },
        },

        {
          title: "VA",
          field: "va",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
          headerSort: true,
        },

        {
          title: "JUMLAH TAGIHAN",
          field: "jumlah_upload",
          hozAlign: "left",
          vertAlign: "middle",
          print: true,
          download: true,
          headerSort: false,
          formatter(cell) {
            return helper.IDR(cell.getData()?.jumlah_upload);
          },
        },
        {
          title: "SUB COMP",
          field: "kode_sub_comp",
          hozAlign: "left",
          vertAlign: "middle",
          print: true,
          download: true,
          headerSort: false,
          formatter(cell) {
            return (
              "<div class='whitespace-normal text-xs'>" +
              cell.getData()?.subcomp?.Keterangan +
              " (" +
              cell.getData()?.kode_sub_comp +
              ")" +
              "</div>"
            );
          },
        },
        {
          title: "PRODUK",
          field: "kode_produk",
          hozAlign: "left",
          vertAlign: "middle",
          print: true,
          download: true,
          headerSort: false,
          formatter(cell) {
            return (
              "<div class='whitespace-normal text-xs'>" +
              cell.getData()?.produk?.Keterangan +
              " (" +
              cell.getData()?.kode_produk +
              ")" +
              "</div>"
            );
          },
        },
        {
          title: "VALIDASI",
          field: "validasi",
          hozAlign: "left",
          vertAlign: "middle",
          print: true,
          download: true,
          headerSort: false,
          formatter(cell) {
            let valid = cell.getData()?.validasi;
            let status = "";
            if (valid === "Y") {
              status += `<div class="flex lg:justify-center items-center">
                    <div class=" rounded-full text-center text-xs px-3 py-1 bg-theme-9 text-white font-small
          ">Telah Divalidasi</div>
              </div>`;
            } else {
              status += `<div class="flex lg:justify-center items-center">
                    <div class=" rounded-full text-center text-xs px-3 py-1 bg-theme-6 text-white font-small
          ">Belum Divalidasi</div>
              </div>`;
            }

            return status;
          },
        },
        {
          title: "TRANSAKSI",
          field: "id",
          hozAlign: "left",
          vertAlign: "middle",
          print: true,
          download: true,
          headerSort: false,
          formatter(cell) {
            let tagihan = cell.getData()?.tagihan;
            let status = "";
            if (tagihan?.Ada_Transaksi === "Y") {
              status += `<div class="flex lg:justify-center items-center">
                    <div class=" rounded-full text-center text-xs px-3 py-1 bg-theme-9 text-white font-small
          ">Telah Dibayar</div>
              </div>`;
            } else if (tagihan?.Ada_Transaksi === "T") {
              status += `<div class="flex lg:justify-center items-center">
                    <div class=" rounded-full text-center text-xs px-3 py-1 bg-theme-6 text-white font-small
          ">Belum Dibayar</div>
              </div>`;
            } else {
              status += ``;
            }

            return status;
          },
        },
        {
          title: "",
          field: "id",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            let tagihan = cell.getData()?.tagihan;
            if (tagihan?.Ada_Transaksi === "Y" && tagihan?.Validasi === "T") {
              return `<div class="flex lg:justify-center items-center">
                <btn class="flex btn btn-sm btn-primary items-center">
                  <i data-feather="check" class="w-3 h-3 mr-1"></i> Validasi
                </btn>
              </div>`;
            } else {
              return `<div class="flex lg:justify-center items-center">
                <btn class="flex btn btn-sm btn-default items-center">
                  <i data-feather="eye" class="w-3 h-3 mr-1"></i> Detail
                </btn>
              </div>`;
            }
          },
          cellClick: showDetail,
        },

        {
          title: "",
          field: "id",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter() {
            return `<div class="flex lg:justify-center items-center">
              <btn class="flex btn btn-sm btn-success items-center">
                <i data-feather="printer" class="w-3 h-3 mr-1"></i> Kwitansi
              </btn>
            </div>`;
          },
          cellClick: cetakKwitansi,
        },
      ];
      tabulator.value = tblTabulator.remoteDataTable(
        tableRef,
        "tagihan/biayatagihan",
        columns,
        true
      );
    };
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener("resize", () => {
        tabulator.value.redraw();
        feather.replace({
          "stroke-width": 1.5,
        });
      });
    };
    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value);
    };
    // On reset filter
    const onResetFilter = () => {
      filter.field = "va";
      filter.type = "like";
      filter.value = "";
      onFilter();
    };

    // Filter function
    const onFilterCustom = (field, type, value) => {
      tabulator.value.setFilter(field, type, value);
    };
    // Export
    const onExportCsv = () => {
      tabulator.value.download("csv", "data.csv");
    };
    const onExportJson = () => {
      tabulator.value.download("json", "data.json");
    };
    const onExportXlsx = () => {
      const win = window;
      win.XLSX = xlsx;
      tabulator.value.download("xlsx", "data.xlsx", {
        sheetName: "Products",
      });
    };
    const onExportHtml = () => {
      tabulator.value.download("html", "data.html", {
        style: true,
      });
    };
    // Print
    const onPrint = () => {
      tabulator.value.print();
    };
    onMounted(() => {
      initTabulator();
      reInitOnResizeWindow();
    });
    return {
      store,
      tabulator,
      tableRef,
      filter,
      onFilter,
      onFilterCustom,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      idData,
      panel,
      detail,
      model,
    };
  },
  data() {
    return {
      smt: { a_periode_aktif: 0 },
      loading: false,
      angkatan: new Date().getFullYear(),
      smsSelected: null,
      smtSelected: null,
      subcomps: null,
      banks: null,
      produks: null,

      statusSimpan: {
        msg: null,
        status: null,
      },
      statusGetTagihan: {
        msg: null,
        status: null,
      },
      statusValidasi: {
        msg: null,
        status: null,
      },
      panelOpen: false,
      needreset: false,
      transaksiBank: null,
      allowValidasi: false,
      confirmValidasi: false,
      listCama: [],
      camaSelected: null,
    };
  },

  computed: {
    semester() {
      let year = new Date().getFullYear();
      let years = [];
      for (let i = 2015; i <= year + 1; i++) {
        years.push({ id: i, value: i });
      }
      return years;
    },
    listOptionsState() {
      return this.$store.state.main.listOptions;
    },
    tabulatorLoaded() {
      return this.$store.state.main.tabulatorDataLoaded;
    },
  },
  watch: {
    tabulatorLoaded: {
      handler: function (h) {
        if (h === true) {
          this.$nextTick(() => {
            store.commit("main/resetTabulatorDataLoaded");
          });

          if (this.needreset === true) {
            this.onResetFilter();
            this.needreset = false;
          }
        }
      },
      immediate: true,
    },
    camaSelected: {
      handler: function (h) {
        if (h.length > 0) {
          this.cariDataTagihanMaba(h);
        }
        console.log(h);
      },
      deep: true,
    },
  },
  beforeMount() {
    option.fetchSemester();
    option.fetchProdi(null, false, "Semua Prodi");
    this.getBankList();
    this.getProdukList();
    this.getSubcomList();
  },

  methods: {
    validasiTransaksi(tb, it) {
      this.confirmValidasi = true;
      this.allowValidasi = true;
      if (tb == null) {
        this.model.add_info = it?.infoTambahan != "x" ? it?.infoTambahan : "";
        this.model.tgl_bayar = it?.infoTambahan != "x" ? it?.tglBayar : null;
      }
    },
    selectProdi(curr, id) {
      this.smsSelected = id;
    },
    selectSemester(curr, id) {
      this.smtSelected = id;
      this.listCama = [];
      this.angkatan = "";
      if (this.smtSelected?.length > 0 && Number(this.smtSelected)) {
        this.angkatan = this.smtSelected.substr(0, 4);
        this.smt = curr[0];
        this.getListDataCama();
      }
    },
    getSubcomList() {
      HTTPKEU.get("list/subcomp").then((res) => {
        this.subcomps = res.data;
        let prdpmb = ["00009", "00010"];
        this.subcomps = this.subcomps.filter((f) => {
          return prdpmb.includes(f.Kode);
        });
      });
    },
    getProdukList() {
      HTTPKEU.get("list/produk").then((res) => {
        this.produks = res.data;
        let prdpmb = [
          "001",
          "002",
          "003",
          "010",
          "011",
          "012",
          "022",
          "023",
          "028",
          "029",
          "030",
        ];
        this.produks = this.produks.filter((f) => {
          return prdpmb.includes(f.kode);
        });
      });
    },
    getBankList() {
      HTTPKEU.get("list/bank").then((res) => {
        this.banks = res.data;
      });
    },

    konfirmValidasi() {
      this.statusValidasi.status = null;
      this.statusValidasi.msg = null;

      HTTPKEU.post("tagihan/validasi_tagihan_pmb", { ...this.model })
        .then((res) => {
          if (res.status === 200) {
            this.statusValidasi.status = true;
            this.statusValidasi.msg = res.data.message;
          } else {
            this.statusValidasi.status = false;
            this.statusValidasi.msg = res.data.message;
          }
        })
        .catch((e) => {
          this.statusValidasi.status = false;
          this.statusValidasi.msg = e.response?.data?.message;
        })
        .finally(() => {
          this.cariData();
          this.confirmValidasi = false;
        });

      cash("#validasi-modal").modal("hide");
      //this.biayatagihanid = null;
    },
    closePanel(close) {
      this.panelOpen = close;
      this.panel.panelOpen = close;

      //Validation
      this.transaksiBank = null;
      this.allowValidasi = false;
      this.confirmValidasi = false;
      this.statusValidasi.status = null;
      this.statusValidasi.msg = null;
    },
    cariData() {
      this.tabulator.setData(
        process.env.VUE_APP_KEUANGAN + "tagihan/biayatagihanpmb",
        {
          filter: {
            kode_bank: this.model.kode_bank,
            kode_produk: this.model.kode_produk,
            kode_sub_comp: this.model.kode_sub_comp,
            id_smt: this.smtSelected,
            id_sms: this.smsSelected,
            angkatan: this.angkatan,
          },
        },
        tblTabulator.ajaxConfig()
      );
    },
    cariDataTagihanMaba(id_ujian) {
      this.tabulator.setData(
        process.env.VUE_APP_KEUANGAN + "tagihan/biayatagihanpmb_byidujian",
        {
          filter: {
            id_ujian: id_ujian,
            angkatan: this.angkatan,
          },
        },
        tblTabulator.ajaxConfig()
      );
    },
    resetFilter() {
      this.needreset = true;
      this.filter.value = "";
      this.tabulator.setData(
        process.env.VUE_APP_KEUANGAN + "tagihan/biayatagihanpmb",
        {},
        tblTabulator.ajaxConfig()
      );
    },
    getListDataCama() {
      this.loading = true;
      HTTPKEU.post("list_calon_mhs_tagih", {
        angkatan: this.angkatan,
      })
        .then((res) => {
          this.listCama = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
