<template>
  <div class="mt-3">
    <button
      v-if="dataMhs.length > 0"
      class="btn btn-warning btn-sm"
      @click="resetImport"
    >
      <DeleteIcon class="mr-2 w-4"></DeleteIcon>
      Reset
    </button>

    <button
      v-if="dataMhs.length > 0"
      class="btn btn-success btn-sm ml-2"
      @click="saveImport"
    >
      <SaveIcon class="mr-2 w-4"></SaveIcon>
      Validasi Tagihan
    </button>

    <div v-if="dataMhs.length == 0" class="intro-y box">
      <div class="grid grid-cols-12 mb-3 gap-3">
        <div class="col-span-4">
          <label class="form-label">Tahun Akademik</label>
          <TailSelectSelector
            :list-options="semesterList"
            @cbox-select="selectSemester"
          ></TailSelectSelector>
        </div>
        <div class="col-span-8">
          <div
            v-if="smtSelected != null"
            class="form-group mt-4 py-5 rounded-lg bg-gray-200 hover:bg-gray-300 dark:bg-dark-1 dark:hover:bg-dark-2 relative"
          >
            <input
              id="fileUpload"
              type="file"
              class="inputfile"
              :disabled="smtSelected == null"
              @change="onChangeFile"
            />
            <div for="file" class="fileinfo text-gray-900 dark:text-white">
              <div>
                {{ namaFile }}
              </div>
              <p class="text-sm text-gray-600 dark:text-gray-400">
                {{ informasiFile }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <button
      v-if="dataMhs.length == 0"
      class="btn btn-primary btn-sm mt-3"
      @click="downloadTemplateExcel"
    >
      <FileTextIcon class="w-4 h-4 mr-2"></FileTextIcon>
      Download Template Validasi Tagihan
    </button>
  </div>
</template>

<script>
import readXlsxFile from "read-excel-file";
import writeXlsxFile from "write-excel-file";
import { saveAs } from "file-saver";
import { mahasiswa } from "@/services/models";
import TailSelectSelector from "../../../components/form/TailSelectSelector";
export default {
  name: "UploadFileExcel",
  components: { TailSelectSelector },
  props: {
    prodi: {
      type: String,
      default: null,
    },
    idSmt: {
      type: String,
      default: null,
    },
    semesterList: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      dataMhs: [],
      namaFile: "Pilih File Excel",
      informasiFile: "Pilih File Excel, sesuai dengan format",
      listMhs: [],
      smtSelected: null,
      smt: { a_periode_aktif: 0 },
    };
  },
  watch: {
    prodi: {
      handler: function () {
        //this.loadMahasiswaBaru();
      },
      immediate: true,
    },
    idSmt: {
      handler: function () {},
      immediate: true,
    },
    semesterList: {
      handler: function () {},
      immediate: true,
    },
  },
  mounted() {
    //this.loadMahasiswaBaru();
  },
  methods: {
    onChangeFile(e) {
      this.dataMhs = [];
      let file = e.target.files[0] ?? null;
      if (file != null) {
        this.namaFile = file.name;
        readXlsxFile(file, { dateFormat: "mm/dd/yyyy" })
          .then((rows) => {
            try {
              rows.splice(0, 1);
              rows.forEach((row) => {
                let dtbayar = {
                  kode_bank: "BCA",
                  no_va: row[0],
                  nama: row[1],
                  sub_comp: row[2],
                  produk: row[3],
                  account_code: row[4],
                  id_smt: row[5],
                };

                this.dataMhs.push(dtbayar);
              });
              this.namaFile = "Pilih File Excel";
              this.informasiFile = "File Berhasil di Proses";
              this.$emit("dataExcel", this.dataMhs);
              e.target.value = "";
            } catch (error) {
              this.informasiFile = "File Gagal di Proses";
              e.target.value = "";
            }
          })
          .catch((e) => {
            this.informasiFile =
              "Pastikan File yang di pilih adalah file excel";
            e.target.value = "";
          });
      }
    },
    saveImport() {
      this.$emit("saveImport", true);
    },
    resetImport() {
      this.dataMhs = [];
      this.$emit("dataExcel", this.dataMhs);
      this.informasiFile = "Pilih File Excel, sesuai dengan format";
    },
    async downloadTemplateExcel() {
      const data = [
        [
          {
            value: "no_va",
            fontWeight: "bold",
          },
          {
            value: "nama",
            fontWeight: "bold",
          },
          {
            value: "sub_comp",
            fontWeight: "bold",
          },
          {
            value: "produk",
            fontWeight: "bold",
          },
          {
            value: "account_code (cek Master Akun)",
            fontWeight: "bold",
          },

          {
            value: "id_smt",
            fontWeight: "bold",
          },
        ],
      ];

      //this.listMhs.forEach((mhs) => {
      data.push([
        {
          type: String,
          value: "2009250066",
        },
        {
          type: String,
          value: "Nur Rachmat",
        },
        {
          type: String,
          value: "00003",
        },
        {
          type: String,
          value: "003",
        },
        {
          type: String,
          value: "710.100",
        },
        {
          type: String,
          value: this.smtSelected.toString(),
        },
      ]);
      //});

      let blobMhs = await writeXlsxFile(data, {
        filePatch: "data.xlsx",
      });
      const blobData = new Blob([blobMhs], {
        type: "application/vnd.ms-excel;charset=utf-8",
      });
      saveAs(
        blobData,
        `TemplateImportValidasiBayaran-${new Date().toLocaleDateString()}.xlsx`
      );
    },
    selectSemester(curr, id) {
      this.smtSelected = id;
      this.smt = curr[0];
      //console.log(this.smt, this.smtSelected);
    },
  },
};
</script>
<style scoped>
.form-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputfile {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  cursor: pointer;
  padding: 10px;
  opacity: 0;
}

.inputfile + .fileinfo {
  font-size: 1rem;
  font-weight: bold;
  display: inline-block;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.inputfile:focus + .fileinfo,
.inputfile + .fileinfo:hover,
.inputfile:hover + .fileinfo {
  cursor: pointer;
}

.inputfile + .fileinfo {
  cursor: pointer;
}
</style>
