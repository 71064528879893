<template>
  <MainLayout>
    <div class="relative">
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">
          Transaksi Pembayaran {{ id }}
        </h2>
        <div
          class="w-full sm:w-auto md:flex justify-center items-center align-center mt-4 sm:mt-0"
        >
          <router-link
            tag="a"
            class="btn btn-success btn-sm w-50 mt-3 mb-2"
            :to="{ name: 'tagihan-validasi' }"
          >
            <CheckCircleIcon class="w-5 mr-1" />
            Validasi Pembayaran
          </router-link>
        </div>
      </div>

      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 lg:col-span-12">
          <div v-if="loading" class="block-loading">
            <LoadingIcon icon="three-dots" class="w-8 h-8" />
            <div class="text-center text-xs mt-2">
              Loading data... Please Wait...
            </div>
          </div>
          <div class="intro-y box">
            <Alert
              v-if="statusValidasi.status != null"
              :type="statusValidasi.status ? 'alert-success' : 'alert-danger'"
              :dismissable="true"
              class="m-3"
              >{{ statusValidasi.msg }}</Alert
            >
            <DetailTransaksi
              v-if="detail?.tagihan?.ID"
              :detail="detail"
              class="p-3"
              @validasiTransaksi="validasiTransaksi"
            ></DetailTransaksi>
            <AlertFailed
              v-if="detail?.tagihan?.ID == null && loading == false"
              :message="'Transaksi pembayaran tidak ditemukan!'"
            ></AlertFailed>
          </div>
        </div>
      </div>
      <ModalDialogPositive
        :id="'validasi-modal'"
        :question="
          'Anda yakin ingin memvalidasi pembayaran tagihan ' +
          detail.tagihan?.NamaProduk +
          ' ' +
          detail.tagihan?.Nama +
          '?'
        "
      >
        <template #delete-button>
          <button
            type="button"
            class="btn btn-success w-24"
            @click="konfirmValidasi"
          >
            Ya
          </button>
        </template>
      </ModalDialogPositive>
    </div>
  </MainLayout>
</template>

<script>
import { onMounted } from "vue";
import { HTTPKEU } from "@/services/http";
import { option } from "@/utils/listoptions";
import MainLayout from "@/layouts/main/MainLayout";
import ModalDialogPositive from "@/components/utility/ModalDialogPositive";
import { useRoute } from "vue-router";
import axios from "axios";
import DetailTransaksi from "../validasi/DetailTransaksi";
import AlertFailed from "../../../components/alerts/AlertFailed";
import Alert from "../../../components/alerts/Alert";

export default {
  name: "Tertagih",
  components: {
    Alert,
    AlertFailed,
    DetailTransaksi,
    ModalDialogPositive,
    MainLayout,
  },
  setup() {
    const route = useRoute();
    //let tagihan = null;

    const id = route.params.trxid;
    console.log(id);
    /*const loadTagihan = () => {

    };*/
    onMounted(() => {
      //loadTagihan();
    });
    return {
      id,
      //loadTagihan,
    };
  },

  data() {
    return {
      loading: true,
      smsSelected: null,
      smtSelected: null,
      subcomps: null,
      banks: null,
      produks: null,
      statusValidasi: {
        msg: null,
        status: null,
      },
      transaksiBank: null,
      allowValidasi: false,
      model: {
        kode_bank: "BCA",
        kode_produk: null,
        kode_sub_comp: null,
        id_smt: null,
        npm: null,
      },
      detail: {
        tagihan: null,
      },
    };
  },

  computed: {
    semester() {
      let year = new Date().getFullYear();
      let years = [];
      for (let i = 2016; i <= year; i++) {
        years.push({ id: i, value: i });
      }
      return years;
    },
    listOptionsState() {
      return this.$store.state.main.listOptions;
    },
    tabulatorLoaded() {
      return this.$store.state.main.tabulatorDataLoaded;
    },
  },
  watch: {
    id: {
      handler: function (h) {
        if (h?.length > 0) this.loadTagihan();
        if (h?.length == 0 && this.loading) {
          this.loading = false;
        }
      },
      immediate: true,
    },
  },
  beforeMount() {
    option.fetchSemester();
    option.fetchProdi();
  },
  mounted() {},

  methods: {
    getSubcomList() {
      HTTPKEU.get("master/subcomp").then((res) => {
        this.subcomps = res.data;
      });
    },
    getProdukList() {
      HTTPKEU.get("master/produk").then((res) => {
        this.produks = res.data;
      });
    },
    getBankList() {
      HTTPKEU.get("master/bank").then((res) => {
        this.banks = res.data;
      });
    },
    konfirmValidasi() {
      this.statusValidasi.status = null;
      this.statusValidasi.msg = null;

      HTTPKEU.post("tagihan/validasi", { ...this.model })
        .then((res) => {
          if (res.status === 200) {
            this.statusValidasi.status = true;
            this.statusValidasi.msg = res.data.message;
          } else {
            this.statusValidasi.status = false;
            this.statusValidasi.msg = res.data.message;
          }
        })
        .catch((e) => {
          this.statusValidasi.status = false;
          this.statusValidasi.msg = e.response?.data?.message;
        })
        .finally(() => {
          this.loadTagihan();
        });

      cash("#validasi-modal").modal("hide");
    },
    cekTransaksiBank(bank, idtrx) {
      this.transaksiBank = null;
      this.allowValidasi = false;
      var data = new FormData();
      data.append("id", idtrx);

      var config = {
        method: "post",
        url: "https://sw.mdp.net.id/bill",
        headers: {
          Accept: "application/json",
        },
        data: data,
      };
      let that = this;
      axios(config)
        .then(function (res) {
          if (res.status === 200) {
            that.statusValidasi.status =
              res.data?.error?.code == 0 ? true : false;
            that.statusValidasi.msg =
              res.data?.error?.code == 0
                ? "Transaksi BANK ditemukan!"
                : res.data?.error?.msg;
            if (res.data?.error?.code == "0") {
              that.transaksiBank = res.data?.data?.TransactionData;
              that.allowValidasi = true;
            }
          } else {
            that.statusValidasi.status = false;
            that.statusValidasi.msg = res.message;
          }
          console.log(that.allowValidasi);
        })
        .catch(function () {
          that.statusValidasi.status = false;
          that.statusValidasi.msg = "Tejadi error pada API Payment Gateway";
        });
    },
    clearV() {
      this.statusValidasi.status = null;
      this.statusValidasi.msg = null;
    },
    loadTagihan() {
      this.loading = true;
      HTTPKEU.get("tagihan/get_transaksi_mhs_bytrx", {
        params: {
          id_trx: this.id,
        },
      })
        .then((res) => {
          this.detail.tagihan = res.data?.data;
          if (this.detail.tagihan?.ID !== null) {
            this.model.id_smt =
              this.detail.tagihan.TahunAkademik.slice(0, 4) +
              this.detail.tagihan.KD_Semester;
            this.model.kode_produk = this.detail.tagihan.KodeProduk;
            this.model.kode_bank = this.detail.tagihan.KodeBank;
            this.model.kode_sub_comp = this.detail.tagihan.SubComp;
            this.model.npm = this.detail.tagihan.NPM;
            this.model.va = this.detail.tagihan.NO_VA;
            this.model.no_upload = this.detail.tagihan.NO_UPLOAD;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    validasiTransaksi() {
      cash("#validasi-modal").modal("show");
    },
  },
};
</script>

<style scoped></style>
