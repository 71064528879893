<template>
  <div class="relative">
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Buat Tagihan Uang Kuliah (UK)</h2>
      <div
        class="w-full sm:w-auto md:flex justify-center items-center align-center mt-4 sm:mt-0"
      >
        <router-link
          tag="a"
          class="btn btn-primary w-50 mt-3 mb-2 mr-2"
          :to="{ name: 'tagihan-uk-cicilan-create' }"
        >
          <PlusCircleIcon class="w-5 mr-1" />
          Buat Tagihan Cicilan
        </router-link>
        <router-link
          tag="a"
          class="btn btn-success w-50 mt-3 mb-2"
          :to="{ name: 'tagihan-list' }"
        >
          <CheckCircleIcon class="w-5 mr-1" />
          Validasi Pembayaran UK
        </router-link>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <div v-if="loading" class="block-loading">
          <LoadingIcon icon="three-dots" class="w-8 h-8" />
          <div class="text-center text-xs mt-2">
            Loading data... Please Wait...
          </div>
        </div>
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div class="grid grid-cols-12 gap-3 p-3">
            <div class="col-span-12 xl:col-span-3">
              <label class="form-label">Tahun Akademik</label>
              <TailSelectSelector
                :list-options="
                  listOptionsState?.semester?.filter((a) => a.smt !== 3)
                "
                @cbox-select="selectSemester"
              ></TailSelectSelector>

              <div class="grid grid-cols-2 mt-3 gap-3 mb-2">
                <div class="col-span-1">
                  <label class="form-label">Tahun Ajaran</label>
                  <input
                    class="form-control"
                    :value="smt?.thn_ajaran?.nm_thn_ajaran"
                    readonly
                    disabled
                  />
                </div>
                <div class="col-span-1">
                  <label class="form-label">Semester</label>
                  <input
                    class="form-control"
                    :value="smt?.nm_smt"
                    readonly
                    disabled
                  />
                </div>
              </div>

              <!--              <button
                type="button"
                class="btn btn-primary w-50 mt-3 mb-2 mr-6"
                :disabled="smtSelected == null || smsSelected == null"
                @click="cariData"
              >
                Cari Data
              </button>-->
              <button
                :disabled="
                  (smtSelected == null || smsSelected == null) &&
                  (listBiayaMhs?.length == 0 || biayaKuliah?.length == 0)
                "
                type="button"
                class="btn bg-purple-500 text-white w-50 ml-auto mr-6 mt-3"
                @click="cariData"
              >
                Cari Data dan Cek Tagihan
              </button>
            </div>
            <div class="col-span-12 xl:col-span-4">
              <label class="form-label">Program Studi</label>
              <TailSelectSelector
                :list-options="listOptionsState?.prodi"
                @cbox-select="selectProdi"
              ></TailSelectSelector>

              <label class="form-label">Jenis Pembiayaan</label>
              <TailSelect
                v-model="id_jenis_biaya"
                :options="{
                  search: true,
                  classNames: 'w-full',
                  descriptions: true,
                  hideSelected: true,
                  hideDisabled: true,
                  multiShowCount: true,
                  multiContainer: true,
                  multiLimit: 1,
                }"
                multiple
              >
                <option
                  v-for="list in jenisBiayaList"
                  :key="list.id"
                  :value="list.id"
                >
                  {{ list.nama }}
                </option>
              </TailSelect>
              <div class="mt-3">
                <Alert
                  v-if="errorbiayaKuliah != null"
                  :type="'alert-danger'"
                  :dismissable="true"
                  >{{ errorbiayaKuliah }}</Alert
                >

                <Alert
                  v-if="statusSimpan.status != null"
                  :type="statusSimpan.status ? 'alert-success' : 'alert-danger'"
                  :dismissable="true"
                  >{{ statusSimpan.msg }}</Alert
                >
                <Alert
                  v-if="statusGetTagihan.status != null"
                  :type="
                    statusGetTagihan.status ? 'alert-success' : 'alert-danger'
                  "
                  :dismissable="true"
                  >{{ statusGetTagihan.msg }}</Alert
                >
                <Alert
                  v-if="statusHapus.status != null"
                  :type="statusHapus.status ? 'alert-success' : 'alert-danger'"
                  :dismissable="true"
                  >{{ statusHapus.msg }}</Alert
                >
              </div>

              <router-link
                tag="a"
                class="btn btn-warning w-50 mb-2"
                :to="{ name: 'master-keuangan' }"
              >
                Master Biaya UK
              </router-link>

              <router-link
                tag="a"
                class="btn btn-primary w-50 mb-2 ml-2"
                :to="{ name: 'mahasiswa-cicilan' }"
              >
                Mahasiswa Cicilan
              </router-link>

              <button
                type="button"
                class="btn btn-danger w-50 mb-2 ml-3"
                :disabled="smtSelected == null || smsSelected == null"
                @click="reset"
              >
                Reset
              </button>
            </div>

            <div class="col-span-12 xl:col-span-1">
              <label class="form-label">Angkatan</label>
              <select v-model="angkatan" class="form-select sm:mr-2 mb-2">
                <option
                  v-for="smtr in semester"
                  :key="smtr.id"
                  :value="smtr.value"
                >
                  {{ smtr.value }}
                </option>
              </select>
            </div>

            <div
              class="col-span-12 xl:col-span-4 bg-gray-300 dark:bg-gray-700 pb-3"
            >
              <div class="p-3">
                <div class="grid grid-cols-2 gap-2">
                  <div class="col-span-1">
                    <label class="form-label">Sub Comp</label>
                    <TailSelect
                      v-model="model.subcomp"
                      :options="{
                        search: true,
                        classNames: 'w-full',
                        deselect: true,
                      }"
                    >
                      <option
                        v-for="list in subcomps"
                        :key="list.Kode"
                        :value="list.Kode"
                      >
                        {{ list.Keterangan }} ({{ list.Kode }})
                      </option>
                    </TailSelect>
                  </div>
                  <div class="col-span-1">
                    <label class="form-label">Produk</label>

                    <TailSelect
                      v-model="model.produk"
                      :options="{
                        search: true,
                        classNames: 'w-full',
                        deselect: true,
                      }"
                    >
                      <option
                        v-for="list in produks"
                        :key="list.kode"
                        :value="list.kode"
                      >
                        {{ list.Keterangan }} ({{ list.kode }})
                      </option>
                    </TailSelect>
                  </div>
                </div>

                <label class="form-label mt-3">Bank</label>
                <select v-model="model.bank" class="form-select">
                  <option
                    v-for="(item, index) in banks"
                    :key="index"
                    :value="item.kode"
                  >
                    {{ item.Keterangan }}
                  </option>
                </select>
              </div>

              <button
                :disabled="
                  listBiayaMhs?.length == 0 || biayaKuliah?.length == 0
                "
                type="button"
                class="btn btn-success w-50 ml-3 mr-5"
                @click="buatTagihan"
              >
                Simpan Data
              </button>
            </div>
          </div>
          <TableMhsAktif
            v-if="showtable"
            :list-mhs-aktif="listMhsAktif"
            :biaya-adm="biayaAdm"
            :id-jenis-biaya="id_jenis_biaya"
            :jenis-biaya-list="jenisBiayaList"
            :biaya-kuliah="biayaKuliah"
            :list-tagihan-jupiter="listTagihanJupiter"
            :jenis-biaya-selected="jenisBiayaSelected"
            :model="model"
            :biaya-mhs="biaya"
            class="p-2"
            @filterdata="filterData"
            @listBiayaMhs="setListBiayaMhs"
            @hapusBiayaTagihan="hapusBiayaTagihan"
          />
          <div v-else class="text-center">
            Memuat data mahasiswa dan biaya tagihan
          </div>
        </div>
      </div>
    </div>

    <ModalDialog
      :question="
        'Anda yakin ingin menghapus Tagihan ' + biayatagihanid?.nm_pd + '?'
      "
    >
      <template #delete-button>
        <button
          type="button"
          class="btn btn-danger w-24"
          @click="konfirmHapusBiayatagihan"
        >
          Ya
        </button>
      </template>
    </ModalDialog>

    <ModalDialogPositive
      :id="'buat-tagihan'"
      :question="
        'Anda yakin ingin membuat Tagihan untuk ' +
        listBuatTagihan?.length +
        ' mahasiswa?'
      "
    >
      <template #delete-button>
        <button type="button" class="btn btn-success w-24" @click="submit">
          Ya
        </button>
      </template>
    </ModalDialogPositive>
  </div>
</template>

<script>
import TailSelectSelector from "@/components/form/TailSelectSelector";
const TableMhsAktif = defineAsyncComponent(() => import("./TableMhsAktif"));
import { option } from "@/utils/listoptions";
import { HTTPKEU } from "../../../services/http";
import Alert from "../../../components/alerts/Alert";
import ModalDialog from "../../../components/utility/ModalDialog";
import { defineAsyncComponent } from "vue";
import ModalDialogPositive from "../../../components/utility/ModalDialogPositive";
export default {
  name: "Form",
  components: {
    ModalDialogPositive,
    ModalDialog,
    Alert,
    TableMhsAktif,
    TailSelectSelector,
  },
  data() {
    return {
      smt: { a_periode_aktif: 0 },
      listMhsAktif: [],
      listTagihanJupiter: [],
      angkatan: new Date().getFullYear(),
      smsSelected: null,
      smtSelected: null,
      subcomps: null,
      banks: null,
      produks: null,
      jenisBiayaList: null,
      id_jenis_biaya: ["2"],
      jenisBiayaSelected: [],
      model: {
        bank: "BCA",
        produk: null,
        subcomp: null,
        id_smt: null,
      },
      biayaKuliah: null,
      errorbiayaKuliah: null,
      statusSimpan: {
        msg: null,
        status: null,
      },
      statusGetTagihan: {
        msg: null,
        status: null,
      },
      statusHapus: {
        msg: null,
        status: null,
      },
      loading: false,
      biayaAdm: 3000,
      biaya: [],
      listBiayaMhs: [],
      biayatagihanid: null,
      showtable: false,
      modecicilan: false,
    };
  },

  computed: {
    semester() {
      let year = new Date().getFullYear();
      let years = [];
      for (let i = 2015; i <= year; i++) {
        years.push({ id: i, value: i });
      }
      return years;
    },
    listOptionsState() {
      return this.$store.state.main.listOptions;
    },
    listBuatTagihan() {
      return this.listBiayaMhs.filter((f) => f.buat_tagihan === true);
    },
  },

  watch: {
    id_jenis_biaya: {
      handler: function (h) {
        this.setJenisBiayaSelected();
        this.biayaKuliahDetail();
      },
      deep: true,
    },
    biaya: {
      handler: function (h) {
        //console.log(h);
      },
      deep: true,
    },
  },
  beforeMount() {
    option.fetchSemester();
    option.fetchProdi();
    this.getBankList();
    this.getProdukList();
    this.getSubcomList();
    this.getJenisBiayaList();
  },
  mounted() {
    this.setJenisBiayaSelected();
  },
  methods: {
    selectProdi(curr, id) {
      this.smsSelected = id;
    },
    selectSemester(curr, id) {
      this.smtSelected = id;
      this.smt = curr[0];
    },
    reset() {
      this.listMhsAktif = [];
      this.biayaAdm = 3000;
      this.listTagihanJupiter = [];
      this.biaya = [];
    },
    cariData() {
      this.loading = true;

      let that = this;
      Promise.all([this.getBiaya(), this.getMhsAktif(), this.getBiayaADM()])
        .then(function (result) {
          that.biayaKuliah = result[0].data;
          that.listMhsAktif = result[1].data;

          if (result[0].data?.length === 0) {
            that.errorbiayaKuliah =
              "Biaya kuliah tidak ditemukan, silakan buat master biaya terlebih dahulu!";
          }

          if (result[2].data?.length > 0) {
            that.biayaAdm = result[2].data?.biaya;
          }
        })
        .finally(() => {
          this.biayaKuliahDetail();
          this.loading = false;
        });

      this.model.id_smt = this.smtSelected;
      this.model.id_sms = this.smsSelected;
      this.model.tahun = this.angkatan;
    },
    filterData(f) {
      this.loading = true;
      this.showtable = false;

      let that = this;
      Promise.all([
        this.getBiaya(),
        this.getMhsAktif(f.size, f.page),
        this.getBiayaADM(),
      ])
        .then(function (result) {
          that.biayaKuliah = result[0].data;
          that.listMhsAktif = result[1].data;

          if (result[0].data?.length === 0) {
            that.errorbiayaKuliah =
              "Biaya kuliah tidak ditemukan, silakan buat master biaya terlebih dahulu!";
          }

          if (result[2].data?.length > 0) {
            that.biayaAdm = result[2].data?.biaya;
          }
        })
        .finally(() => {
          this.biayaKuliahDetail();
          this.loading = false;
        });
    },
    getSubcomList() {
      HTTPKEU.get("list/subcomp").then((res) => {
        this.subcomps = res.data;
      });
    },
    getProdukList() {
      HTTPKEU.get("list/produk").then((res) => {
        this.produks = res.data;
      });
    },
    getBankList() {
      HTTPKEU.get("list/bank").then((res) => {
        this.banks = res.data;
      });
    },
    getBiayaADM() {
      return HTTPKEU.post("master/biayaAdm", {
        tahun: this.smtSelected?.substr(0, 4),
      });
    },
    getJenisBiayaList() {
      HTTPKEU.get("list/jenisbiaya")
        .then((res) => {
          this.jenisBiayaList = res.data;
        })
        .finally(() => {
          this.setJenisBiayaSelected();
        });
    },
    getMhsAktif(s, p) {
      return HTTPKEU.post("tagihan/mhs_aktif", {
        id_smt: this.smtSelected,
        id_sms: this.smsSelected,
        tahun: this.angkatan,
        size: s ?? 50,
        page: p ?? 1,
        subcomp: this.model.subcomp,
        bank: this.model.bank,
        produk: this.model.produk,
      });
    },
    getBiaya() {
      return HTTPKEU.post("master/getbiayakuliah", {
        tahun: this.angkatan,
        id_jenis_biaya: this.id_jenis_biaya,
        id_sms: this.smsSelected,
      });
    },

    biayaKuliahDetail() {
      this.setJenisBiayaSelected();
      let y = [];
      this.listMhsAktif.data?.forEach((a) => {
        y.push(this.biayaKuliahMhs(a.kelompok_kelas));
      });
      Promise.all(y).then(() => {
        this.showtable = true;
      });
    },
    biayaKuliahMhs(sub_jenis) {
      let n = 0;
      this.jenisBiayaSelected.forEach((e) => {
        if (this.biaya?.length > 0) {
          let b = this.biaya?.find((f) => {
            return f.id_jenis_biaya == e.id && f.sub_jenis == sub_jenis;
          });
          if (b?.id == null) {
            this.getBiayaDetail(
              this.model.tahun,
              e.id,
              this.model.id_sms,
              sub_jenis
            ).then(() => {
              n++;
            });
          }
        } else {
          this.getBiayaDetail(
            this.model.tahun,
            e.id,
            this.model.id_sms,
            sub_jenis
          ).then(() => {
            n++;
          });
        }
      });
      return n;
    },

    getBiayaDetail(t, b, f, s) {
      this.loading = true;
      let that = this;
      return new Promise(function (resolve) {
        HTTPKEU.post("master/getbiayakuliahdetail", {
          tahun: t,
          id_jenis_biaya: b,
          id_sms: f,
          sub_jenis: s,
        }).then((d) => {
          if (d.status === 200) {
            if (that.biaya?.length > 0) {
              let e = that.biaya?.find((f) => {
                return (
                  f.id_jenis_biaya == b && f.sub_jenis == s && f.tahun == t
                );
              });
              if (e?.id == null) {
                if (d?.data?.id) {
                  that.biaya.push(d.data);
                }
              }
            } else {
              if (d?.data?.id) {
                that.biaya.push(d.data);
              }
            }
          }

          that.loading = false;
          resolve(true);
        });
      });
    },

    setListBiayaMhs(val) {
      this.listBiayaMhs = val;
    },
    setJenisBiayaSelected() {
      let h = this.id_jenis_biaya;
      if (this.jenisBiayaList?.length > 0) {
        let a = h?.map((x) => {
          return parseInt(x);
        });
        this.jenisBiayaSelected = this.jenisBiayaList.filter((f) => {
          return a.includes(f.id);
        });
      }
    },
    submit() {
      this.statusSimpan.msg = null;
      this.statusSimpan.status = null;
      if (this.model.subcomp != null || this.model.produk != null) {
        this.loading = true;

        HTTPKEU.post("tagihan/simpan_tagihan", {
          subcomp: this.model.subcomp,
          bank: this.model.bank,
          produk: this.model.produk,
          data_mhs: this.listBiayaMhs.filter((f) => f.buat_tagihan === true),
          id_smt: this.model.id_smt,
          id_jenis_biaya: this.id_jenis_biaya,
        })
          .then((res) => {
            if (res.status === 200) {
              this.statusSimpan.msg = res.data?.message;
              this.statusSimpan.status = true;
            } else {
              this.statusSimpan.msg = res.data?.message;
              this.statusSimpan.status = false;
            }
          })
          .catch((err) => {
            this.statusSimpan.msg = err.message;
            this.statusSimpan.status = false;
          })
          .finally(() => {
            this.loading = false;
            this.cekTagihanJupiter();
          });
      } else {
        this.statusSimpan.msg = "Pilih SubComp dan Produk terlebih dahulu";
        this.statusSimpan.status = false;
      }

      cash("#buat-tagihan").modal("hide");
    },
    cekTagihanJupiter() {
      this.statusGetTagihan.msg = null;
      this.statusGetTagihan.status = null;
      if (this.model.subcomp != null || this.model.produk != null) {
        this.loading = true;

        HTTPKEU.post("tagihan/get_tagihan_jupiter", {
          subcomp: this.model.subcomp,
          bank: this.model.bank,
          produk: this.model.produk,
          id_fakultas: this.listBiayaMhs[0]["id_fakultas"],
          id_smt: this.model.id_smt,
        })
          .then((res) => {
            if (res.status == 200) {
              this.listTagihanJupiter = res?.data;
            } else {
              this.statusGetTagihan.msg = res.data?.message;
              this.statusGetTagihan.status = false;
            }
          })
          .catch((err) => {
            this.listTagihanJupiter = [];
            this.statusGetTagihan.msg = err.message;
            this.statusGetTagihan.status = false;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.statusGetTagihan.msg = "Pilih SubComp dan Produk terlebih dahulu";
        this.statusGetTagihan.status = false;
      }
    },

    hapusBiayaTagihan(btag) {
      this.biayatagihanid = btag;
      cash("#delete-modal").modal("show");
    },

    konfirmHapusBiayatagihan() {
      this.statusHapus.status = null;
      this.statusHapus.msg = null;
      console.log(this.biayatagihanid);
      HTTPKEU.post("tagihan/hapus_tagihan", {
        _method: "DELETE",
        id_smt: this.model.id_smt,
        nipd: this.biayatagihanid.nipd,
        subcomp: this.model.subcomp,
        bank: this.model.bank,
        produk: this.model.produk,
      })
        .then((res) => {
          if (res.status === 200) {
            this.statusHapus.status = true;
            this.statusHapus.msg = res.data.message;
          } else {
            this.statusHapus.status = false;
            this.statusHapus.msg = res.data.message;
          }
        })
        .catch((e) => {
          console.log(e.response?.status);
          this.statusHapus.status = false;
          this.statusHapus.msg = e.response?.data?.message;
        })
        .finally(() => {
          this.cariData();
          this.cekTagihanJupiter();
        });

      cash("#delete-modal").modal("hide");
      this.biayatagihanid = null;
    },

    buatTagihan() {
      cash("#buat-tagihan").modal("show");
    },
    setModeCicilan(mode){
      this.modecicilan = mode;
    },
  },
};
</script>

<style scoped></style>
