<template>
  <MainLayout>
    <div class="relative">
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">
          Data Biaya Tagihan Mahasiswa
        </h2>
        <div
          class="w-full sm:w-auto md:flex justify-center items-center align-center mt-4 sm:mt-0"
        ></div>
      </div>

      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 lg:col-span-12">
          <div v-if="loading" class="block-loading">
            <LoadingIcon icon="three-dots" class="w-8 h-8" />
            <div class="text-center text-xs mt-2">
              Loading data... Please Wait...
            </div>
          </div>

          <div class="intro-y box">
            <div class="grid grid-cols-12 mb-3">
              <div class="col-span-12 xl:col-span-4 p-3">
                <div class="grid grid-cols-2 gap-3">
                  <div class="col-span-1">
                    <label class="form-label">Tahun Akademik</label>
                    <TailSelectSelector
                      :list-options="listOptionsState?.semester"
                      @cbox-select="selectSemester"
                    ></TailSelectSelector>
                  </div>
                  <div class="col-span-1">
                    <label class="form-label">Jenis Pembiayaan</label>
                    <TailSelect
                      v-model="jenis_biaya"
                      :options="{
                        search: true,
                        classNames: 'w-full',
                        descriptions: true,
                        deselect: true,
                      }"
                      @change="setJenisBiayaSelected(v)"
                    >
                      <option
                        v-for="list in jenisBiayaList"
                        :key="list.id"
                        :value="list.id"
                      >
                        {{ list.nama }}
                      </option>
                    </TailSelect>
                  </div>
                </div>
                <div class="grid grid-cols-2 gap-3 mb-2">
                  <div class="col-span-1">
                    <label class="form-label">Tahun Ajaran</label>
                    <input
                      class="form-control"
                      :value="smt?.thn_ajaran?.nm_thn_ajaran"
                      readonly
                      disabled
                    />
                  </div>
                  <div class="col-span-1">
                    <label class="form-label">Semester</label>
                    <input
                      class="form-control"
                      :value="smt?.nm_smt"
                      readonly
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-12 xl:col-span-2 p-3">
                <label class="form-label">Program Studi</label>
                <TailSelectSelector
                  class="col-span-5"
                  :list-options="listOptionsState?.prodi"
                  @cbox-select="selectProdi"
                ></TailSelectSelector>

                <label class="form-label">Angkatan</label>
                <select v-model="angkatan" class="form-select sm:mr-2">
                  <option
                    v-for="smtr in semester"
                    :key="smtr.id"
                    :value="smtr.id"
                  >
                    {{ smtr.value }}
                  </option>
                </select>

                <button
                  class="btn btn-success w-50 mr-5 mt-2"
                  @click="cariData"
                >
                  Cek Tagihan
                </button>
              </div>
              <div
                class="col-span-12 xl:col-span-6 bg-gray-300 dark:bg-gray-700"
              >
                <div class="grid grid-cols-2 gap-3">
                  <div class="col-span-1">
                    <div class="p-3">
                      <h3 class="font-medium mb-2">Seluruh Tagihan</h3>
                      Jumlah Tagihan : {{ statistik?.all?.jumlah }}<br />
                      Total Nominal Tagihan :
                      {{ $h.IDR(statistik?.all?.total_tagihan) }}<br />
                      Jumlah Transaksi : {{ statistik?.lunas?.jumlah }}<br />
                      Total Nominal Transaksi :
                      {{ $h.IDR(statistik?.lunas?.total_tagihan) }}<br />
                    </div>
                  </div>
                  <div v-if="statistik" class="col-span-1">
                    <div v-if="sms" class="p-3">
                      <h3 class="font-medium mb-2">
                        Prodi {{ sms?.nama }} ({{ sms?.jenjang }})
                      </h3>
                      Jumlah Tagihan : {{ statistik?.filter_all?.jumlah }}<br />
                      Total Nominal Tagihan :
                      {{ $h.IDR(statistik?.filter_all?.total_tagihan) }}<br />
                      Jumlah Transaksi : {{ statistik?.filter_lunas?.jumlah
                      }}<br />
                      Total Nominal Transaksi :
                      {{ $h.IDR(statistik?.filter_lunas?.total_tagihan) }}<br />
                    </div>
                  </div>
                </div>

                <div class="p-3">
                  <router-link
                    :to="{ name: 'buka-tutup-absen' }"
                    class="btn btn-danger w-50 mr-2"
                    @click="cariData"
                  >
                    <alert-circle-icon class="mr-1 w-5"></alert-circle-icon>
                    Buka/Tutup Absen Mahasiswa
                  </router-link>
                  <button class="btn btn-primary w-50" @click="kirimNotifikasi">
                    <send-icon class="mr-1 w-5"></send-icon>
                    Kirim Pengingat Tagihan
                  </button>
                </div>
                <div class="p-3">
                  <Alert
                    v-if="statusValidasi.status != null"
                    :type="
                      statusValidasi.status ? 'alert-success' : 'alert-danger'
                    "
                    :dismissable="true"
                    >{{ statusValidasi.msg }}</Alert
                  >
                  <Alert
                    v-if="statusNotifikasi.status != null"
                    :type="
                      statusNotifikasi.status ? 'alert-success' : 'alert-danger'
                    "
                    :dismissable="true"
                    >{{ statusNotifikasi.msg }}</Alert
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="intro-y box p-5 mt-5">
            <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
              <form
                id="tabulator-html-filter-form"
                class="xl:flex sm:mr-auto"
                @submit.prevent="onFilter"
              >
                <div class="sm:flex items-center sm:mr-4">
                  <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                    >Field</label
                  >
                  <select
                    id="tabulator-html-filter-field"
                    v-model="filter.field"
                    class="form-select w-full sm:w-32 xxl:w-full mt-2 sm:mt-0 sm:w-auto"
                  >
                    <option value="npm">NPM</option>
                    <option value="nama">Nama</option>
                  </select>
                </div>

                <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                  <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                    >Value</label
                  >
                  <input
                    id="tabulator-html-filter-value"
                    v-model="filter.value"
                    type="text"
                    class="form-control sm:w-40 xxl:w-full mt-2 sm:mt-0"
                    placeholder="Search..."
                  />
                </div>
                <div class="mt-2 xl:mt-0">
                  <button
                    id="tabulator-html-filter-go"
                    type="button"
                    class="btn btn-primary w-full sm:w-16"
                    @click="onFilter"
                  >
                    Go
                  </button>
                  <button
                    id="tabulator-html-filter-reset"
                    type="button"
                    class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
                    @click="resetFilter"
                  >
                    Reset
                  </button>
                </div>
              </form>
              <div class="flex mt-5 sm:mt-0">
                <button
                  id="tabulator-print"
                  class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"
                  @click="onPrint"
                >
                  <PrinterIcon class="w-4 h-4 mr-2" /> Print
                </button>
                <div class="dropdown w-1/2 sm:w-auto">
                  <button
                    class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
                    aria-expanded="false"
                  >
                    <FileTextIcon class="w-4 h-4 mr-2" /> Export
                    <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                  </button>
                  <div class="dropdown-menu w-40">
                    <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                      <a
                        id="tabulator-export-xlsx"
                        href="javascript:"
                        class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                        @click="onExportXlsx"
                      >
                        <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                      </a>
                      <a
                        id="tabulator-export-html"
                        href="javascript:"
                        class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                        @click="onExportHtml"
                      >
                        <FileTextIcon class="w-4 h-4 mr-2" /> Export HTML
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="overflow-x-auto scrollbar-hidden">
              <div
                id="tabulator"
                ref="tableRef"
                class="mt-5 table-report table-report--tabulator"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <ModalDialogPositive
        :id="'validasi-modal'"
        :question="
          'Anda yakin ingin memvalidasi pembayaran tagihan ' +
          detail.tagihan?.NamaProduk +
          ' ' +
          detail.tagihan?.Nama +
          '?'
        "
      >
        <template #delete-button>
          <button
            type="button"
            class="btn btn-success w-24"
            @click="konfirmValidasi"
          >
            Ya
          </button>
        </template>
      </ModalDialogPositive>
    </div>

    <SlideOver :open="panel.panelOpen" size="max-w-5xl" @close="closePanel">
      <template #title>Detail Tagihan dan Transaksi Pembayaran</template>
      <div class="h-full" aria-hidden="true">
        <Alert
          v-if="statusValidasi.status != null"
          :type="statusValidasi.status ? 'alert-success' : 'alert-danger'"
          :dismissable="true"
          >{{ statusValidasi.msg }}</Alert
        >
        <div
          v-if="confirmValidasi"
          class="p-3 text-center border border-green-100 mb-3"
        >
          Anda yakin ingin memvalidasi pembayaran tagihan
          <b>{{ detail.tagihan?.NamaProduk }} {{ detail.tagihan?.Nama }}</b
          >?

          <button
            type="button"
            class="btn btn-success w-24"
            @click="konfirmValidasi"
          >
            Ya
          </button>

          <button
            type="button"
            class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 ml-1"
            @click="confirmValidasi = false"
          >
            Tidak
          </button>
        </div>
        <DetailTransaksi
          :detail="detail"
          :process="allowValidasi"
          @validasiTransaksi="validasiTransaksi"
        ></DetailTransaksi>
      </div>
    </SlideOver>
  </MainLayout>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import xlsx from "xlsx";
import { useStore } from "vuex";
import feather from "feather-icons";
import { HTTPKEU } from "@/services/http";
import TailSelectSelector from "@/components/form/TailSelectSelector";
import Alert from "@/components/alerts/Alert";
import { option } from "@/utils/listoptions";
import MainLayout from "@/layouts/main/MainLayout";
import SlideOver from "@/layouts/main/SlideOver";
import { tblTabulator } from "@/utils/tabulator";
import store from "@/store";
import ModalDialogPositive from "@/components/utility/ModalDialogPositive";
import DetailTransaksi from "./validasi/DetailTransaksi";
import { helper } from "@/utils/helper";

export default {
  name: "Main",
  components: {
    DetailTransaksi,
    ModalDialogPositive,
    SlideOver,
    MainLayout,
    Alert,
    TailSelectSelector,
  },

  setup() {
    const tableRef = ref();
    const tabulator = ref();
    const idData = ref();
    const store = useStore();
    const filter = reactive({
      field: "npm",
      type: "like",
      value: "",
    });
    let panelOpen = ref(false);
    let panel = reactive({ panelOpen });
    let tagihan = null;
    let detail = reactive({ tagihan });
    let model = reactive({
      kode_bank: "BCA",
      kode_produk: null,
      kode_sub_comp: null,
      id_smt: null,
      npm: null,
    });
    let id_jenis_biaya = null;

    /*const showDetail = (e, cell) => {
      idData.value = cell.getData().id;
      detail.tagihan = cell.getData()?.tagihan;

      if (detail.tagihan?.ID) {
        panel.panelOpen = true;
        model.id_smt =
          detail.tagihan?.TahunAkademik.slice(0, 4) +
          detail.tagihan.KD_Semester;
        model.kode_produk = detail.tagihan.KodeProduk;
        model.kode_bank = detail.tagihan.KodeBank;
        model.kode_sub_comp = detail.tagihan.SubComp;
        model.npm = detail.tagihan.NPM;
        model.va = detail.tagihan.NO_VA;
        model.no_upload = detail.tagihan.NO_UPLOAD;
        console.log(model);
      }

      console.log(detail.tagihan);
    };*/
    /*const kofirmValidasi = (e, cell) => {
      let tagihan = cell.getData().tagihan;
      if (tagihan?.Ada_Transaksi === "Y" && tagihan?.Validasi === "T") {
        idData.value = cell.getData().id;
        detail.tagihan = cell.getData()?.tagihan;
        model.id_smt = cell.getData().id_smt;
        model.kode_produk = cell.getData().kode_produk;
        model.kode_bank = cell.getData().kode_bank;
        model.kode_sub_comp = cell.getData().kode_sub_comp;
        model.npm = cell.getData().npm;
        cash("#validasi-modal").modal("show");
      }
    };*/

    //panel = true;
    const initTabulator = () => {
      let columns = [
        {
          formatter: "responsiveCollapse",
          width: 40,
          minWidth: 30,
          hozAlign: "center",
          resizable: false,
          headerSort: false,
        },
        { formatter: "rownum", hozAlign: "center", width: 40 },
        // For HTML table
        {
          title: "Mahasiswa",
          field: "nipd",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
          headerSort: true,
          formatter(cell) {
            return (
              "<div><div class='whitespace-normal font-medium'>" +
              cell.getData().nipd +
              "</div> " +
              "<div class='whitespace-normal text-xs uppercase'>" +
              cell.getData().nm_pd.toUpperCase() +
              "</div>" +
              "<div class='whitespace-normal text-xs'>" +
              cell.getData().id_stat_mhs +
              "</div>" +
              "</div>"
            );
          },
        },
        {
          title: "VA",
          field: "no_va",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
          headerSort: true,
        },
        {
          title: "Kelas Kuliah",
          field: "ket_kelompok_kelas",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
          headerSort: true,
        },
      ];
      tabulator.value = tblTabulator.remoteDataTable(
        tableRef,
        "biaya",
        columns,
        true,
        null,
        { jenis_biaya: id_jenis_biaya }
      );
    };
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener("resize", () => {
        tabulator.value.redraw();
        feather.replace({
          "stroke-width": 1.5,
        });
      });
    };
    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value);
    };
    // On reset filter
    const onResetFilter = () => {
      filter.field = "npm";
      filter.type = "like";
      filter.value = "";
      onFilter();
    };

    // Filter function
    const onFilterCustom = (field, type, value) => {
      tabulator.value.setFilter(field, type, value);
    };
    // Export
    const onExportCsv = () => {
      tabulator.value.download("csv", "data.csv");
    };
    const onExportJson = () => {
      tabulator.value.download("json", "data.json");
    };
    const onExportXlsx = () => {
      const win = window;
      win.XLSX = xlsx;
      tabulator.value.download("xlsx", "data.xlsx", {
        sheetName: "Products",
      });
    };
    const onExportHtml = () => {
      tabulator.value.download("html", "data.html", {
        style: true,
      });
    };
    // Print
    const onPrint = () => {
      tabulator.value.print();
    };
    onMounted(() => {
      initTabulator();
      reInitOnResizeWindow();
    });
    return {
      store,
      tabulator,
      tableRef,
      filter,
      onFilter,
      onFilterCustom,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      idData,
      panel,
      detail,
      model,
      id_jenis_biaya,
    };
  },
  data() {
    return {
      smt: { a_periode_aktif: 0 },
      loading: false,
      angkatan: new Date().getFullYear(),
      sms: null,
      smsSelected: null,
      smtSelected: null,
      subcomps: null,
      banks: null,
      produks: null,

      statusSimpan: {
        msg: null,
        status: null,
      },
      statusGetTagihan: {
        msg: null,
        status: null,
      },
      statusValidasi: {
        msg: null,
        status: null,
      },
      statusNotifikasi: {
        msg: null,
        status: null,
      },
      panelOpen: false,
      needreset: false,
      transaksiBank: null,
      allowValidasi: false,
      confirmValidasi: false,
      jenisBiayaList: null,
      jenisBiayaSelected: null,
      jenis_biaya: 2,
      statistik: null,
    };
  },

  computed: {
    semester() {
      let year = new Date().getFullYear();
      let years = [];
      years.push({ id: 0, value: "Semua" });
      for (let i = 2015; i <= year; i++) {
        years.push({ id: i, value: i });
      }
      return years;
    },
    listOptionsState() {
      return this.$store.state.main.listOptions;
    },
    tabulatorLoaded() {
      return this.$store.state.main.tabulatorDataLoaded;
    },
  },
  watch: {
    tabulatorLoaded: {
      handler: function (h) {
        if (h === true) {
          this.$nextTick(() => {
            store.commit("main/resetTabulatorDataLoaded");
          });

          if (this.needreset === true) {
            this.onResetFilter();
            this.needreset = false;
          }
        }
      },
      immediate: true,
    },
    jenis_biaya: {
      handler: function (h) {
        this.setJenisBiayaSelected();
      },
      immediate: true,
    },
  },
  beforeMount() {
    option.fetchSemester();
    option.fetchProdi();
    this.getBankList();
    this.getProdukList();
    this.getSubcomList();
    this.getJenisBiayaList();
  },

  methods: {
    getJenisBiayaList() {
      HTTPKEU.get("list/jenisbiaya")
        .then((res) => {
          this.jenisBiayaList = res.data;
        })
        .finally(() => {});
    },
    setJenisBiayaSelected() {
      let h = parseInt(this.jenis_biaya);
      this.id_jenis_biaya = h;
      if (this.jenisBiayaList?.length > 0) {
        this.jenisBiayaSelected = this.jenisBiayaList.find((f) => {
          return h === f.id;
        });
      }
    },
    validasiTransaksi() {
      this.confirmValidasi = true;
      this.allowValidasi = true;
    },
    selectProdi(curr, id) {
      this.smsSelected = id;
      this.sms = curr[0];
    },
    selectSemester(curr, id) {
      this.smtSelected = id;
      this.smt = curr[0];
    },
    getSubcomList() {
      HTTPKEU.get("list/subcomp").then((res) => {
        this.subcomps = res.data;
      });
    },
    getProdukList() {
      HTTPKEU.get("list/produk").then((res) => {
        this.produks = res.data;
      });
    },
    getBankList() {
      HTTPKEU.get("list/bank").then((res) => {
        this.banks = res.data;
      });
    },

    konfirmValidasi() {
      this.statusValidasi.status = null;
      this.statusValidasi.msg = null;

      HTTPKEU.post("tagihan/validasi", { ...this.model })
        .then((res) => {
          if (res.status === 200) {
            this.statusValidasi.status = true;
            this.statusValidasi.msg = res.data.message;
          } else {
            this.statusValidasi.status = false;
            this.statusValidasi.msg = res.data.message;
          }
        })
        .catch((e) => {
          this.statusValidasi.status = false;
          this.statusValidasi.msg = e.response?.data?.message;
        })
        .finally(() => {
          this.cariData();
          this.confirmValidasi = false;
        });

      cash("#validasi-modal").modal("hide");
      //this.biayatagihanid = null;
    },
    closePanel(close) {
      this.panelOpen = close;
      this.panel.panelOpen = close;

      //Validation
      this.transaksiBank = null;
      this.allowValidasi = false;
      this.confirmValidasi = false;
      this.statusValidasi.status = null;
      this.statusValidasi.msg = null;
    },
    cariData() {
      this.tabulator.setData(
        process.env.VUE_APP_KEUANGAN + "biaya",
        {
          filter: {
            kode_bank: this.model.kode_bank,
            kode_produk: this.model.kode_produk,
            kode_sub_comp: this.model.kode_sub_comp,
            id_smt: this.smtSelected,
            id_sms: this.smsSelected,
            angkatan: this.angkatan,
          },
          jenis_biaya: this.id_jenis_biaya,
        },
        tblTabulator.ajaxConfig()
      );

      let columns = [
        {
          formatter: "responsiveCollapse",
          width: 40,
          minWidth: 30,
          hozAlign: "center",
          resizable: false,
          headerSort: false,
        },
        {
          title: "",
          formatter: "rownum",
          hozAlign: "center",
          width: 40,
          print: true,
          download: true,
        },
        {
          title: "Mahasiswa",
          field: "nm_pd",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
          headerSort: true,
          formatter(cell) {
            return (
              "<div><div class='whitespace-normal font-medium'>" +
              cell.getData().nipd +
              "</div> " +
              "<div class='whitespace-normal text-xs uppercase'>" +
              cell.getData().nm_pd +
              "<span class='text-yellow-800 text-xs font-bold ml-2'>(" +
              cell.getData().id_stat_mhs +
              ")</span>" +
              "</div>" +
              "</div>"
            );
          },
        },
        {
          title: "VA",
          field: "no_va",
          hozAlign: "left",
          vertAlign: "top",
          width: 100,
          print: true,
          download: true,
          headerSort: true,
        },
        {
          title: "Kelas Kuliah",
          field: "ket_kelompok_kelas",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          width: 120,
          download: true,
          headerSort: true,
        },
        {
          title: "Tagihan " + this.jenisBiayaSelected?.nama,
          print: true,
          download: true,
          columns: [
            {
              title: "Nominal",
              field: "biaya_tagihan.jumlah_upload",
              headerSort: false,
              width: 150,
              print: true,
              download: true,
              formatter(cell) {
                return cell.getData().biaya_tagihan?.jumlah_upload
                  ? helper.IDR(cell.getData().biaya_tagihan?.jumlah_upload)
                  : 0;
              },
            },
            {
              title: "Tgl Tagih",
              field: "biaya_tagihan.tgl_tagih",
              headerSort: false,
              width: 120,
              print: true,
              download: true,
              formatter(cell) {
                return cell.getData().biaya_tagihan?.tgl_tagih;
              },
            },
            {
              title: "Tgl Bayar",
              field: "tagihan_jupiter.Tgl_Transaksi",
              headerSort: false,
              print: true,
              download: true,
              width: 120,
              formatter(cell) {
                return cell.getData().tagihan_jupiter?.Tgl_Transaksi;
              },
            },
            {
              title: "Ada Transaksi",
              field: "tagihan_jupiter.Ada_Transaksi",
              headerSort: false,
              width: 150,
              print: true,
              download: true,
              hozAlign: "center",
              formatter(cell) {
                if (cell.getData().tagihan_jupiter?.Ada_Transaksi == "Y")
                  return `<div class="text-green-600">${
                    cell.getData().tagihan_jupiter?.Ada_Transaksi
                  }</div>`;
                else if (cell.getData().tagihan_jupiter?.Ada_Transaksi == "T")
                  return `<div class="text-red-600">${
                    cell.getData().tagihan_jupiter?.Ada_Transaksi
                  }</div>`;
                else return "";
              },
            },
            {
              title: "Validasi",
              field: "tagihan_jupiter.Validasi",
              headerSort: false,
              width: 100,
              hozAlign: "center",
              print: true,
              download: true,
              formatter(cell) {
                if (cell.getData().tagihan_jupiter?.Validasi == "Y")
                  return `<div class="text-green-600">${
                    cell.getData().tagihan_jupiter?.Validasi
                  }</div>`;
                else if (cell.getData().tagihan_jupiter?.Validasi == "T")
                  return `<div class="text-red-600">${
                    cell.getData().tagihan_jupiter?.Validasi
                  }</div>`;
                else return "";
              },
            },

            {
              title: "Keterangan",
              field: "tagihan_jupiter",
              headerSort: false,
              width: 100,
              hozAlign: "center",
              print: true,
              download: true,
              formatter(cell) {
                if (
                  cell.getData().tagihan_jupiter?.Validasi == "Y" &&
                  cell.getData().tagihan_jupiter?.Ada_Transaksi == "Y"
                )
                  return `<div class="text-green-600 text-xs whitespace-normal">Lunas</div>`;
                else if (
                  cell.getData().tagihan_jupiter?.Validasi == "T" &&
                  cell.getData().tagihan_jupiter?.Ada_Transaksi == "Y"
                )
                  return `<div class="text-red-600 text-xs whitespace-normal">Belum Divalidasi</div>`;
                else if (
                  cell.getData().tagihan_jupiter?.Validasi == "T" &&
                  cell.getData().tagihan_jupiter?.Ada_Transaksi == "T"
                )
                  return `<div class="text-red-600 text-xs whitespace-normal">Belum Dibayar</div>`;
                else
                  return `<div class="text-red-600 text-xs whitespace-normal">Tidak Ada Tagihan</div>`;
              },
            },
          ],
        },
        {
          title: "Beasiswa",
          field: "beasiswa.keterangan",
          headerSort: false,
          width: 150,
          hozAlign: "center",
          print: true,
          download: true,
          formatter(cell) {
            let b = cell.getData().beasiswa;
            if (cell.getData().beasiswa?.keterangan != null)
              return `<div class="text-red-600 text-xs whitespace-normal">${b.keterangan}</div>`;
            else return "";
          },
        },
      ];

      this.tabulator.setColumns(columns);

      //
      this.getStatistik();
    },
    resetFilter() {
      this.needreset = true;
      this.filter.value = "";
      this.tabulator.setData(
        process.env.VUE_APP_KEUANGAN + "biaya",
        {
          filter: {
            kode_bank: this.model.kode_bank,
            kode_produk: this.model.kode_produk,
            kode_sub_comp: this.model.kode_sub_comp,
            id_smt: this.smtSelected,
            id_sms: this.smsSelected,
            angkatan: this.angkatan,
          },
          jenis_biaya: this.id_jenis_biaya,
        },
        tblTabulator.ajaxConfig()
      );
    },
    kirimNotifikasi() {
      HTTPKEU.get("tagihan/kirimnotif", {
        params: {
          id_smt: this.smtSelected,
          id_sms: this.smsSelected,
          angkatan: this.angkatan,
          jenis_biaya: this.id_jenis_biaya,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.statusNotifikasi.status = true;
            this.statusNotifikasi.msg = res.data.message;
          } else if (res.status == 404) {
            this.statusNotifikasi.status = false;
            this.statusNotifikasi.msg = "Route Not FOund";
          } else {
            this.statusNotifikasi.status = false;
            this.statusNotifikasi.msg = res.data.message;
          }
        })
        .catch((e) => {
          this.statusNotifikasi.status = false;
          this.statusNotifikasi.msg = e.response?.data?.message;
        });
    },
    getStatistik() {
      HTTPKEU.get("statistik/biaya", {
        params: {
          id_smt: this.smtSelected,
          id_sms: this.smsSelected,
          angkatan: this.angkatan,
          jenis_biaya: this.id_jenis_biaya,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.statistik = res.data;
          }
        })
        .catch((e) => {
          this.statistik = null;
        });
    },
  },
};
</script>

<style scoped></style>
