<template>
  <div class="relative">
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        Buat Tagihan Cicilan UK Mahasiswa
      </h2>
      <div
        class="w-full sm:w-auto md:flex justify-center items-center align-center mt-4 sm:mt-0"
      >
        <router-link
          tag="a"
          class="btn btn-success w-50 mt-3 mb-2"
          :to="{ name: 'validasi-transaksi-uk-cicilan' }"
        >
          <CheckCircleIcon class="w-5 mr-1" />
          Validasi Pembayaran Cicilan UK
        </router-link>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <div v-if="loading" class="block-loading">
          <LoadingIcon icon="three-dots" class="w-8 h-8" />
          <div class="text-center text-xs mt-2">
            Loading data... Please Wait...
          </div>
        </div>
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div class="grid grid-cols-12 gap-3 p-3">
            <div class="col-span-12 xl:col-span-8">
              <div class="grid grid-cols-12 gap-2">
                <div class="col-span-12 xl:col-span-2">
                  <label class="form-label">Angkatan</label>
                  <select v-model="angkatan" class="form-select sm:mr-2 mb-2">
                    <option
                      v-for="smtr in semester"
                      :key="smtr.id"
                      :value="smtr.value"
                    >
                      {{ smtr.value }}
                    </option>
                  </select>
                </div>
                <div class="col-span-12 xl:col-span-4">
                  <label class="form-label">Tahun Akademik</label>
                  <TailSelectSelector
                    :list-options="
                      listOptionsState?.semester?.filter((a) => a.smt !== 3)
                    "
                    @cbox-select="selectSemester"
                  ></TailSelectSelector>
                </div>

                <div class="col-span-12 xl:col-span-4">
                  <label class="form-label">Jenis Pembiayaan</label>
                  <TailSelect
                    v-model="id_jenis_biaya"
                    :options="{
                      search: true,
                      classNames: 'w-full',
                    }"
                  >
                    <option
                      v-for="list in jenisBiayaList"
                      :key="list.id"
                      :value="list.id"
                    >
                      {{ list.nama }}
                    </option>
                  </TailSelect>
                  <!-- Alert Block -->
                  <div class="mt-3">
                    <Alert
                      v-if="errorbiayaKuliah != null"
                      :type="'alert-danger'"
                      :dismissable="true"
                      >{{ errorbiayaKuliah }}</Alert
                    >

                    <Alert
                      v-if="statusSimpan.status != null"
                      :type="
                        statusSimpan.status ? 'alert-success' : 'alert-danger'
                      "
                      :dismissable="true"
                      >{{ statusSimpan.msg }}</Alert
                    >
                    <Alert
                      v-if="statusGetTagihan.status != null"
                      :type="
                        statusGetTagihan.status
                          ? 'alert-success'
                          : 'alert-danger'
                      "
                      :dismissable="true"
                      >{{ statusGetTagihan.msg }}</Alert
                    >
                    <Alert
                      v-if="statusHapus.status != null"
                      :type="
                        statusHapus.status ? 'alert-success' : 'alert-danger'
                      "
                      :dismissable="true"
                      >{{ statusHapus.msg }}</Alert
                    >
                  </div>
                  <router-link
                    tag="a"
                    class="btn btn-primary btn-sm w-50 mb-2"
                    :to="{ name: 'mahasiswa-cicilan' }"
                  >
                    <DatabaseIcon class="w-4 mr-2" />
                    Mahasiswa Cicilan
                  </router-link>
                  <router-link
                    tag="a"
                    class="btn btn-warning btn-sm w-50 ml-2 mb-2"
                    :to="{ name: 'master-keuangan' }"
                  >
                    <DatabaseIcon class="w-4 mr-2" />
                    Master Biaya
                  </router-link>
                </div>

                <div class="col-span-12 xl:col-span-2">
                  <button
                    type="button"
                    class="btn btn-primary mb-2 mt-7"
                    @click="cariData"
                  >
                    Cari Data
                  </button>
                </div>
                <div class="col-span-12 xl:col-span-12">
                  <label class="form-label">Mahasiswa</label>
                  <TailSelect
                    v-model="mhsCicilSelected"
                    :options="{
                      search: true,
                      classNames: 'w-full',
                      deselect: true,
                    }"
                  >
                    <option
                      v-for="list in listMhsCicil"
                      :key="list?.id_reg_pd"
                      :value="list?.id_reg_pd"
                    >
                      {{ list?.nm_pd }} ({{ list?.nipd }})
                    </option>
                  </TailSelect>
                </div>
              </div>
            </div>

            <div
              class="col-span-12 xl:col-span-4 bg-gray-300 dark:bg-gray-700 pb-3"
            >
              <div class="p-3">
                <div class="grid grid-cols-2 gap-2">
                  <div class="col-span-1">
                    <label class="form-label">Sub Comp</label>
                    <select v-model="model.subcomp" class="form-select">
                      <option
                        v-for="list in subcomps"
                        :key="list.Kode"
                        :value="list.Kode"
                      >
                        {{ list.Keterangan }} ({{ list.Kode }})
                      </option>
                    </select>
                  </div>
                  <div class="col-span-1">
                    <label class="form-label">Produk</label>

                    <select v-model="model.produk" class="form-select">
                      <option
                        v-for="list in produks"
                        :key="list.kode"
                        :value="list.kode"
                      >
                        {{ list.Keterangan }} ({{ list.kode }})
                      </option>
                    </select>
                  </div>
                </div>

                <label class="form-label mt-3">Bank</label>
                <select v-model="model.bank" class="form-select">
                  <option
                    v-for="(item, index) in banks"
                    :key="index"
                    :value="item.kode"
                  >
                    {{ item.Keterangan }}
                  </option>
                </select>
              </div>

              <button
                :disabled="totalHarusDiupload == 0 || model.subcomp == null"
                type="button"
                class="btn btn-success w-50 ml-3 mr-2"
                @click="buatTagihan"
              >
                Kirim Tagihan
              </button>

              Harus Upload : {{ totalHarusDiupload }} {{ model.subcomp }}

              <!--              <a
                v-if="dataCama?.no_ujian"
                target="_blank"
                :href="
                  apiurl + 'cetaksuratpernyataanmaba/' + dataCama?.no_ujian
                "
                class="btn btn-warning w-50 ml-3 mr-2"
              >
                Cetak Pernyataan
              </a>
              <a
                v-if="dataCama?.no_ujian"
                target="_blank"
                :href="apiurl + 'cetakloa/' + dataCama?.no_ujian"
                class="btn btn-primary w-50 ml-3 mr-5"
              >
                Cetak LOA
              </a>-->
            </div>
          </div>

          <div class="grid grid-cols-12 gap-2 p-2">
            <div v-if="mhsCicilSelected" class="col-span-3 pl-2">
              <div class="columns-1 mb-2">
                <label class="form-label">NPM</label>
                <div class="font-bold">
                  {{ dataMhsCicil?.nipd }}
                </div>
              </div>
              <div class="columns-1 mb-2">
                <label class="form-label">Nama</label>
                <div class="font-bold">
                  {{ dataMhsCicil?.nm_pd }}
                </div>
              </div>
              <div class="columns-1 mb-2">
                <label class="form-label">NO. VA</label>
                <div class="font-bold">
                  <span class="text-yellow-700 pr-1">75105</span
                  >{{ dataMhsCicil?.no_va }}
                </div>
              </div>

              <div class="columns-1 mb-2">
                <label class="form-label">Prodi</label>
                <div class="font-bold">
                  {{ dataMhsCicil?.nm_prodi }} ({{ dataMhsCicil?.jenjang }})
                </div>
              </div>

              <div class="columns-1 mb-2">
                <label class="form-label">Waktu Kuliah</label>
                <div class="font-bold">
                  {{ dataMhsCicil?.ket_kelompok_kelas }}
                </div>
              </div>
            </div>
            <div class="col-span-9">
              <CreateTagihanCicilan
                v-if="mhsCicilSelected"
                class="p-2"
                :model="model"
                :id-ujian="mhsCicilSelected"
                :mhs="dataMhsCicil"
                :angkatan="angkatan"
                :jenis-biaya-list="jenisBiayaList"
                @dataTagihan="setDataTagihan"
                @loadingBlock="setLoadingBlock"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalDialogPositive
      :id="'buat-tagihan'"
      :question="'Anda yakin ingin membuat Tagihan Cicilan untuk mahasiswa ini?'"
    >
      <template #delete-button>
        <button type="button" class="btn btn-success w-24" @click="submit">
          Ya
        </button>
      </template>
    </ModalDialogPositive>
  </div>
</template>

<script>
import { option } from "@/utils/listoptions";
import { HTTPKEU } from "@/services/http";
import Alert from "../../../../components/alerts/Alert";
import ModalDialogPositive from "../../../../components/utility/ModalDialogPositive";
import CreateTagihanCicilan from "./CreateTagihanCicilan";
import TailSelectSelector from "@/components/form/TailSelectSelector";
export default {
  name: "Form",
  components: {
    CreateTagihanCicilan,
    ModalDialogPositive,
    Alert,
    TailSelectSelector,
  },
  data() {
    return {
      smt: { a_periode_aktif: 0 },
      listMhsAktif: [],
      listTagihanJupiter: [],
      angkatan: new Date().getFullYear(),
      smsSelected: null,
      smtSelected: null,
      subcomps: null,
      banks: null,
      produks: null,
      jenisBiayaList: null,
      id_jenis_biaya: "5",
      jenisBiayaSelected: [],
      model: {
        bank: "BCA",
        produk: "010",
        subcomp: null,
        id_smt: null,
      },
      biayaKuliah: null,
      errorbiayaKuliah: null,
      statusSimpan: {
        msg: null,
        status: null,
      },
      statusGetTagihan: {
        msg: null,
        status: null,
      },
      statusHapus: {
        msg: null,
        status: null,
      },
      loading: false,
      biayaAdm: 3000,
      biaya: [],
      listBiayaMhs: [],
      biayatagihanid: null,
      showtable: false,
      jalur: "A",
      sesi: 1,
      listMhsCicil: [],
      mhsCicilSelected: null,
      dataMhsCicil: null,
      dataTagihan: null,
      isCicil: true,
      apiurl: process.env.VUE_APP_KEUANGAN,
      panelOpen: false,
    };
  },

  computed: {
    semester() {
      let year = new Date().getFullYear();
      let years = [];
      for (year; year >= 2018; year--) {
        years.push({ id: year, value: year });
      }
      return years;
    },
    listOptionsState() {
      return this.$store.state.main.listOptions;
    },
    listBuatTagihan() {
      return this.listBiayaMhs.filter((f) => f.buat_tagihan === true);
    },

    totalHarusDiupload() {
      let total = 0;
      /*if (this.dataTagihan?.tagihSumbangan) {
        for (let s of this.dataTagihan.tagihSumbangan.filter((t) => {
          return Boolean(t.upload_tagih) === true && t.nominal_bayar == null;
        })) {
          total += parseFloat(s.nominal);
        }
      }
      if (this.dataTagihan?.tagihUk1 && this.isCicil === false) {
        for (let s of this.dataTagihan.tagihUk1.filter((t) => {
          return Boolean(t.upload_tagih) === true && t.nominal_bayar == null;
        })) {
          total += parseFloat(s.nominal);
        }
      }
      if (this.dataTagihan?.tagihUk2 && this.isCicil === false) {
        for (let s of this.dataTagihan.tagihUk2.filter((t) => {
          return Boolean(t.upload_tagih) === true && t.nominal_bayar == null;
        })) {
          total += parseFloat(s.nominal);
        }
      }*/

      if (this.dataTagihan?.tagihUk && this.isCicil) {
        for (let s of this.dataTagihan.tagihUk.filter((t) => {
          return Boolean(t.upload_tagih) === true && t.nominal_bayar == null;
        })) {
          total += parseFloat(s.nominal);
        }
      }

      return total;
    },
  },

  watch: {
    id_jenis_biaya: {
      handler: function (h) {
        //this.setJenisBiayaSelected();
        //this.biayaKuliahDetail();
      },
      deep: true,
    },
    mhsCicilSelected: {
      handler: function (h) {
        console.log(h);
        this.dataMhsCicil = this.listMhsCicil.find((a) => {
          return a.id_reg_pd === h;
        });
        console.log(this.dataMhsCicil);
        /*this.dataCama.no_va = this.dataCama.no_ujian;
        let p = this.dataCama.pilfix;
        this.dataCama.prodiFix = this.listOptionsState?.prodi.find((f) => {
          return f?.kode_angka == p;
        });*/
      },
      deep: true,
    },
  },
  beforeMount() {
    option.fetchSemester();

    this.getBankList();
    this.getProdukList();
    this.getSubcomList();
  },
  mounted() {
    //this.setJenisBiayaSelected();
    this.getJenisBiayaList();
  },
  methods: {
    selectCama(curr, id) {
      this.camaSelected = id;
    },
    selectProdi(curr, id) {
      this.smsSelected = id;
    },
    selectSemester(curr, id) {
      this.smtSelected = id;
      this.smt = curr[0];
    },
    reset() {
      this.listMhsAktif = [];
      this.biayaAdm = 3000;
      this.listTagihanJupiter = [];
      this.biaya = [];
    },
    cariData() {
      this.loading = true;

      HTTPKEU.post("list_mhs_cicilan", {
        angkatan: this.angkatan,
        id_smt: this.smtSelected,
        id_jenis_biaya: this.id_jenis_biaya,
      })
        .then((res) => {
          this.model.id_jenis_biaya = this.id_jenis_biaya;
          this.model.id_smt = this.smtSelected;
          this.listMhsCicil = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getSubcomList() {
      HTTPKEU.get("list/subcomp").then((res) => {
        this.subcomps = res.data;
        let prdpmb = ["00000", "00001", "00003", "00004"];
        this.subcomps = this.subcomps.filter((f) => {
          return prdpmb.includes(f.Kode);
        });
      });
    },
    getProdukList() {
      HTTPKEU.get("list/produk").then((res) => {
        this.produks = res.data;
        let prdpmb = ["002", "003", "029", "030"];
        this.produks = this.produks.filter((f) => {
          return prdpmb.includes(f.kode);
        });
      });
    },
    getBankList() {
      HTTPKEU.get("list/bank").then((res) => {
        this.banks = res.data;
      });
    },
    getBiayaADM() {
      return HTTPKEU.post("master/biayaAdm", {
        tahun: this.smtSelected?.substr(0, 4),
      });
    },
    getJenisBiayaList() {
      HTTPKEU.get("list/jenisbiaya")
        .then((res) => {
          this.jenisBiayaList = res.data;
        })
        .finally(() => {
          //this.setJenisBiayaSelected();
        });
    },
    submit() {
      this.statusSimpan.msg = null;
      this.statusSimpan.status = null;
      if (this.model.subcomp != null || this.model.produk != null) {
        this.loading = true;
        console.log(this.dataTagihan);
        let data_pemb = [...this.dataTagihan.tagihUk];
        /*if (this.isCicil && this.dataTagihan?.tagihUk?.length > 0) {
          data_pemb = [
            ...this.dataTagihan.tagihSumbangan,
            ...this.dataTagihan.tagihUk,
          ];
        }*/

        /*if (this.isCicil === false) {
          data_pemb = [
            ...this.dataTagihan.tagihSumbangan,
            ...this.dataTagihan.tagihUk1,
            ...this.dataTagihan.tagihUk2,
          ];
        }*/

        HTTPKEU.post("tagihan/simpan_tagihan_cicilan_uk", {
          subcomp: this.model.subcomp,
          bank: this.model.bank,
          produk: this.model.produk,
          id_smt: this.model.id_smt,
          id_jenis_biaya: this.model.id_jenis_biaya,
          id_reg_pd: this.dataMhsCicil.id_reg_pd,
          nominal_tagih: this.totalHarusDiupload,
          data_pembiayaan: data_pemb,
          mhs: this.dataMhsCicil,
        })
          .then((res) => {
            if (res.status === 200) {
              this.statusSimpan.msg = res.data?.message;
              this.statusSimpan.status = true;
            } else {
              this.statusSimpan.msg = res.data?.message;
              this.statusSimpan.status = false;
            }
          })
          .catch((err) => {
            this.statusSimpan.msg = err.response?.data?.message;
            this.statusSimpan.status = false;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.statusSimpan.msg = "Pilih SubComp dan Produk terlebih dahulu";
        this.statusSimpan.status = false;
      }

      cash("#buat-tagihan").modal("hide");
    },
    buatTagihan() {
      cash("#buat-tagihan").modal("show");
    },
    setDataTagihan(data, cicil) {
      this.dataTagihan = data;
      this.isCicil = cicil;
    },
    setLoadingBlock(loading) {
      this.loading = loading;
    },
    closePanel() {
      this.panelOpen = false;
    },
  },
};
</script>
