<template>
  <MainLayout>
    <div class="relative">
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">
          Validasi Transaksi Pembayaran
        </h2>
        <div
          class="w-full sm:w-auto md:flex justify-center items-center align-center mt-4 sm:mt-0"
        >
          <button class="btn btn-danger" @click="openPanelImportValidasi()">
            Validasi Massal
          </button>
          <router-link
            class="btn btn-primary ml-2"
            :to="{ name: 'validasi-tagihan-permhs' }"
          >
            Validasi Per Mahasiswa
          </router-link>
          <router-link
            class="btn btn-success ml-2"
            :to="{ name: 'tagihan-list' }"
          >
            Tagihan dan Transaksi
          </router-link>
        </div>
      </div>

      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 lg:col-span-12">
          <div v-if="loading" class="block-loading">
            <LoadingIcon icon="three-dots" class="w-8 h-8" />
            <div class="text-center text-xs mt-2">
              Loading data... Please Wait...
            </div>
          </div>

          <div class="intro-y box">
            <div class="grid grid-cols-12 mb-3 p-3">
              <div class="col-span-3">
                <label class="form-label">Tahun Akademik</label>
                <TailSelectSelector
                  :list-options="listOptionsState?.semester"
                  @cbox-select="selectSemester"
                ></TailSelectSelector>
              </div>
              <div class="col-span-3">
                <label class="form-label">Sub Comp</label>
                <TailSelect
                  v-model="model.kode_sub_comp"
                  :options="{
                    search: true,
                    classNames: 'w-full',
                    deselect: true,
                  }"
                >
                  <option
                    v-for="list in subcomps"
                    :key="list.Kode"
                    :value="list.Kode"
                  >
                    {{ list.Keterangan }} ({{ list.Kode }})
                  </option>
                </TailSelect>
              </div>
              <div class="col-span-3">
                <label class="form-label">Produk</label>

                <TailSelect
                  v-model="model.kode_produk"
                  :options="{
                    search: true,
                    classNames: 'w-full',
                    deselect: true,
                  }"
                >
                  <option
                    v-for="list in produks"
                    :key="list.kode"
                    :value="list.kode"
                  >
                    {{ list.Keterangan }} ({{ list.kode }})
                  </option>
                </TailSelect>
              </div>
              <div class="col-span-3">
                <div class="form-check mt-7 ml-3">
                  <input
                    id="cek_divalidasi"
                    v-model="divalidasi"
                    class="form-check-switch"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="cek_divalidasi">{{
                    divalidasi ? "Telah Divalidasi" : "Belum Validasi"
                  }}</label>
                  <button
                    class="btn btn-success w-50 ml-3 mr-5"
                    @click="cariData"
                  >
                    Cek Tagihan
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="intro-y box p-5 mt-5">
            <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
              <form
                id="tabulator-html-filter-form"
                class="xl:flex sm:mr-auto"
                @submit.prevent="onFilter"
              >
                <div class="sm:flex items-center sm:mr-4">
                  <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                    >Filter By</label
                  >
                  <select
                    id="tabulator-html-filter-field"
                    v-model="filter.field"
                    class="form-select w-full sm:w-32 xxl:w-full mt-2 sm:mt-0 sm:w-auto"
                  >
                    <option value="Nama">Nama</option>
                    <option value="NO_VA">NO VA</option>
                    <option value="SubComp">Sub Comp</option>
                    <option value="KodeProduk">Produk</option>
                  </select>
                </div>

                <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                  <select
                    v-if="filter.field == 'SubComp'"
                    v-model="filter.value"
                    class="form-select"
                    placeholder="Pilih SubComp"
                  >
                    <option
                      v-for="sc in subcomps"
                      :key="sc.Kode"
                      :value="sc.Kode"
                    >
                      {{ sc.Keterangan }}
                    </option>
                  </select>
                  <select
                    v-if="filter.field == 'KodeProduk'"
                    v-model="filter.value"
                    class="form-select"
                    placeholder="Pilih Produk"
                  >
                    <option
                      v-for="pr in produks"
                      :key="pr.kode"
                      :value="pr.kode"
                    >
                      {{ pr.Keterangan }} ({{ pr.kode }})
                    </option>
                  </select>
                  <input
                    v-if="filter.field == 'Nama' || filter.field == 'NO_VA'"
                    id="tabulator-html-filter-value"
                    v-model="filter.value"
                    type="text"
                    class="form-control sm:w-40 xxl:w-full mt-2 sm:mt-0"
                    placeholder="Search..."
                  />
                </div>
                <div class="mt-2 xl:mt-0">
                  <button
                    id="tabulator-html-filter-go"
                    type="button"
                    class="btn btn-primary w-full sm:w-16"
                    @click="onFilter"
                  >
                    Go
                  </button>
                  <button
                    id="tabulator-html-filter-reset"
                    type="button"
                    class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
                    @click="resetFilter"
                  >
                    Reset
                  </button>
                </div>
              </form>
              <div class="flex mt-5 sm:mt-0">
                <button
                  id="tabulator-print"
                  class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"
                  @click="onPrint"
                >
                  <PrinterIcon class="w-4 h-4 mr-2" /> Print
                </button>
                <div class="dropdown w-1/2 sm:w-auto">
                  <button
                    class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
                    aria-expanded="false"
                  >
                    <FileTextIcon class="w-4 h-4 mr-2" /> Export
                    <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                  </button>
                  <div class="dropdown-menu w-40">
                    <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                      <a
                        id="tabulator-export-xlsx"
                        href="javascript:"
                        class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                        @click="onExportXlsx"
                      >
                        <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                      </a>
                      <a
                        id="tabulator-export-html"
                        href="javascript:"
                        class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                        @click="onExportHtml"
                      >
                        <FileTextIcon class="w-4 h-4 mr-2" /> Export HTML
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="overflow-x-auto scrollbar-hidden">
              <div
                id="tabulator"
                ref="tableRef"
                class="mt-5 table-report table-report--tabulator"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <ModalDialogPositive
        :id="'validasi-modal'"
        :question="
          'Anda yakin ingin memvalidasi pembayaran tagihan ' +
          detail.tagihan?.NamaProduk +
          ' ' +
          detail.tagihan?.Nama +
          '?'
        "
      >
        <template #delete-button>
          <button
            type="button"
            class="btn btn-success w-24"
            @click="konfirmValidasi"
          >
            Ya
          </button>
        </template>
      </ModalDialogPositive>
    </div>

    <SlideOver :open="panel.panelOpen" size="max-w-5xl" @close="closePanel">
      <template #title>Detail Tagihan dan Transaksi Pembayaran</template>
      <div class="h-full" aria-hidden="true">
        <Alert
          v-if="statusValidasi.status != null"
          :type="statusValidasi.status ? 'alert-success' : 'alert-danger'"
          :dismissable="true"
          >{{ statusValidasi.msg }}</Alert
        >
        <div
          v-if="confirmValidasi"
          class="p-3 text-center border border-yellow-500 mb-3 font-medium text-lg text-yellow-700"
        >
          Anda yakin ingin memvalidasi pembayaran tagihan
          <b>{{ detail.tagihan?.NamaProduk }}</b> mahasiswa
          <b>{{ detail.tagihan?.Nama }}</b
          >?
          <div class="content-center mt-2 text-xs">
            <select
              v-model="model.account_code"
              class="form-select form-select-sm w-auto mr-2 border-yellow-700"
            >
              <option value="700.100">Penerimaan PMB</option>
              <option value="710.100">UK 1</option>
              <option value="710.200">UK 2</option>
              <option value="710.300">Biaya Semester Pendek</option>
              <option value="710.300">Wisuda</option>
            </select>
            <button
              type="button"
              class="btn btn-success w-24"
              @click="konfirmValidasi"
            >
              Ya
            </button>

            <button
              type="button"
              class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 ml-2"
              @click="confirmValidasi = false"
            >
              Tidak
            </button>
          </div>
        </div>
        <DetailTransaksi
          :detail="detail"
          :process="allowValidasi"
          @validasiTransaksi="validasiTransaksi"
        ></DetailTransaksi>
      </div>
    </SlideOver>

    <SlideOver
      :open="panelImportValiasi"
      size="max-w-5xl"
      @close="closePanelImportValidasi"
    >
      <template #title
        >Import Validasi Massal Pembayaran Virtual Account</template
      >
      <Alert :type="'alert-warning'"
        >Proses Validasi ini tidak melewati prosedur Cek Transaksi Bank yang
        telah disediakan oleh BANK pada fitur Single Validation (Validasi satu
        per satu). Oleh karena itu, pastikan data yang diimport sudah sesuai
        dengan data yang ada di Laporan Transaksi BANK. <br />Sehingga jika
        terdapat kesalahan validasi merupakan tanggung jawab dari Admin yang
        melakukan Validasi.
      </Alert>
      <Alert
        v-if="statusImport.status != null"
        :type="statusImport.status ? 'alert-success' : 'alert-danger'"
        :dismissable="true"
        >{{ statusImport.msg }}</Alert
      >
      <UploadFileExcel
        :semester-list="listOptionsState?.semester"
        @dataExcel="onOpenFileExcel"
        @saveImport="onSaveImport"
      >
      </UploadFileExcel>

      <TableImportData
        v-if="dataMhsFromExcel.length > 0"
        class="mt-2"
        :datafromexcelprops="dataMhsFromExcel"
        :status-import="statusImportValidasiMhs"
      ></TableImportData>
    </SlideOver>
  </MainLayout>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import xlsx from "xlsx";
import { useStore } from "vuex";
import feather from "feather-icons";
import { HTTPKEU } from "@/services/http";
import { option } from "@/utils/listoptions";
import MainLayout from "@/layouts/main/MainLayout";
import SlideOver from "@/layouts/main/SlideOver";
import { tblTabulator } from "@/utils/tabulator";
import { helper } from "@/utils/helper";
import store from "@/store";
import ModalDialogPositive from "@/components/utility/ModalDialogPositive";
import { mahasiswa } from "@/services/models";
import DetailTransaksi from "@/views/tagihan/validasi/DetailTransaksi";
import Alert from "../../../components/alerts/Alert";
import TailSelectSelector from "../../../components/form/TailSelectSelector";
import UploadFileExcel from "./UplodFileExcel";
import TableImportData from "./TableImportData";

export default {
  name: "ValidasiMain",
  components: {
    TableImportData,
    UploadFileExcel,
    TailSelectSelector,
    Alert,
    DetailTransaksi,
    ModalDialogPositive,
    SlideOver,
    MainLayout,
  },

  setup() {
    const tableRef = ref();
    const tabulator = ref();
    const idData = ref();
    const store = useStore();
    const filter = reactive({
      field: "Nama",
      type: "like",
      value: "",
    });

    let model = reactive({
      kode_bank: "BCA",
      kode_produk: null,
      kode_sub_comp: null,
      id_smt: null,
      npm: null,
      add_info: "",
      tgl_bayar: null,
      account_code: "710.100",
    });
    let panelOpen = ref(false);
    let panel = reactive({ panelOpen });
    let tagihan = null;
    let detail = reactive({ tagihan });

    const showDetail = (e, cell) => {
      idData.value = cell.getData().id;
      detail.tagihan = cell.getData();
      panel.panelOpen = true;
      console.log(detail.tagihan);

      model.id_smt =
        cell.getData().TahunAkademik.slice(0, 4) + cell.getData().KD_Semester;
      model.kode_produk = cell.getData().KodeProduk;
      model.kode_bank = cell.getData().KodeBank;
      model.kode_sub_comp = cell.getData().SubComp;
      model.npm = cell.getData().NPM;
      model.va = cell.getData().NO_VA;
      model.no_upload = cell.getData().NO_UPLOAD;
      model.add_info = "";
      model.tgl_bayar = null;
    };

    //panel = true;
    const initTabulator = () => {
      let columns = [
        {
          formatter: "responsiveCollapse",
          width: 40,
          minWidth: 30,
          hozAlign: "center",
          resizable: false,
          headerSort: false,
        },
        { formatter: "rownum", title: "NO", hozAlign: "center", width: 40 },
        // For HTML table

        {
          title: "MAHASISWA",
          field: "Nama",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
          headerSort: true,
          formatter(cell) {
            return (
              "<div><div class='whitespace-normal font-medium'>" +
              cell.getData().NPM +
              "</div>" +
              "<div class='whitespace-normal text-xs'>" +
              cell.getData().Nama +
              "</div></div>"
            );
          },
        },
        {
          title: "SEMESTER",
          field: "TahunAkademik",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
          headerSort: true,
          formatter(cell) {
            return (
              cell.getData()?.TahunAkademik +
              " " +
              helper.semester(cell.getData()?.KD_Semester)
            );
          },
        },

        {
          title: "VA",
          field: "NO_VA",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
          headerSort: true,
        },

        {
          title: "JUMLAH",
          field: "Tagihan",
          hozAlign: "left",
          vertAlign: "middle",
          print: true,
          download: true,
          headerSort: false,
          formatter(cell) {
            return helper.IDR(cell.getData()?.Tagihan);
          },
        },
        {
          title: "SUB COMP",
          field: "SubComp",
          hozAlign: "left",
          vertAlign: "middle",
          print: true,
          download: true,
          headerSort: false,
        },
        {
          title: "PRODUK",
          field: "NamaProduk",
          hozAlign: "left",
          vertAlign: "middle",
          print: true,
          download: true,
          headerSort: false,
          formatter(cell) {
            return (
              "<div class='whitespace-normal text-xs'>" +
              cell.getData()?.NamaProduk +
              " (" +
              cell.getData()?.KodeProduk +
              ")" +
              "</div>"
            );
          },
        },
        {
          title: "VALIDASI",
          field: "Validasi",
          hozAlign: "left",
          vertAlign: "middle",
          print: true,
          download: true,
          headerSort: false,
          formatter(cell) {
            let valid = cell.getData()?.Validasi;
            let status = "";
            if (valid === "Y") {
              status += `<div class="flex lg:justify-center items-center">
                    <div class=" rounded-full text-center text-xs px-3 py-1 bg-theme-9 text-white font-small
          ">Telah Divalidasi</div>
              </div>`;
            } else {
              status += `<div class="flex lg:justify-center items-center">
                    <div class=" rounded-full text-center text-xs px-3 py-1 bg-theme-6 text-white font-small
          ">Belum Divalidasi</div>
              </div>`;
            }

            return status;
          },
        },
        {
          title: "TRANSAKSI",
          field: "Ada_Transaksi",
          hozAlign: "left",
          vertAlign: "middle",
          print: true,
          download: true,
          headerSort: false,
          formatter(cell) {
            let tagihan = cell.getData()?.Ada_Transaksi;
            let status = "";
            if (tagihan === "Y") {
              status += `
              <div class="grid grid-cols-1">
                <div class="whitespace-nowrap rounded-full text-center text-xs px-3 py-1 bg-theme-9 text-white font-small
          ">Telah Dibayar</div>
                <div class="whitespace-normal text-xs mt-1">
                  ID Transaksi : ${cell.getData()?.MLPOREF}
                </div>
                <div class="whitespace-normal text-xs mt-1">
                  Tgl Transaksi : ${cell.getData()?.TGLTRANSAKSI}
                </div>
              </div>`;
            } else if (tagihan === "T") {
              status += `<div class="flex lg:justify-center items-center">
                    <div class=" rounded-full text-center text-xs px-3 py-1 bg-theme-6 text-white font-small
          ">Belum Dibayar</div>
              </div>`;
            } else {
              status += ``;
            }

            return status;
          },
        },

        {
          title: "",
          field: "id",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            let tagihan = cell.getData();
            if (tagihan?.Ada_Transaksi === "Y" && tagihan?.Validasi === "T") {
              const a = `<div class="flex lg:justify-center items-center">
                <btn class="flex btn btn-sm btn-primary items-center">
                  <i data-feather="check" class="w-3 h-3 mr-1"></i> Validasi
                </btn>
              </div>`;
              return `${a}`;
            } else {
              const a = `<div class="flex lg:justify-center items-center">
                <btn class="flex btn btn-sm btn-default items-center">
                  <i data-feather="eye" class="w-3 h-3 mr-1"></i> Detail
                </btn>
              </div>`;
              return `${a}`;
            }
          },
          cellClick: showDetail,
        },
      ];
      tabulator.value = tblTabulator.remoteDataTable(
        tableRef,
        "tagihan/get_tagihan_ada_transaksibaru",
        columns,
        true
      );
    };
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener("resize", () => {
        tabulator.value.redraw();
        feather.replace({
          "stroke-width": 1.5,
        });
      });
    };
    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value);
    };
    // On reset filter
    const onResetFilter = () => {
      filter.field = "Nama";
      filter.type = "like";
      filter.value = "";
      onFilter();
    };

    // Filter function
    const onFilterCustom = (field, type, value) => {
      tabulator.value.setFilter(field, type, value);
    };
    // Export
    const onExportCsv = () => {
      tabulator.value.download("csv", "data.csv");
    };
    const onExportJson = () => {
      tabulator.value.download("json", "data.json");
    };
    const onExportXlsx = () => {
      const win = window;
      win.XLSX = xlsx;
      tabulator.value.download("xlsx", "data.xlsx", {
        sheetName: "Products",
      });
    };
    const onExportHtml = () => {
      tabulator.value.download("html", "data.html", {
        style: true,
      });
    };
    // Print
    const onPrint = () => {
      tabulator.value.print();
    };
    onMounted(() => {
      initTabulator();
      reInitOnResizeWindow();
    });
    return {
      store,
      tabulator,
      tableRef,
      filter,
      onFilter,
      onFilterCustom,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      idData,
      panel,
      detail,
      model,
    };
  },
  data() {
    return {
      smt: { a_periode_aktif: 0 },
      loading: false,
      angkatan: new Date().getFullYear(),
      smsSelected: null,
      smtSelected: null,
      subcomps: null,
      banks: null,
      produks: null,

      statusSimpan: {
        msg: null,
        status: null,
      },
      statusGetTagihan: {
        msg: null,
        status: null,
      },
      statusValidasi: {
        msg: null,
        status: null,
      },
      panelOpen: false,
      needreset: false,
      dataPD: null,
      dosenPA: null,
      transaksiBank: null,
      allowValidasi: false,
      confirmValidasi: false,
      divalidasi: false,
      dataValidasiFromExcel: [],
      panelImportValiasi: false,
      dataMhsFromExcel: [],
      statusImportValidasiMhs: [],
      statusImport: {
        status: null,
        msg: null,
      },
    };
  },

  computed: {
    semester() {
      let year = new Date().getFullYear();
      let years = [];
      for (let i = 2016; i <= year; i++) {
        years.push({ id: i, value: i });
      }
      return years;
    },
    listOptionsState() {
      return this.$store.state.main.listOptions;
    },
    tabulatorLoaded() {
      return this.$store.state.main.tabulatorDataLoaded;
    },
  },
  watch: {
    tabulatorLoaded: {
      handler: function (h) {
        if (h === true) {
          this.$nextTick(() => {
            store.commit("main/resetTabulatorDataLoaded");
          });

          if (this.needreset === true) {
            this.onResetFilter();
            this.needreset = false;
          }
        }
      },
      immediate: true,
    },
  },
  beforeMount() {
    option.fetchSemester();
    option.fetchProdi();
    this.getSubcomList();
    this.getProdukList();
  },
  mounted() {
    //this.cariData();
  },
  methods: {
    fetchDataMahasiswa(d) {
      let id_pd = d?.id_pd;
      mahasiswa.PesertaDidik.getData(id_pd)
        .then((result) => {
          if (result?.status === 200) {
            this.dataPD = result.data.data;
            this.dosenPA = this.dataPD?.map_mhs;
            this.idRegPD = this.dosenPA?.id_reg_pd;
          }
        })
        .finally(() => {
          this.cariData(d?.npm);
        });
    },
    validasiTransaksi(tb, it) {
      this.confirmValidasi = true;
      this.allowValidasi = true;
      if (tb == null) {
        this.model.add_info = it?.infoTambahan != "x" ? it?.infoTambahan : "";
        this.model.tgl_bayar = it?.infoTambahan != "x" ? it?.tglBayar : null;
      }
    },
    konfirmValidasi() {
      this.statusValidasi.status = null;
      this.statusValidasi.msg = null;
      var url = "tagihan/validasi";

      if (this.model.kode_produk === "029") {
        url = "validasi_tagihan_cicilan_uk";
      }

      HTTPKEU.post(url, { ...this.model })
        .then((res) => {
          if (res.status === 200) {
            this.statusValidasi.status = true;
            this.statusValidasi.msg = res.data.message;
          } else {
            this.statusValidasi.status = false;
            this.statusValidasi.msg = res.data.message;
          }
        })
        .catch((e) => {
          this.statusValidasi.status = false;
          this.statusValidasi.msg = e.response?.data?.message;
        })
        .finally(() => {
          this.cariData(this.model.npm);
          this.confirmValidasi = false;
        });
    },
    closePanel(close) {
      this.panelOpen = close;
      this.panel.panelOpen = close;
      this.transaksiBank = null;
      this.allowValidasi = false;
      this.confirmValidasi = false;
      this.statusValidasi.status = null;
      this.statusValidasi.msg = null;
    },
    cariData() {
      this.tabulator.setData(
        process.env.VUE_APP_KEUANGAN + "tagihan/get_tagihan_ada_transaksibaru",
        {
          filter: {
            //kode_bank: this.model.kode_bank,
            kode_produk: this.model.kode_produk,
            kode_sub_comp: this.model.kode_sub_comp,
            id_smt: this.smtSelected,
            divalidasi: this.divalidasi ? "Y" : "T",
            //id_sms: this.smsSelected,
            //angkatan: this.angkatan,
          },
        },
        tblTabulator.ajaxConfig()
      );
    },
    resetFilter() {
      this.needreset = true;
      this.filter.value = "";
      this.tabulator.setData(
        process.env.VUE_APP_KEUANGAN + "tagihan/get_tagihan_ada_transaksibaru",
        {},
        tblTabulator.ajaxConfig()
      );
    },
    getSubcomList() {
      HTTPKEU.get("list/subcomp").then((res) => {
        this.subcomps = res.data;
      });
    },
    getProdukList() {
      HTTPKEU.get("list/produk").then((res) => {
        this.produks = res.data;
      });
    },
    selectSemester(curr, id) {
      this.smtSelected = id;
      this.smt = curr[0];
    },
    openPanelImportValidasi() {
      this.panelImportValiasi = true;
    },
    closePanelImportValidasi() {
      this.panelImportValiasi = false;
    },
    onOpenFileExcel(value) {
      this.data = [];
      this.statusImport = [];
      this.dataMhsFromExcel = value;
    },
    onSaveImport() {
      this.statusImport.status = null;
      this.statusImport.msg = [];
      this.statusImportValidasiMhs = [];
      let sendata = this.dataMhsFromExcel;
      const chunkSize = 3;
      for (let i = 0; i < sendata.length; i += chunkSize) {
        const chunk = sendata.slice(i, i + chunkSize);
        this.saveImportMassValidasi(chunk);
      }
    },
    saveImportMassValidasi(chunk) {
      var url = "tagihan/validasimassal";
      chunk.forEach((valid) => {
        console.log(valid);
        HTTPKEU.post(url, { ...valid })
          .then((res) => {
            this.statusImportValidasiMhs.push(res.data);
          })
          .catch((e) => {
            this.statusImportValidasiMhs.push({ message: e?.message });
          })
          .finally(() => {
            //this.statusImport.status = true;
          });
      });
    },
  },
};
</script>

<style scoped></style>
