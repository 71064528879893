<template>
  <div class="relative">
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        Buat Tagihan Untuk Semester Pendek
      </h2>
      <div
        class="w-full sm:w-auto md:flex justify-center items-center align-center mt-4 sm:mt-0"
      >
        <router-link
          tag="a"
          class="btn btn-success w-50 mt-3 mb-2"
          :to="{ name: 'tagihan-list' }"
        >
          <CheckCircleIcon class="w-5 mr-1" />
          Validasi Pembayaran SP
        </router-link>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <div v-if="loading" class="block-loading">
          <LoadingIcon icon="three-dots" class="w-8 h-8" />
          <div class="text-center text-xs mt-2">
            Loading data... Please Wait...
          </div>
        </div>
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div class="grid grid-cols-12 mb-3">
            <div class="col-span-12 xl:col-span-8">
              <div class="p-3 flex">
                <div class="flex-1">
                  <label class="form-label">Tahun Akademik</label>
                  <TailSelectSelector
                    :list-options="
                      listOptionsState?.semester?.filter((a) => a.smt == '3')
                    "
                    @cbox-select="selectSemester"
                  ></TailSelectSelector>

                  <div class="grid grid-cols-2 mt-3 gap-3 mb-2">
                    <div class="col-span-1">
                      <label class="form-label">Tahun Ajaran</label>
                      <input
                        class="form-control"
                        :value="smt?.thn_ajaran?.nm_thn_ajaran"
                        readonly
                        disabled
                      />
                    </div>
                    <div class="col-span-1">
                      <label class="form-label">Semester</label>
                      <input
                        class="form-control"
                        :value="smt?.nm_smt"
                        readonly
                        disabled
                      />
                    </div>
                  </div>
                  <Alert
                    v-if="errorUk1 != null"
                    :type="'alert-danger'"
                    :dismissable="true"
                    >{{ errorUk1 }}</Alert
                  >
                  <Alert
                    v-if="errorUk2 != null"
                    :type="'alert-danger'"
                    :dismissable="true"
                    >{{ errorUk2 }}</Alert
                  >

                  <Alert
                    v-if="statusSimpan.status != null"
                    :type="
                      statusSimpan.status ? 'alert-success' : 'alert-danger'
                    "
                    :dismissable="true"
                    >{{ statusSimpan.msg }}</Alert
                  >
                  <Alert
                    v-if="statusGetTagihan.status != null"
                    :type="
                      statusGetTagihan.status ? 'alert-success' : 'alert-danger'
                    "
                    :dismissable="true"
                    >{{ statusGetTagihan.msg }}</Alert
                  >
                  <Alert
                    v-if="statusHapus.status != null"
                    :type="
                      statusHapus.status ? 'alert-success' : 'alert-danger'
                    "
                    :dismissable="true"
                    >{{ statusHapus.msg }}</Alert
                  >
                </div>
                <div class="flex-1 ml-5">
                  <label class="form-label">Program Studi</label>
                  <TailSelectSelector
                    class="col-span-5"
                    :list-options="listOptionsState?.prodi"
                    @cbox-select="selectProdi"
                  ></TailSelectSelector>

                  <label class="form-label">Angkatan</label>
                  <select v-model="angkatan" class="form-select sm:mr-2">
                    <option
                      v-for="smtr in semester"
                      :key="smtr.id"
                      :value="smtr.value"
                    >
                      {{ smtr.value }}
                    </option>
                  </select>
                  <button
                    type="button"
                    class="btn btn-primary w-50 mt-3 mb-2 mr-6"
                    :disabled="smtSelected == null || smsSelected == null"
                    @click="cariData"
                  >
                    Cari Data
                  </button>

                  <router-link
                    tag="a"
                    class="btn btn-warning w-50 mt-3 mb-2"
                    :to="{ name: 'master-biaya-sp' }"
                  >
                    Master Biaya SP
                  </router-link>
                </div>
              </div>
            </div>
            <div
              class="col-span-12 xl:col-span-4 bg-gray-300 dark:bg-gray-700 pb-3"
            >
              <div class="p-3">
                <div class="grid grid-cols-2 gap-2">
                  <div class="col-span-1">
                    <label class="form-label">Sub Comp</label>
                    <TailSelect
                      v-model="model.subcomp"
                      :options="{
                        search: true,
                        classNames: 'w-full',
                        deselect: true,
                      }"
                    >
                      <option
                        v-for="list in subcomps"
                        :key="list.Kode"
                        :value="list.Kode"
                      >
                        {{ list.Keterangan }} ({{ list.Kode }})
                      </option>
                    </TailSelect>
                  </div>
                  <div class="col-span-1">
                    <label class="form-label">Produk</label>

                    <TailSelect
                      v-model="model.produk"
                      :options="{
                        search: true,
                        classNames: 'w-full',
                        deselect: true,
                      }"
                    >
                      <option
                        v-for="list in produks"
                        :key="list.kode"
                        :value="list.kode"
                      >
                        {{ list.Keterangan }} ({{ list.kode }})
                      </option>
                    </TailSelect>
                  </div>
                </div>

                <label class="form-label mt-3">Bank</label>
                <select v-model="model.bank" class="form-select">
                  <option
                    v-for="(item, index) in banks"
                    :key="index"
                    :value="item.kode"
                  >
                    {{ item.Keterangan }}
                  </option>
                </select>
              </div>

              <button
                :disabled="
                  listBiayaMhs?.length == 0 ||
                  biayaUk1?.length == 0 ||
                  biayaUk2?.length == 0
                "
                type="button"
                class="btn btn-success w-50 ml-3 mr-5"
                @click="submit"
              >
                Simpan Data
              </button>
              <button
                :disabled="
                  listBiayaMhs?.length == 0 ||
                  biayaUk1?.length == 0 ||
                  biayaUk2?.length == 0
                "
                type="button"
                class="btn bg-purple-500 text-white w-50 ml-auto"
                @click="cekTagihanJupiter"
              >
                Cek Tagihan
              </button>
            </div>
          </div>
          <TableMhsAmbilSP
            :list-mhs-s-p="listMhsSP"
            :biaya-uk1="biayaUk1"
            :biaya-uk2="biayaUk2"
            :biaya-adm="biayaAdm"
            :list-tagihan-jupiter="listTagihanJupiter"
            :model="model"
            class="p-2"
            @listBiayaMhs="setListBiayaMhs"
            @hapusBiayaTagihan="hapusBiayaTagihan"
          />
        </div>
      </div>
    </div>

    <ModalDialog
      :question="
        'Anda yakin ingin menghapus Biaya Tagihan Semester Pendek ' +
        biayatagihanid?.nm_pd +
        '?'
      "
    >
      <template #delete-button>
        <button
          type="button"
          class="btn btn-danger w-24"
          @click="konfirmHapusBiayatagihan"
        >
          Ya
        </button>
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import TailSelectSelector from "@/components/form/TailSelectSelector";
import TableMhsAmbilSP from "./TableMhsAmbilSP";
import { option } from "@/utils/listoptions";
import { HTTPKEU } from "../../../services/http";
import Alert from "../../../components/alerts/Alert";
import ModalDialog from "../../../components/utility/ModalDialog";
export default {
  name: "Form",
  components: {
    ModalDialog,
    Alert,
    TableMhsAmbilSP,
    TailSelectSelector,
  },
  data() {
    return {
      smt: { a_periode_aktif: 0 },
      listMhsSP: [],
      listTagihanJupiter: [],
      angkatan: new Date().getFullYear(),
      smsSelected: null,
      smtSelected: null,
      subcomps: null,
      banks: null,
      produks: null,
      model: {
        bank: "BCA",
        produk: null,
        subcomp: null,
        id_smt: null,
      },
      biayaUk1: null,
      biayaUk2: null,
      errorUk1: null,
      errorUk2: null,
      statusSimpan: {
        msg: null,
        status: null,
      },
      statusGetTagihan: {
        msg: null,
        status: null,
      },
      statusHapus: {
        msg: null,
        status: null,
      },
      loading: false,
      biayaAdm: 3000,
      listBiayaMhs: [],
      biayatagihanid: null,
    };
  },

  computed: {
    semester() {
      let year = new Date().getFullYear();
      let years = [];
      for (let i = 2015; i <= year; i++) {
        years.push({ id: i, value: i });
      }
      return years;
    },
    listOptionsState() {
      return this.$store.state.main.listOptions;
    },
  },
  beforeMount() {
    option.fetchSemester();
    option.fetchProdi();
    this.getBankList();
    this.getProdukList();
    this.getSubcomList();
  },
  methods: {
    selectProdi(curr, id) {
      this.smsSelected = id;
    },
    selectSemester(curr, id) {
      this.smtSelected = id;
      this.smt = curr[0];
    },
    cariData() {
      this.loading = true;
      this.errorUk1 = null;
      this.errorUk2 = null;
      let that = this;
      Promise.all([
        this.getBiayaUKI(),
        this.getBiayaUKII(),
        this.getBiayaADM(),
      ]).then(function (result) {
        that.biayaUk1 = result[0].data;
        that.biayaUk2 = result[1].data;

        if (result[0].data?.length == 0) {
          that.errorUk1 =
            "Biaya UK I tidak ditemukan, silakan buat master biaya SP terlebih dahulu!";
        }
        if (result[1].data?.length == 0) {
          that.errorUk2 =
            "Biaya UK II tidak ditemukan, silakan buat master biaya SP terlebih dahulu!";
        }
        if (result[2].data?.length > 0) {
          that.biayaAdm = result[2].data?.biaya;
        }
      });
      HTTPKEU.post("tagihan/mhs_ambilsp", {
        id_smt: this.smtSelected,
        id_sms: this.smsSelected,
        tahun: this.angkatan,
      })
        .then((res) => {
          if (res.status == 200) {
            this.listMhsSP = res.data?.data;
            this.model.id_smt = this.smtSelected;
            this.model.id_sms = this.smsSelected;
            this.model.tahun = this.angkatan;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getSubcomList() {
      HTTPKEU.get("list/subcomp").then((res) => {
        this.subcomps = res.data;
      });
    },
    getProdukList() {
      HTTPKEU.get("list/produk").then((res) => {
        this.produks = res.data;
      });
    },
    getBankList() {
      HTTPKEU.get("list/bank").then((res) => {
        this.banks = res.data;
      });
    },
    getBiayaUKI() {
      return HTTPKEU.post("master/biayasp/uk1", {
        id_smt: this.smtSelected,
        id_sms: this.smsSelected,
      });
    },
    getBiayaUKII() {
      return HTTPKEU.post("master/biayasp/uk2", {
        id_smt: this.smtSelected,
        angkatan: this.angkatan,
        id_sms: this.smsSelected,
      });
    },
    getBiayaADM() {
      return HTTPKEU.post("master/biayasp/biayaAdm", {
        id_smt: this.smtSelected,
      });
    },
    setListBiayaMhs(val) {
      this.listBiayaMhs = val;
    },
    submit() {
      this.statusSimpan.msg = null;
      this.statusSimpan.status = null;
      if (this.model.subcomp != null || this.model.produk != null) {
        this.loading = true;

        HTTPKEU.post("tagihan/simpan_tagihan_sp", {
          subcomp: this.model.subcomp,
          bank: this.model.bank,
          produk: this.model.produk,
          data_mhs: this.listBiayaMhs,
          id_smt: this.model.id_smt,
        })
          .then((res) => {
            if (res.status == 200) {
              this.statusSimpan.msg = res.data?.message;
              this.statusSimpan.status = true;
            } else {
              this.statusSimpan.msg = res.data?.message;
              this.statusSimpan.status = false;
            }
          })
          .catch((err) => {
            this.statusSimpan.msg = err.message;
            this.statusSimpan.status = false;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.statusSimpan.msg = "Pilih SubComp dan Produk terlebih dahulu";
        this.statusSimpan.status = false;
      }
    },
    cekTagihanJupiter() {
      this.statusGetTagihan.msg = null;
      this.statusGetTagihan.status = null;
      if (this.model.subcomp != null || this.model.produk != null) {
        this.loading = true;

        HTTPKEU.post("tagihan/get_tagihan_jupiter", {
          subcomp: this.model.subcomp,
          bank: this.model.bank,
          produk: this.model.produk,
          id_fakultas: this.listBiayaMhs[0]["id_fakultas"],
          id_smt: this.model.id_smt,
        })
          .then((res) => {
            if (res.status == 200) {
              this.listTagihanJupiter = res?.data;
            } else {
              this.statusGetTagihan.msg = res.data?.message;
              this.statusGetTagihan.status = false;
            }
          })
          .catch((err) => {
            this.listTagihanJupiter = [];
            this.statusGetTagihan.msg = err.message;
            this.statusGetTagihan.status = false;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.statusGetTagihan.msg = "Pilih SubComp dan Produk terlebih dahulu";
        this.statusGetTagihan.status = false;
      }
    },

    hapusBiayaTagihan(btag) {
      this.biayatagihanid = btag;
      cash("#delete-modal").modal("show");
    },

    konfirmHapusBiayatagihan() {
      this.statusHapus.status = null;
      this.statusHapus.msg = null;
      HTTPKEU.post("tagihan/hapus_tagihan", {
        _method: "DELETE",
        id_smt: this.biayatagihanid.id_smt,
        nipd: this.biayatagihanid.nipd,
        subcomp: this.model.subcomp,
        bank: this.model.bank,
        produk: this.model.produk,
      })
        .then((res) => {
          if (res.status === 200) {
            this.statusHapus.status = true;
            this.statusHapus.msg = res.data.message;
          } else {
            this.statusHapus.status = false;
            this.statusHapus.msg = res.data.message;
          }
        })
        .catch((e) => {
          console.log(e.response?.status);
          this.statusHapus.status = false;
          this.statusHapus.msg = e.response?.data?.message;
        })
        .finally(() => {
          this.cariData();
          this.cekTagihanJupiter();
        });

      cash("#delete-modal").modal("hide");
      this.biayatagihanid = null;
    },
  },
};
</script>

<style scoped></style>
