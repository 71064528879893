<template>
  <div class="overflow-x-auto">
    <table class="table">
      <thead>
        <tr class="text-center">
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            rowspan="2"
          >
            NPM
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
            rowspan="2"
          >
            Nama
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
            rowspan="2"
          >
            VA
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
            rowspan="2"
          >
            Tahun
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
            colspan="3"
          >
            SKS
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
            rowspan="2"
          >
            UKI
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
            colspan="2"
          >
            Per SKS (UK II)
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
            colspan="2"
          >
            Biaya SKS (UK II)
          </th>

          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
            rowspan="2"
          >
            Total Biaya
          </th>

          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
            rowspan="2"
          >
            Status Tagihan
          </th>
        </tr>
        <tr class="text-center">
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
          >
            SKS TOTAL
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
          >
            SKS PG
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
          >
            SKS ML
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
          >
            /SKS PG
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
          >
            /SKS ML
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
          >
            Rp SKS PG
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
          >
            Rp SKS ML
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in listMhs" :key="index">
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            {{ item.nipd }}
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            {{ item.nm_pd }}
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            {{ item.no_va }}
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            {{ item.tahun }}
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs"
          >
            {{ parseInt(item.tsks) }}
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs"
          >
            {{ item.tsks_pagi ? parseInt(item.tsks_pagi) : 0 }}
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs"
          >
            {{ item.tsks_malam ? parseInt(item.tsks_malam) : 0 }}
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            {{ $h.IDR(item?.uk1) }}
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            {{ $h.IDR(item?.uk2_pagi) }}
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            {{ $h.IDR(item?.uk2_malam) }}
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            {{ $h.IDR(item?.rp_sks_pagi) }}
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            {{ $h.IDR(item?.rp_sks_malam) }}
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            {{ $h.IDR(item?.totalrp) }}
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            <div
              v-if="
                item?.status_tagihan == 'Y' &&
                item?.status_validasi_tagihan == 'T'
              "
              class="py-1 px-2 rounded-full text-xs bg-green-400 text-white text-center cursor-pointer font-medium"
            >
              Ada Transaksi
            </div>
            <div
              v-if="
                item?.status_tagihan == 'Y' &&
                item?.status_validasi_tagihan == 'Y'
              "
              class="py-1 px-2 rounded-full text-xs bg-green-500 text-white text-center cursor-pointer font-medium"
            >
              Telah Divalidasi
            </div>
            <div
              v-if="item?.status_tagihan == 'T'"
              class="py-1 px-2 rounded-full text-xs bg-red-500 text-white text-center cursor-pointer font-medium"
            >
              Belum Dibayar
            </div>
            <div
              v-if="item?.status_tagihan == null"
              class="py-1 px-2 rounded-full text-xs bg-gray-500 text-white text-center cursor-pointer font-medium"
            >
              Belum Ada Tagihan
            </div>
            <button
              v-if="
                model.subcomp != null &&
                model.produk != null &&
                item?.status_tagihan == 'T'
              "
              class="btn btn-sm btn-warning py-0 mt-2"
              @click="hapusTagihan(item)"
            >
              <Trash2Icon class="w-4 mr-2" />
              Hapus Tagihan
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "TableMhsAmbilSP",
  props: {
    listMhsSP: {
      type: Array,
      default: null,
    },
    biayaUk1: {
      type: Array,
      default: null,
    },
    biayaUk2: {
      type: Array,
      default: null,
    },
    biayaAdm: {
      type: [String, Number],
      default: 3000,
    },
    listTagihanJupiter: {
      type: Array,
      default: null,
    },
    model: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      listMhs: [],
    };
  },
  computed: {},
  watch: {
    listMhsSP: {
      handler: function (h) {
        this.listBiayaMhs();
      },
      immediate: true,
    },
    biayaUk2: {
      handler: function (h) {
        this.biayaUK2Mhs("Pagi");
      },
      immediate: true,
    },
    biayaUk1: {
      handler: function (h) {
        this.biayaUK1Mhs(2);
      },
      immediate: true,
    },
    listTagihanJupiter: {
      handler: function (h) {
        this.listBiayaMhs();
      },
      immediate: true,
    },
    model: {
      handler: function (h) {},
      immediate: true,
    },
  },
  methods: {
    listBiayaMhs() {
      this.listMhs = [];
      this.listMhsSP?.forEach((a, b) => {
        let smlm = this.biayaUK2Mhs("Malam"); //siang
        let spagi = this.biayaUK2Mhs("Pagi");
        let uk1 = this.biayaUK1Mhs(a.tsks);

        let rp_sks_pagi =
          a.tsks_pagi != null ? parseFloat(spagi) * parseInt(a.tsks_pagi) : 0;
        let rp_sks_malam =
          a.tsks_malam != null ? parseFloat(smlm) * parseInt(a.tsks_malam) : 0; //siang

        let tagjupiter = this.statusTagihan(a.nipd);
        this.listMhs.push({
          ...a,
          uk1: uk1,
          uk2_pagi: spagi,
          uk2_malam: smlm,
          rp_sks_pagi: rp_sks_pagi,
          rp_sks_malam: rp_sks_malam,
          adm: this.biayaAdm,
          totalbiaya:
            parseFloat(rp_sks_pagi) +
            parseFloat(rp_sks_malam) +
            parseFloat(uk1),
          totalrp:
            parseFloat(this.biayaAdm) +
            parseFloat(rp_sks_pagi) +
            parseFloat(rp_sks_malam) +
            parseFloat(uk1),
          status_tagihan: tagjupiter?.Ada_Transaksi,
          status_validasi_tagihan: tagjupiter?.Validasi,
        });
      });
      this.$emit("listBiayaMhs", this.listMhs);
    },
    biayaUK1Mhs(sks) {
      if (parseInt(sks) > 0 && this.biayaUk1?.length > 0) {
        let c = parseInt(sks) <= 4 ? "2-4" : "5-8";
        let b = this.biayaUk1.find((f) => {
          return f.jenis == c;
        });
        return b?.biaya;
      } else {
        return 0;
      }
    },
    biayaUK2Mhs(jenis) {
      if (this.biayaUk2?.length > 0) {
        let b = this.biayaUk2.find((f) => {
          return f.jenis == jenis;
        });
        return b?.biaya;
      } else {
        return 0;
      }
    },

    statusTagihan(nipd) {
      if (this.listTagihanJupiter?.length > 0) {
        let tag = this.listTagihanJupiter?.find((f) => {
          return f?.NPM == nipd;
        });
        return tag;
      } else {
        return null;
      }
    },
    hapusTagihan(btag) {
      this.$emit("hapusBiayaTagihan", btag);
    },
  },
};
</script>

<style scoped></style>
